
import jsbridge from "../../libs/jsbridge";

/**
 * 返回
 * 
 * @function setOnVisibilityChange
 * @memberof ui
 * 
 * @param {any} params 
 */
export function setOnVisibilityChange(params) {
  return jsbridge({url: "ui/setOnVisibilityChange", params: params}, {
    callbackType: 'observer',
    customCallback: true,
  });
}



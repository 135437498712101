
import jsbridge from "../../libs/jsbridge";

/**
 * 返回
 * 
 * @function setWebTitle
 * @memberof ui
 * 
 * @param {Object} params
 * @param {String} params.title 页面title
 */
export function setWebTitle(params) {
  return jsbridge({url: "ui/setWebTitle", params: params});
}



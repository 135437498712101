/**
 * getLocation
 * @author donaldcen
 * @createTime 2017/10/22
 */

export default function () {
  if (typeof window === 'object' && typeof window.location === 'object') {
    return window.location
  } else if (typeof weex === 'object' && typeof weex.config === 'object') {
    let url = weex.config.bundleUrl || ''
    let m = url.match(/(https?:)?\/\/([^\/]+)(\/[^\?#$]*)*(\?[^#$]*)*(#\w*)*/) || []
    return {
      protocol: m[1],
      host: m[2],
      href: url,
      origin: m[1]+m[2],
      pathname: m[3],
      search: m[4],
      hash: m[5]
    }
  }
}
/**
 * 支持v-bem在组件层的服务端渲染
 * 组件不会触发v-bem指令的服务端渲染， 因此需要在组件的根节点配置 v-bem-ssr="true"，用于触发服务端渲染
 */
import { transCamecase } from '@/util/util';

export default {
  stringify: (vnode) => {
    const node = vnode.parent; // 在组件上设置的v-bem,需要查找其父节点的设置
    const { data } = vnode;
    if (node && node.data && node.data.directives && node.context && node.context.$options) {
      const bemOptions = node.data.directives.find(dir => dir.name === 'bem');
      if (bemOptions) {
        const block = transCamecase(node.context.$options.name || '');
        let bemClass = `${block}`;
        if (bemOptions.arg) {
          bemClass += `__${bemOptions.arg}`;
        }
        if (data.staticClass) {
          data.staticClass += ` ${bemClass}`;
        } else {
          data.staticClass = bemClass;
        }
      }
    }
    return {
      ...vnode,
      data,
    };
  },
};

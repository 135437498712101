/**
 * Created by homkerliu on 2017/12/20.
 */

import getUA from './getUA'

/**
 * 判断是否预缓存页面
 */
export default function () {
  let ua = getUA()
  return Number((ua.match(/preload\/([\d]){1}/) || [])[1]) === 1 //1 为是，0为否
}
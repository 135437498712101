/**
 * isIOS
 * @author marvinqi
 * @createTime 2017/9/19
 */


/**
 *@desc 判断是否是在安卓的环境下
 *@returns {boolean}
 */

import toNum from '../../../utils/toNum'
import _platform from './platform'

export default function () {
  return toNum(_platform('android[/ ]'))
}

import jsbridge from "../../libs/jsbridge";

/**
 * 注销账户
 *
 * @function deleteAccount
 * @memberof user
 *
 * @returns {Promise<any>}
 */
export function deleteAccount (params) {
  return jsbridge({ url: 'user/deleteAccount', params });
}

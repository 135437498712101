
import jsbridge from "../../libs/jsbridge";

/**
 * 弹出 webview 窗口
 * 
 * @function showBroswerDialog
 * @memberof ui
 * 
 * @param {Object} params
 * @param {String} params.url webview的URL
 * @param {Number} params.width 弹窗宽度
 * @param {Number} params.height 弹窗高度
 * @param {Boolean} params.mask 是否有遮罩
 * @returns {Promise<any>}
 */
export function showBroswerDialog(params) {
  return jsbridge({url: "ui/showBroswerDialog", params: params}, {
    callbackType: 'observer',
    customCallback: true,
  });
}

import Vue from 'vue';
import Router from 'vue-router';
import { checkEnv } from '@/util/browser-env';
import { setWebTitle } from '@/jsbridge';
import preloadRoutes from '@/page/common/preload/preload-routes_old';
import { logger } from '@/util/logger';
import groupRoutes from './group-tools';

const checkEnvObj = checkEnv();

// 为实现动态打包，这里对import做了特殊处理
const Home = () => import(/* webpackChunkName: "Home" */'../page/home/index.vue');
const NoLogin = () => import(/* webpackChunkName: "NoLogin" */'../page/no-login/index.vue');
const NotFound = () => import(/* webpackChunkName: "NotFound" */'../page/not-found/index.vue');
const Download = () => import(/* webpackChunkName: "NotFound" */'../page/common/download/index.vue');
const EmailActivation = () => import(/* webpackChunkName: "NotFound" */'../page/common/email-activation/index.vue');
const EmailActivationFail = () => import(/* webpackChunkName: "NotFound" */'../page/common/email-activation-fail/index.vue');
const PostDetail = () => import(/* webpackChunkName: "PostDetail" */'../page/community/post-detail/index.vue');
const GameIntroduce = () => import(/* webpackChunkName: "GameIntroduce" */'../page/community/game-introduce/index.vue');
const PostVideo = () => import(/* webpackChunkName: "PostVideo" */'../page/community/post-video/index.vue');
const QuestionDetail = () => import(/* webpackChunkName: "QuestionDetail" */'../page/community/question-detail/index.vue');
const AnswerDetail = () => import(/* webpackChunkName: "AnswerDetail" */'../page/community/answer-detail/index.vue');
const AnswerInvite = () => import(/* webpackChunkName: "AnswerInvite" */'../page/community/answer-invite/index.vue');
const GameReservation = () => import(/* webpackChunkName: "NewReservation" */'../page/community/game-reservation/index.vue');

const BindPhone = () => import(/* webpackChunkName: "BindPhone" */'../page/user/bind-phone/index.vue');
const LotteryRecord = () => import(/* webpackChunkName: "LotteryRecord" */ '../page/base-activity/page-lottery/lottery-record/index.vue');
const Feedback = () => import(/* webpackChunkName: "Feedback" */'../page/setting/feedback/index.vue');
const Logoff = () => import(/* webpackChunkName: "Logoff" */'../page/setting/logoff/index.vue');
const LogoffError = () => import(/* webpackChunkName: "Logoff" */'../page/setting/logoff/error.vue');
const LogoffVerify = () => import(/* webpackChunkName: "Logoff" */'../page/setting/logoff/verify.vue');
const LogoffProtocol = () => import(/* webpackChunkName: "Logoff" */'../page/setting/logoff/protocol.vue');
const LogoffSuccess = () => import(/* webpackChunkName: "Logoff" */'../page/setting/logoff/success.vue');
const CommonError = () => import(/* webpackChunkName: "CommonError" */'@/page/error-page/common-error/index.vue');
const AnswerGrowthQA = () => import(/* webpackChunkName: "Feedback" */'../page/community/answer-growth-qa/index.vue');

// 活动-策划面对面
const FaceToFace = () => import(/* webpackChunkName: "FaceToFace" */'../page/base-activity/face-to-face/index.vue');
const FaceToFaceAnswerDetail = () => import(/* webpackChunkName: "FaceToFace" */ '../page/base-activity/face-to-face/answer-detail.vue');
const FaceToFaceHistory = () => import(/* webpackChunkName: "FaceToFace" */ '../page/base-activity/face-to-face/history.vue');

// 活动中心
const ActivityCenter = () => import(/* webpackChunkName: "ActivityCenter" */ '../page/base-activity/activity-center/index.vue');

const GamePayRedirect = () => import(/* webpackChunkName: "GamePayRedirect" */'../page/game-pay-redirect/index.vue');

let demoRoutes = [];
if (process.env.NODE_ENV === 'development') {
  demoRoutes = require('./demo-routes').default;
}

Vue.use(Router);
const router = new Router({
  mode: 'history',
  routes: [
    { // 首页
      name: 'home',
      path: '/',
      component: Home,
      meta: {
        //
      },
    }, { // 没登录态的页面
      name: 'no-login',
      path: '/no-login',
      component: NoLogin,
    }, { // 下载APP
      name: 'common-download',
      path: '/common/download',
      component: Download,
    }, { // 邮箱激活
      name: 'common-email-activation',
      path: '/common/email-activation',
      component: EmailActivation,
    }, { // 邮箱激活失败
      name: 'common-email-activation-fail',
      path: '/common/email-activation-fail',
      component: EmailActivationFail,
    }, { // 图文帖子详情页
      name: 'community-post-detail',
      path: '/community/post-detail',
      component: PostDetail,
    }, { // 新游介绍详情页
      name: 'community-game-introduce',
      path: '/community/game-introduce',
      component: GameIntroduce,
    },  { // 视频帖子详情页
      name: 'community-post-video',
      path: '/community/post-video',
      component: PostVideo,
    }, { // 问题详情页
      name: 'community-question-detail',
      path: '/community/question-detail',
      component: QuestionDetail,
    }, { // 答案详情页
      name: 'community-answer-detail',
      path: '/community/answer-detail',
      component: AnswerDetail,
    }, { // 邀请回答页面
      name: 'community-answer-invite',
      path: '/community/answer-invite',
      component: AnswerInvite,
    }, { // 新游预约页
      name: 'community-game-reservation',
      path: '/community/game-reservation',
      component: GameReservation,
      meta: {
        keepAlive: true,
      },
    },
    { // 绑定手机
      name: 'user-bind-phone',
      path: '/user/bind-phone',
      component: BindPhone,
      meta: {
        title: '绑定手机号',
      },
    },
    { // 中奖记录页
      name: 'lottery-record',
      path: '/page-lottery/lottery-record',
      component: LotteryRecord,
      meta: {
        title: '中奖记录',
      },
    },
    { // 反馈
      name: 'setting-feedback',
      path: '/setting/feedback',
      component: Feedback,
      meta: {
        title: '我要反馈',
      },
    },
    { // 注销
      name: 'setting-logoff',
      path: '/setting/logoff',
      component: Logoff,
      meta: {
        title: '社区账号注销提示',
      },
    },
    { // 注销失败
      name: 'setting-logoff-error',
      path: '/setting/logoff-error',
      component: LogoffError,
      meta: {
        title: '账号注销',
      },
    },
    { // 注销验证
      name: 'setting-logoff-verify',
      path: '/setting/logoff-verify',
      component: LogoffVerify,
      meta: {
        title: '账号注销',
      },
    },
    { // 注销协议
      name: 'setting-logoff-protocol',
      path: '/setting/logoff-protocol',
      component: LogoffProtocol,
      meta: {
        title: '社区账号注销协议',
      },
    },
    { // 注销协议
      name: 'setting-logoff-success',
      path: '/setting/logoff-success',
      component: LogoffSuccess,
      meta: {
        title: '账号注销',
      },
    },
    { // 答主成长体系常见问题
      name: 'community-answer-growth-qa',
      path: '/community/answer-growth-qa',
      component: AnswerGrowthQA,
      meta: {
        title: '答主成长体系常见问题',
      },
    },
    // 策划面对面-外部分享首页
    {
      name: 'face-to-face',
      path: '/face-to-face',
      component: FaceToFace,
      meta: {},
    },
    // 策划面对面-回答详情
    {
      name: 'face-to-face-answer-detail',
      path: '/face-to-face/answer-detail',
      component: FaceToFaceAnswerDetail,
      meta: {
        title: '回答详情',
      },
    },
    // 策划面对面-往期
    {
      name: 'face-to-face-history',
      path: '/face-to-face/history',
      component: FaceToFaceHistory,
      meta: {
        title: '更多精彩都在这',
      },
    },
    {
      name: 'activity-center',
      path: '/activity-center/index',
      component: ActivityCenter,
      meta: {
        title: '活动中心',
      },
    },
    // 圈子相关
    ...groupRoutes,
    ...demoRoutes,
    { // 横屏游戏内嵌下载页
      name: 'download-in-app',
      path: '/download/horizontal',
      component: () => import('../page/embed-page/download-in-app/index-h.vue'),
      meta: {
        title: '闪现一下',
      },
    }, { // 竖屏游戏内嵌下载页
      name: 'download-in-app',
      path: '/download/vertical',
      component: () => import('../page/embed-page/download-in-app/index-v.vue'),
      meta: {
        title: '闪现一下',
      },
    },
    { // 兼容横竖屏
      name: 'download-in-app',
      path: '/download/all',
      component: () => import('../page/embed-page/download-in-app/index.vue'),
      meta: {
        title: '闪现一下',
      },
    },
    { // 游戏内置页帖子
      name: 'download-in-app-post-detail',
      path: '/download/post-detail',
      component: () => import('../page/embed-page/download-in-app/post-detail/index.vue'),
      meta: {
        title: '闪现一下',
      },
    },
    {
      name: 'user-exam',
      path: '/user/exam',
      component: () => import('../page/user/exam/index.vue'),
    },
    {
      name: 'game-pay-redirect',
      path: '/game-pay-redirect',
      component: GamePayRedirect,
    },
    // {
    //   name: 'preload',
    //   path: '/preload',
    //   component: () => import('../page/common/preload/index.vue'),
    // },
    ...preloadRoutes, // 需要放在后面
    {
      name: 'error',
      path: '/error/common',
      component: CommonError,
    },
    { // 404页面
      path: '*',
      component: NotFound,
      meta: {
        //
      },
    },
  ],
});
router.beforeEach((to, from, next) => {
  logger.info('vueRouter beforeEach', from.fullPath, to.fullPath);
  if (to.meta && to.meta.title) {
    setWebTitle(to.meta.title);
  } else if (!checkEnvObj.isApp) {
    setWebTitle('闪现一下');
  } else {
    setWebTitle(' ');
  }
  if (window.skeleton && (!from.name || from.name.indexOf('preload') === -1) && to.name && to.name.indexOf('preload') === -1) {
    window.skeleton.load(to.path);
  }

  // 修复to.query参数变成数组的问题
  // 如果是数组就取第一个作为参数
  if (checkEnvObj.isGameHelper) {
    let redirect = false;
    const query = JSON.parse(JSON.stringify(to.query));
    if (to.query) {
      const queryKeys = Object.keys(to.query);
      for (let i = 0; i < queryKeys.length; i++) {
        if (Array.isArray(query[queryKeys[i]])) {
          redirect = true;
          [query[queryKeys[i]]] = query[queryKeys[i]];
        }
      }
    }
    if (redirect) {
      logger.info('redirect in beforeEach', query);
      next({
        path: to.path,
        query,
      });
    }
  }
  next();
});

router.afterEach((to, from) => {
  logger.info('vueRouter afterEach', from.fullPath, to.fullPath);
});

export default router;

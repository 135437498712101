import { clickReport } from './click';
import { exposureReport } from './exposure';

/**
 * 数据上报指令
 * @param {*} Vue
 * @param {*} option 相关选项
 */
function report(Vue, options) {
  const strategyObj = {
    click: clickReport(Vue),
    exposure: exposureReport(Vue, options),
  };

  return {
    bind: (el, binding) => {
      const strategy = strategyObj[binding && binding.arg];
      if (!strategy || !strategy.bind) {
        return;
      }

      strategy.bind(el, binding);
    },
    inserted: (el, binding) => {
      const strategy = strategyObj[binding && binding.arg];
      if (!strategy || !strategy.inserted) {
        return;
      }

      strategy.inserted(el, binding);
    },
    unbind: (el, binding) => {
      const strategy = strategyObj[binding && binding.arg];
      if (!strategy || !strategy.unbind) {
        return;
      }

      strategy.unbind(el);
    },
  };
}

export { report };

import { isServerRender, tswPlug, tegMonitor } from '@/util/env';
import { jsonToQueryString } from '@/util/uri';
import { API_HOST_TEST } from '@/util/get-base-url';

let tdbank;
let fromIp; // 本机器的内网ip

if (isServerRender && tswPlug) {
  tdbank = tswPlug('api/tdbank');
  const serverInfo = tswPlug('serverInfo');
  fromIp = serverInfo && serverInfo.intranetIp;
}
const timeCache = {};
function requestCb(config) {
  if (!isServerRender || !tdbank) {
    return;
  }
  const key = `${Date.now()}_${parseInt(Math.random() * 100, 10)}`;
  timeCache[key] = Date.now();
  // eslint-disable-next-line no-param-reassign
  config.plugCgiReport = key;
}


function responseCb(response, isError) {
  if (!isServerRender || !tdbank) {
    return;
  }
  if (response && response.config && response.config.plugCgiReport && timeCache[response.config.plugCgiReport]) {
    let type = -1;
    let code = -1;
    if (isError) { // 错误
      type = 2;
      const { message } = response;
      code = message;
    } else {
      type = 0;
      if (response.data) { // todo 支持配置返回码
        code = response.data.ret;
        if (code > 0) {
          type = 1;
        } else if (code < 0) {
          type = 3;
        }
      }
    }
    const params = {
      sysID: 9901108, // 秒级监控申请业务id
      intfID: response.config.baseURL.indexOf(API_HOST_TEST) === -1 ? 1 : 8, // 秒级监控申请的接口对应id
      totalCount: 1,
      netErrorCount: type === 2 ? 1 : 0, // 网络异常
      logicErrorCount: type === 1 ? 1 : 0, // 逻辑异常
      serverErrorCount: type === 3 ? 1 : 0, // 服务异常
      timeCost: Date.now() - timeCache[response.config.plugCgiReport],
      url: response.config.url.replace(/\?[^\s]+/, '').replace(/\//g, '-'),
      isFail: type,
      code,
      host: response.config.baseURL.replace(/[\w]+:\/\//, ''),
      fromIp,
      toIp: (response.config.proxy && response.config.proxy.host) || response.config.baseURL.replace(/[\w]+:\/\//, ''),
    };
    // console.log('返回码', JSON.stringify(params));
    tdbank.send('b_teg_rt_index', 'yoyo_api', jsonToQueryString(params));

    tegMonitor.reportItems([
      {
        metric: 'cgi_cnt', // 请求数
        value: 1,
        tags: {
          code, // code===0为正常
          url: params.url,
        },
      },
      {
        metric: 'cgi_time_cost', // 请求数
        value: params.timeCost,
        tags: {
          code,
          url: params.url,
        },
      },
    ]);
    delete timeCache[response.config.plugCgiReport];
  }
}

function errorCb(response) {
  responseCb(response, 1);
}
export default {
  request: requestCb,
  response: responseCb,
  error: errorCb,
};

/**
 * Created by homkerliu on 2017/11/20.
 */

import getUA from './getUA'
// import getAppVersion from './getAppVersion'
// import getChannelName from './getChannelName'

/**
 * 判断是否提审版本
 */
export default function isSUB () {
  let ua = getUA()
  // let isVivoSUB = (getAppVersion()==='3.6.1.236' && getChannelName()==='78711') //这个版本提审vivo市场被拒了,因为下载按钮的问题
  return (Number((ua.match(/sub\/([\d]){1}/) || [])[1]) === 1)  //1 为是，0为否
}
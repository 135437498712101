/**
 * formatSchema
 * @author donaldcen
 * @createTime 2017/9/19
 */
import object2url from '../../utils/object2url'

const SCHEMA = 'jsbridge'
/**
 * @desc 格式化那个叫什么schema(该层仅负责格式化协议，不做encode)
 * @param {object} data 输入参数
 * @option {string} data.schema 传入的协议头默认jsbridge
 * @param {String} data.url 必选，请求协议路径
 * @param {Object} data.params 参数对象
 * @returns {*}
 * @example
 * formatSchema({
 *  url:'ui/setWebViewBehavior',
 *  params: {p:'%7B%22navBgColor%22%3A%22%23FFFF0000%22%2C%22callback%22%3A%22__MQQ_CALLBACK_2%22%7D'}
 * })
 */
export default function (data) {
  if (!data || !data.url) {
    return false
  }
  let schema = data.schema || SCHEMA
  let url = data.url || ''
  let {params} = data
  params = object2url(params)
  // params += '&_webLaunchTime=' + Date.now()
  return [schema, '://', url, '?', params].join('')
}

//jsbridge://ui/setWebViewBehavior?p=%7B%22navBgColor%22%3A%22%23FFFF0000%22%2C%22callback%22%3A%22__MQQ_CALLBACK_2%22%7D#3
//jsbridge://ui/openView?p={"options":"qgameapi://video/room?aid=80290445&_bpname=live&_webLaunchTime=1498555512585"}
/* eslint-disable no-param-reassign */
import linkToPersonal from '@/plugin/prototype/linkToPersonal';
import linkToGroup from '@/plugin/prototype/linkToGroup';
import checkAuthDeco from '@/plugin/prototype/checkAuthDeco';
import checkAppDeco from '@/plugin/prototype/checkAppDeco';
import reportData from '@/plugin/prototype/reportData';
import closeSkeleton from '@/plugin/prototype/closeSkeleton';
import toast from '@/ui/toast/index';
import compareVersion from '@/util/compareVersion';
import tgcimg from '@/plugin/prototype/tgcimg';
import loading from '@/ui/loading/index';
import { showActionSheet } from '@/component/action-sheet/index';

export default function (Vue) {
  Vue.prototype.$linkToPersonal = linkToPersonal;
  Vue.prototype.$linkToGroup = linkToGroup;
  Vue.prototype.$checkAuthDeco = checkAuthDeco;
  Vue.prototype.$checkAppDeco = checkAppDeco;
  Vue.prototype.$reportData = reportData;
  Vue.prototype.$toast = toast;
  Vue.prototype.$loading = loading;
  Vue.prototype.$closeSkeleton = closeSkeleton;
  Vue.prototype.$compareVersion = compareVersion;
  Vue.prototype.$tgcimg = tgcimg;
  Vue.prototype.$showActionSheet = showActionSheet;
}

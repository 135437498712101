/* eslint-disable no-unreachable */
import Vue from 'vue';
import qs from 'qs';
import { logger } from '@/util/logger';
import jsbridge from '@/jsbridge';
import { addEventListener } from '@/util/webbundle/jsapi';
import { checkEnv } from '@/util/browser-env';
import { getPreloadRoutesName } from '@/util/webbundle/get-preload-routes';
import { toDirectUrl, toStaticUrl } from '@/util/uni-open-url';
import getUrlPath from './get-url-path';

const checkEnvObj = checkEnv();
const PRELOAD_LOADING_ROUTE_NAME = 'preload-loading';

// vue router 返回的钩子函数，用来取消注册的钩子。
let beforeResolveHook;
let status;
let preloadModuleList = []; // 存放preload准备好的组件，为了reuse时候方便获取

const STATUS_ENUM = {
  init: 0,
  preloading: 1,
  preloaded: 2,
  pushed: 3,
  reseting: 4,
};
let webbundleUseTimes = 0; // 记录重用次数
const loadedModules = {};

function clearAllCallback() {
  // 清除无用的变量，主要是全局回调函数
  const callbackList = Object.keys(window).filter(name => /^__PGG_CALLBACK/.test(name));
  callbackList.forEach((name) => {
    delete window[name];
  });
  logger.debug('clearAllCallback done');
}
function clearDom() {
  // 清理dom，删除全局添加的dom元素
  const whiteListId = ['app', 'skeleton-body', 'firstScreenHtml'];
  try {
    const parentNode = document.body;
    const divs = parentNode.childNodes;
    for (let i = 0; i < divs.length; i++) {
      if (divs[i].nodeType === 1 && divs[i].tagName.toLocaleLowerCase() === 'div') {
        if (whiteListId.indexOf(divs[i].id) === -1) {
          parentNode.removeChild(divs[i]);
        }
      }
    }
    logger.debug('clearDom done');
  } catch (error) {
    // logger.error('clearVariable error', error);
  }
}

function clearAllTimeout() {
  // eslint-disable-next-line no-empty-function
  let id = window.setTimeout(() => {}, 0);

  // eslint-disable-next-line no-plusplus
  while (id--) {
    window.clearTimeout(id); // will do nothing if no timeout with id is present
  }
  logger.debug('clearAllTimeout done');
}

const ERROR_OVER_TIMES = 2;
/**
 * 记录webbundle失败的次数，超出次数则禁用方案
 */
function addNoUseTimes() {
  const key = 'webbundle_speedy_error_times';
  let times = localStorage.getItem(key);

  times = parseInt(times, 10);
  times = Number.isNaN(times) ? 0 : times;

  if (times >= 0) {
    times += 1;

    // 超过一次，先禁用加速方案
    if (times >= ERROR_OVER_TIMES) {
      logger.info('关闭 webbundle 方案', `status=${status}`);
      if (checkEnvObj.ios) {
        jsbridge.core.call(
          'ui/setWebBundleOption',
          { useWebBundle: 0 },
        );
      } else {
        try {
          window.webbundle.setWebBundleOption(0);
        } catch (e) {
          logger.info('安卓关闭 webbundle 方案 失败', `status=${status}`, e);
        }
      }
      // 超过两次的，关闭方案，然后清0
      localStorage.setItem(key, 0);
    } else {
      localStorage.setItem(key, times);
    }
  }
}

/**
 * 记录组件的生命周期
 * @deprecated 测试用的
 */
export function preloadMixin() {
  Vue.mixin({
    beforeCreate() {
      logger.info(`[lifecycle] ${this.$options.name} beforeCreate ${+new Date()}`);
    },
    created() {
      logger.info(`[lifecycle] ${this.$options.name} created ${+new Date()}`);
    },
    mounted() {
      logger.info(`[lifecycle] ${this.$options.name} mounted ${+new Date()}`);
    },
    activated() {
      logger.info(`[lifecycle] ${this.$options.name} activated ${+new Date()}`);
    },
  });
}

export function getQuery(url) {
  const d = url.indexOf('?');
  const str = url.slice(d + 1);
  return qs.parse(str);
}

/**
 * 通知客户端preload页面可用
 */
function preloadReady() {
  window.isPreloadExist = function isPreloadExist() {
    return true;
  };
  status = STATUS_ENUM.preloaded;
  if (checkEnvObj.ios) {
    // window.location.href = 'jsbridge://comic/preloadViewSuccess';
    jsbridge.core.call('comic/preloadViewSuccess');
    logger.info('ios通知 preloadViewSuccess', `status=${status}`);
    // console.log('ios通知 preloadViewSuccess');
  } else if (checkEnvObj.android) {
    try {
      // android通知终端preload页已ok可以使用
      logger.info('安卓通知 preloadViewSuccess');
      window.webbundle.preloadViewSuccess();
    } catch (e) {
      // do nothing
      logger.error('安卓通知 preloadViewSuccess 失败', `status=${status}`, e);
    }
  }
}

// 预选注册store，加快速度
function preloadRegisterStore(comp, { store }) {
  if (comp && comp.storeModules) {
    logger.debug('call preloadRegisterStore');
    // console.log('preloadRegisterStore', comp);
    store.registerAllModules(comp);
  }
}

// 开始加载preload
function onWebbundlePreloadView({ store, router }) {
  logger.debug('onWebbundlePreloadView', `status=${status}`);
  if (status !== STATUS_ENUM.init) {
    return;
  }
  status = STATUS_ENUM.preloading;
  // 监听页面可见性，防止preload页面被展示出来。如果出现超过5秒则跳转到错误页面
  jsbridge.ui.setOnVisibilityChange({
    callback: (res) => {
      if (res.result === 0) {
        if (res.data.visibilityState) {
          setTimeout(() => {
            // 如果超时5S之后还在preload页，说明执行出错，跳转失败，跳转到错误页面
            if (router.currentRoute.name === PRELOAD_LOADING_ROUTE_NAME) {
              logger.info('webbundle 加速失败 停留在preload页面');
              addNoUseTimes();
              router.replace('/error/common');
            }
          }, 5000);
        }
      }
    },
  });
  if (router.currentRoute.name === PRELOAD_LOADING_ROUTE_NAME) {
    // 这里需要注意，这里的routerName是你项目中preload页面的路由名
    const promiseList = [];
    // 路由都把需要加载的都加载
    const routes = getPreloadRoutesName();
    logger.debug('获得预加载路由 routes', routes);
    // 加载多个可能需要加载的vue
    routes.forEach((route) => {
      // 如果模块没有加载过，则加载
      if (!loadedModules[route]) {
        router.getMatchedComponents({
          name: route,
        }).forEach((fn) => {
          if (typeof fn === 'function') {
            // 将bundle的执行结果推入
            promiseList.push(fn());
            // 将已加载的路由模块标记
            loadedModules[route] = true;
          }
        });
      }
    });

    // 确保所有bundle都加载ok了才通知终端
    // 这里调用的JSAPI依赖于所处环境
    Promise.all(promiseList).then((moduleList) => {
      // 通知终端，preload页面已经准备好，可以使用
      preloadModuleList = moduleList;
      logger.warn('preloadModuleList', preloadModuleList);
      preloadModuleList.forEach((m) => {
        preloadRegisterStore(m.default, { store, router });
      });
      preloadReady();
    });
  }
}


// TODO: 调试代码
/*
function onWebbundlePushViewDebug (data) {
  logger.info('<<<<<<<<<<<< onWebbundlePushViewDebug >>>>>>>>>>>');
  console.timeStamp();
  webbundleUseTimes += 1;
  logger.warn('webbundle', `useTimes:${webbundleUseTimes}`, 'onWebbundlePushViewDebug');
  logger.info('url', data.url, `status=${status} ${new Date().getTime()}`);
  let url = getUrlPath(data.url);
  if (url.indexOf('?') === -1) {
    url += '?pageOpenType=2';
  } else {
    url += '&pageOpenType=2';
  }

  if (window.skeleton) {
    const path = url.split('?')[0];
    console.log(`webbundle skeleton show url = ${path} ${new Date().getTime()}`);
    window.skeleton.load(path);
  }

  router.replace(url);
  status = STATUS_ENUM.pushed;
}
*/

// 进入页面
function onWebbundlePushView(data, { store, router }, ssrData) {
  logger.info('<<<<<<<<<<<< onWebbundlePushView >>>>>>>>>>>');
  webbundleUseTimes += 1;
  logger.warn('webbundle', `useTimes:${webbundleUseTimes}`, 'onWebbundlePushView');
  logger.info('url', data.url, `status=${status}`);
  store.commit('app/setIsInBackground', false);

  // 如果5s之后还在preload页，则说明跳转失败了，此处为容错逻辑
  setTimeout(() => {
    if (router.currentRoute.name === PRELOAD_LOADING_ROUTE_NAME) {
      window.location.replace(data.url);
      logger.info('preload页面跳转失败。自动跳转', `status=${status}`);
    }
  }, 5000);


  let preData;
  if (checkEnvObj.ios) {
    if (typeof data.data === 'string') {
      try {
        preData = JSON.parse(data.data);
      } catch (e) {
        logger.error('ios获取preData失败', `status=${status}`, e);
      }
    } else if (typeof data.data === 'object') {
      preData = data.data;
    }
    logger.info('ios获取preData', `status=${status}`, preData);
  } else if (checkEnvObj.android) {
    logger.debug('window.webbundle', window.webbundle);
    if (typeof window.webbundle === 'object' && window.webbundle.getData) {
      try {
        preData = JSON.parse(window.webbundle.getData());
        logger.info('安卓获取preData', `status=${status}`, preData);
      } catch (e) {
        logger.error('安卓获取preData失败', `status=${status}`, e);
        preData = null;
      }
    }
  } else {
    try {
      preData = JSON.parse(data.data);
    } catch (e) {
      logger.error('获取preData失败', `status=${status}`, e);
    }
  }
  let { url } = data;
  // 兼容跳转页面
  if (router.options.base === '/direct/pages/') {
    url = toDirectUrl(url);
  } else {
    url = toStaticUrl(url);
  }
  url = getUrlPath(url);

  /*
  if (preData) {
    const matched = router.getMatchedComponents({
      path: url,
    });
    matched.forEach((c) => {
      if (c.storeName) {
        store.commit(`${c.storeName}/setPreData`, preData);
      }
    });
  }
  */


  /**
   * 兼容SSR构建
   * 如果是走ssr，则asyncData逻辑放在client entry里面
   */
  if (ssrData) {
    // eslint-disable-next-line no-param-reassign
    ssrData.preData = preData;
  } else if (preData) {
    router.onReady(() => {
      beforeResolveHook = router.beforeResolve((to, from, next) => {
        const matched = router.getMatchedComponents(to);
        // eslint-disable-next-line max-nested-callbacks
        Promise.all(matched.map((c) => {
          // if (c.asyncData) {
          //   return c.asyncData({ store, route: to }, preData);
          // }
          if (c.storeName) {
            store.commit(`${c.storeName}/setPreData`, preData);
          }
          return false;
        })).then(next)
          .catch(next);
      });
    });
  }

  if (url.indexOf('?') === -1) {
    url += '?pageOpenType=1';
  } else {
    url += '&pageOpenType=1';
  }

  logger.info('before url replace', url);

  router.replace(url);
  status = STATUS_ENUM.pushed;
}

// 重新设置preload页面
function resetPreload({ store, router }) {
  logger.info('call resetPreload');
  store.dispatch('resetState');
  clearAllTimeout();
  clearDom();
  clearAllCallback();
  preloadModuleList.forEach((m) => {
    preloadRegisterStore(m.default, { store, router });
  });
  router.replace({ name: PRELOAD_LOADING_ROUTE_NAME });
  if (typeof beforeResolveHook === 'function') {
    beforeResolveHook();
    logger.debug('beforeResolveHook success');
  }
  setTimeout(() => {
    logger.info('setTimeout preloadReady');
    preloadReady();
  }); // 这里设置延迟的话ios可能会不执行
}

// 重新设置状态
// eslint-disable-next-line no-unused-vars
function onWebbundleReset({ store, router }) {
  logger.info('call onWebbundleReset');
  store.commit('app/setIsInBackground', true);
  resetPreload({ store, router });
  // TODO 重设 window对象 变量；注销监听的事件；setTimeout注销
}

// 注册
export function registerWebbundleEvents({ store, router }, ssrData) {
  status = STATUS_ENUM.init; // preload页面状态
  logger.info('webbundle init', `status=${status}`);
  addEventListener('vashybrid_bundle_message', (event) => {
    logger.info('收到事件', 'vashybrid_bundle_message', `status=${status}`, event);
    const data = (event) || {};
    // 如果是preloadView，则加载对应组件的bundle
    if (data.type === 'preloadView') {
      onWebbundlePreloadView({ store, router });
    } else if (data.type === 'pushView') {
      onWebbundlePushView(data, { store, router }, ssrData);
    } else if (data.type === 'reset') {
      onWebbundleReset({ store, router }, ssrData);
    }
  });
}

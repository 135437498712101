/*Created by 文赫 on 2017-11-14.*/

import isQgameLiveassistant from './isQgameLiveassistant'
import isQgame from './isQgame'

/**
 * 获取协议头
 * @returns {*}
 */
export default function () {
  if (isQgame()) {
    return 'qgameapi'
  } else if (isQgameLiveassistant()) {
    return 'qgamelive'
  }
  return ''
}

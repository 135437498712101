<template>
  <transition name="ui-loading-fade">
    <div
      v-show="show"
      class="ui-loading"
    >
      <div class="ui-loading-inner" />
      <div class="ui-loading-text">
        {{ message }}
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    message: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  computed: {
    show() {
      return this.visible || this.loading;
    },
  },
};
</script>

<style lang="scss" src="./scss/index.scss"></style>

import cookie from 'js-cookie';
import qs from 'qs';
/**
 * 工具库，用来沉淀一些常用方法
 * */

/**
 * 转换一些html标签，防XSS
 * @param {String} htmlStr  html标签字符串
 * @return {String}
 * @example
 *  filterHtml('<script>alert(1)</script>');
 * */
export function filterHtml(htmlStr) {
  if (htmlStr === null || htmlStr.length === 0) {
    return htmlStr;
  }
  let textStr = '';
  for (let i = 0; i < htmlStr.length; i++) {
    const ch = htmlStr.charAt(i);
    switch (ch) {
    /* case '&':
        textStr += "&amp;";
        break; */
      case '<':
        textStr += '&lt;';
        break;
      case '>':
        textStr += '&gt;';
        break;
      case '"':
        textStr += '&quot;';
        break;
      case '\'':
        textStr += '&#x27;';
        break;
      case '/':
        textStr += '&#x2F;';
        break;
      default:
        textStr += ch;
    }
  }
  return textStr;
}

// 转换数据格式
function transNum(num) {
  if (num < 10) return `0${num}`;
  return `${num}`;
}

/**
 * 获取相对时间文案
 * @param {Number} createTime  开始时间
 * @param {Number} currentTime  当前时间，这里传参的目的是避免出现"现网时间和用户本地时间不一致"
 * @return {String} 返回相对时间文案
 * @example
 *  getRelativeTime(1574231373, 1574231375);
 * */
export function getRelativeTime(createTime, currentTime) {
  // 返回
  if (!createTime) {
    return '';
  }
  // eslint-disable-next-line no-param-reassign
  createTime = Number(createTime);
  const nowTime = currentTime || (+(new Date()) / 1000);
  const intervalTime = nowTime - createTime;
  if (intervalTime > 0 && intervalTime < 60) {
    return '刚刚';
  }
  if (intervalTime > 0 && intervalTime < 3600) {
    return `${Math.floor(intervalTime / 60)}分钟前`;
  }
  if (intervalTime > 0 && intervalTime < 3600 * 24) {
    return `${Math.floor(intervalTime / 3600)}小时前`;
  }
  const createTimeDate = new Date(createTime * 1000);
  const y = createTimeDate.getFullYear();
  const m = createTimeDate.getMonth() + 1;
  const d = createTimeDate.getDate();

  const nowTimeDate = new Date(nowTime * 1000);
  if (
    nowTimeDate.getFullYear() === y
    && nowTimeDate.getMonth() + 1 === m
    && nowTimeDate.getDate() - 1 === d
  ) {
    return '昨天';
  }

  if (nowTimeDate.getFullYear() === y) {
    return `${transNum(m)}-${transNum(d)}`;
  }
  return `${transNum(y)}-${transNum(m)}-${transNum(d)}`;
}

/**
 * 时间戳转换为日期
 * @param {Number} timestamp 时间戳，秒级
 */
export function getLocalDate(timestamp) {
  let time;
  if (typeof timestamp === 'string') {
    time = parseInt(timestamp, 10) * 1000;
  } else if (typeof timestamp === 'number') {
    time = timestamp * 1000;
  } else {
    throw Error('时间戳格式不正确');
  }
  if (Number.isNaN(time)) return '';
  const date = new Date(time);
  const y = date.getFullYear();
  const m = date.getMonth() + 1;
  const d = date.getDate();

  return `${transNum(y)}.${transNum(m)}.${transNum(d)}`;
}

/**
 * 数字转化
 * @param {Number} num  数字
 * @return {String}
 * @example
 *  translateNumToText(1350);
 * */
export function translateNumToText(num) {
  if (num > 10000) {
    return `${(num / 10000).toFixed(1)}w`;
  } if (num > 1000) {
    return `${(num / 1000).toFixed(1)}k`;
  }
  return String(num);
}

/**
 * 时长转化
 * @param {Number} seconds  秒
 * @return {String} 时:分:秒
 * */
export function translateSecondsToText(seconds) {
  const second = seconds % 60;
  const minute = Math.floor(seconds / 60);
  const hour = Math.floor(minute / 60);
  if (hour) {
    return `${String(hour).padStart(2, 0)}:${String(minute).padStart(2, '0')}:${String(second).padStart(2, '0')}`;
  }
  return `${String(minute).padStart(2, '0')}:${String(second).padStart(2, '0')}`;
}

/**
 * 跳转到客户端的方法
 * @param {String} moduleName  模块名
 * @param {Object} param  传参
 * @example
 *  jumpToClient('group');
 * */
// export function jumpToClient (moduleName) {
//   window.open('/');
// }

/**
 * 请求载入并执行一个 JavaScript 文件
 *
 * @method getScript
 * @param {String} url js文件的url
 * @param {Object} scriptParam 要附加的属性
 * @return Promise
 */
export function getScript(url, scriptParam) {
  return new Promise(((resolve, reject) => {
    let loadCount = 0;
    (function loadScript() {
      const script = document.createElement('script');
      script.async = 'async';
      script.src = url;
      script.onload = resolve;
      script.onerror = (e) => {
        // 同域名连续2次加载失败的话才做失败处理
        loadCount += 1;
        if (loadCount >= 2) {
          reject(e);
        } else {
          if (scriptParam && scriptParam.crossOrigin) {
            // eslint-disable-next-line no-param-reassign
            delete scriptParam.crossOrigin;
          }
          loadScript();
        }
      };
      if (scriptParam) {
        Object.keys(scriptParam).forEach((attr) => {
          script[attr] = scriptParam[attr];
        });
      }
      document.getElementsByTagName('head')[0].appendChild(script);
    }());
  }));
}

/**
 * 加载外部 css
 * @param {String} url css文件url
 */
export function loadCssLink(url) {
  const head = document.getElementsByTagName('head')[0];
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.type = 'text/css';
  link.href = url;
  head.appendChild(link);
}

export function createLinkStyle(href, before, media) {
  /* eslint-disable */
  function onloadCSS (ss, callback) {
    let called;
    function newcb () {
      if (!called && callback) {
        called = true;
        callback.call(ss);
      }
    }
    if (ss.addEventListener) {
      ss.addEventListener('load', newcb);
    }
    if (ss.attachEvent) {
      ss.attachEvent('onload', newcb);
    }

    // This code is for browsers that don’t support onload
    // No support for onload (it'll bind but never fire):
    //	* Android 4.3 (Samsung Galaxy S4, Browserstack)
    //	* Android 4.2 Browser (Samsung Galaxy SIII Mini GT-I8200L)
    //	* Android 2.3 (Pantech Burst P9070)

    // Weak inference targets Android < 4.4
    if ('isApplicationInstalled' in navigator && 'onloadcssdefined' in ss) {
      ss.onloadcssdefined(newcb);
    }
  }
  function loadCSS () {
    // Arguments explained:
    // `href` [REQUIRED] is the URL for your CSS file.
    // `before` [OPTIONAL] is the element the script should use as a reference for injecting our stylesheet <link> before
    // By default, loadCSS attempts to inject the link after the last stylesheet or script in the DOM. However, you might desire a more specific location in your document.
    // `media` [OPTIONAL] is the media type or query of the stylesheet. By default it will be 'all'
    const doc = window.document;
    const ss = doc.createElement('link');
    let ref;
    if (before) {
      ref = before;
    } else {
      const refs = (doc.body || doc.getElementsByTagName('head')[0]).childNodes;
      ref = refs[refs.length - 1];
    }

    const sheets = doc.styleSheets;
    ss.rel = 'stylesheet';
    ss.href = href;
    // temporarily set media to something inapplicable to ensure it'll fetch without blocking render
    ss.media = 'only x';

    // wait until body is defined before injecting link. This ensures a non-blocking load in IE11.
    function ready (cb) {
      if (doc.body) {
        return cb();
      }
      setTimeout(() => {
        ready(cb);
      });
    }
    // Inject link
    // Note: the ternary preserves the existing behavior of "before" argument, but we could choose to change the argument to "after" in a later release and standardize on ref.nextSibling for all refs
    // Note: `insertBefore` is used instead of `appendChild`, for safety re: http://www.paulirish.com/2011/surefire-dom-element-insertion/
    ready(() => {
      ref.parentNode.insertBefore(ss, (before ? ref : ref.nextSibling));
    });
    // A method (exposed on return object for external use) that mimics onload by polling document.styleSheets until it includes the new sheet.
    var onloadcssdefined = function (cb) {
      const resolvedHref = ss.href;
      let i = sheets.length;
      while (i--) {
        if (sheets[i].href === resolvedHref) {
          return cb();
        }
      }
      setTimeout(() => {
        onloadcssdefined(cb);
      });
    };

    function loadCB () {
      if (ss.addEventListener) {
        ss.removeEventListener('load', loadCB);
      }
      ss.media = media || 'all';
    }

    // once loaded, set link's media back to `all` so that the stylesheet applies once it loads
    if (ss.addEventListener) {
      ss.addEventListener('load', loadCB);
    }
    ss.onloadcssdefined = onloadcssdefined;
    onloadcssdefined(loadCB);
    return ss;
  }
  return new Promise((resolve, reject) => {
    onloadCSS(loadCSS(href, before, media), () => {
      resolve();
    });
  });
}

/**
 *
 * @param {Function} fn  实际要执行的函数
 * @param {Number} delay 延迟时间，也就是阈值，单位是毫秒（ms）
 *
 * @return {Function} 返回一个“去弹跳”了的函数
 */
export function debounce(fn, delay) {

  // 定时器，用来 setTimeout
  var timer

  // 返回一个函数，这个函数会在一个时间区间结束后的 delay 毫秒时执行 fn 函数
  return function () {

    // 保存函数调用时的上下文和参数，传递给 fn
    var context = this
    var args = arguments

    // 每次这个返回的函数被调用，就清除定时器，以保证不执行 fn
    clearTimeout(timer)

    // 当返回的函数被最后一次调用后（也就是用户停止了某个连续的操作），
    // 再过 delay 毫秒就执行 fn
    timer = setTimeout(function () {
      fn.apply(context, args)
    }, delay)
  }
}

/**
 *
 * @param {Function} fn  实际要执行的函数
 * @param {Number} delay 执行间隔，单位是毫秒（ms）
 *
 * @return {Function}     返回一个“节流”函数
 */

export function throttle (fn, delay) {

  // 记录上次执行的时间
  var last

  // 定时器
  var timer

  // 默认间隔为 250ms
  delay || (delay = 250)

  // 返回的函数，每过 threshhold 毫秒就执行一次 fn 函数
  return function () {

    // 保存函数调用时的上下文和参数，传递给 fn
    var context = this
    var args = arguments

    var now = +new Date()

    // 如果距离上次执行 fn 函数的时间小于 threshhold，那么就放弃
    // 执行 fn，并重新计时
    if (last && now < last + delay) {
      clearTimeout(timer)

      // 保证在当前时间区间结束后，再执行一次 fn
      timer = setTimeout(function () {
        last = now
        fn.apply(context, args)
      }, delay)

      // 在时间区间的最开始和到达指定间隔的时候执行一次 fn
    } else {
      last = now
      fn.apply(context, args)
    }
  }
}

/**
 * 驼峰转换
 * @param {String} str 驼峰字符串
 * @param {String} [syntax=-] 标识符，默认中划线
 * @example
 *  transCamecase('HelloWorld')
 *  // 'hello-world'
 */
export function transCamecase (str, syntax = '-') {
  if (typeof str !== 'string') {
    return '';
  }
  return str.replace(/[A-Z]/g, (match, index) => {
    return `${index ? syntax : ''}${match.toLocaleLowerCase()}`;
  });
}

/**
 * 判断数字二进制位是否为1
 * @param {Number|String} num 数字
 * @param {Number} pos bit位，从0开始
 * @returns {Boolean}
 */
export function isBitTrue (num, pos) {
  if (typeof num === 'string') {
    num = parseInt(num, 10);
  }
  if (num < 0 || pos < 0) {
    throw '数字不能为负数';
  }
  const n = 2 ** pos;
  return (num & n) === n; // 按位与操作
}

export const humps = (() => {
  var _processKeys = function(convert, obj, options) {
    if(!_isObject(obj) || _isDate(obj) || _isRegExp(obj) || _isBoolean(obj) || _isFunction(obj)) {
      return obj;
    }

    var output,
      i = 0,
      l = 0;

    if(_isArray(obj)) {
      output = [];
      for(l=obj.length; i<l; i++) {
        output.push(_processKeys(convert, obj[i], options));
      }
    }
    else {
      output = {};
      for(var key in obj) {
        if(Object.prototype.hasOwnProperty.call(obj, key)) {
          output[convert(key, options)] = _processKeys(convert, obj[key], options);
        }
      }
    }
    return output;
  };

  // String conversion methods

  var separateWords = function(string, options) {
    options = options || {};
    var separator = options.separator || '_';
    var split = options.split || /(?=[A-Z])/;

    return string.split(split).join(separator);
  };

  var camelize = function(string) {
    if (_isNumerical(string)) {
      return string;
    }
    string = string.replace(/[\-_\s]+(.)?/g, function(match, chr) {
      return chr ? chr.toUpperCase() : '';
    });
    // Ensure 1st char is always lowercase
    return string.substr(0, 1).toLowerCase() + string.substr(1);
  };

  var pascalize = function(string) {
    var camelized = camelize(string);
    // Ensure 1st char is always uppercase
    return camelized.substr(0, 1).toUpperCase() + camelized.substr(1);
  };

  var decamelize = function(string, options) {
    return separateWords(string, options).toLowerCase();
  };

  // Utilities
  // Taken from Underscore.js

  var toString = Object.prototype.toString;

  var _isFunction = function(obj) {
    return typeof(obj) === 'function';
  };
  var _isObject = function(obj) {
    return obj === Object(obj);
  };
  var _isArray = function(obj) {
    return toString.call(obj) == '[object Array]';
  };
  var _isDate = function(obj) {
    return toString.call(obj) == '[object Date]';
  };
  var _isRegExp = function(obj) {
    return toString.call(obj) == '[object RegExp]';
  };
  var _isBoolean = function(obj) {
    return toString.call(obj) == '[object Boolean]';
  };

  // Performant way to determine if obj coerces to a number
  var _isNumerical = function(obj) {
    obj = obj - 0;
    return obj === obj;
  };

  // Sets up function which handles processing keys
  // allowing the convert function to be modified by a callback
  var _processor = function(convert, options) {
    var callback = options && 'process' in options ? options.process : options;

    if(typeof(callback) !== 'function') {
      return convert;
    }

    return function(string, options) {
      return callback(string, convert, options);
    }
  };

  return {
    camelize: camelize,
    decamelize: decamelize,
    pascalize: pascalize,
    depascalize: decamelize,
    camelizeKeys: function(object, options) {
      return _processKeys(_processor(camelize, options), object);
    },
    decamelizeKeys: function(object, options) {
      return _processKeys(_processor(decamelize, options), object, options);
    },
    pascalizeKeys: function(object, options) {
      return _processKeys(_processor(pascalize, options), object);
    },
    depascalizeKeys: function () {
      return this.decamelizeKeys.apply(this, arguments);
    }
  };
})();

export function setTitle (title) {
  document.title = title
  // 下方代码目的是为了兼容iphone更换
  var mobile = navigator.userAgent.toLowerCase()
  if (/iphone|ipad|ipod/.test(mobile)) {
    var iframe = document.createElement('iframe')
    iframe.style.display = 'none'
    iframe.setAttribute('src', '//www.tencent.com/img/index/tencent_logo.png')
    var iframeCallback = function () {
      setTimeout(function () {
        iframe.removeEventListener('load', iframeCallback)
        document.body.removeChild(iframe)
      }, 0)
    }
    iframe.addEventListener('load', iframeCallback)
    document.body.appendChild(iframe)
  }
}

/**
 * 打乱数组，洗牌算法
 * @param {Array.<T>} arr 数组
 */
export function randomSort (arr) {
  for (let i = 0; i < arr.length; i++) {
    const j = Math.floor(Math.random() * (arr.length - i));
    const temp = arr[i];
    arr[i] = arr[j];
    arr[j] = temp;
  }
  return arr;
}

export function cssSupport (attr, value) {
  let element = document.createElement('div');
  if (attr in element.style) {
    if (!Array.isArray(value)) value = [value]
    let flag = false
    flag = value.some(item => {
      element.style[attr] = item;
      return element.style[attr] === item;
    })
    return flag
  } else {
    return false;
  }
}
/**
 * 将元素滚动到可见位置
 * @param options
 * @param scrollElement 要滚动的元素
 * @param scrollTop 需要可见的元素
 * @param animationType
 */
export function scrollTo ({scrollElement, scrollTop, animationType = 'linear'}) {
  const tween = {
    linear: function ( t, b, c, d ) {
      return c * t / d + b;
    },
    easeIn: function ( t, b, c, d ) {
      return c * ( t /= d ) * t + b;
    },
    strongEaseIn: function (t, b, c, d) {
      return c * ( t /= d ) * t * t * t * t + b;
    },
    strongEaseOut: function (t, b, c, d) {
      return c * ( ( t = t / d - 1) * t * t * t * t + 1 ) + b;
    },
    sineaseIn: function ( t, b, c, d ) {
      return c * ( t /= d) * t * t + b;
    },
    sineaseOut: function (t,b,c,d) {
      return c * ( ( t = t / d - 1) * t * t + 1 ) + b;
    }
  }
  // const clientHeight = scrollElement.clientHeight
  const scrollTarget = scrollTop
  const scrollStart = scrollElement.scrollTop
  let start = null
  const step = (timestamp) => {
    if (!start) {
      start = timestamp
    }
    let stepScroll = tween[animationType](timestamp - start, 0, scrollTarget, 1000)
    console.log(`scrollStart: ${scrollStart}, stepScroll: ${stepScroll}`)
    let total = scrollElement.scrollTop = scrollStart + stepScroll
    if (total < scrollStart + scrollTarget) {
      requestAnimationFrame(step)
    }
  }
  requestAnimationFrame(step)
}

/**
 * 页面在屏幕Y轴方向上，滑动指定的距离
 * @param {number} scrollDistance 滑动的距离
 * @param {string} animationType 动画模型(默认strongEaseOut模型滑动)
 */
export function screenYAxisScroll({
  scrollDistance,
  animationType = 'strongEaseOut'
}) {
  /**
   * 安卓app中，document的body、documentElement、scrollingElement
   * 设置scrollTop均无效，因此这里使用外层app容器进行滑动设置
   */
  const vueRoot = document.getElementById('app');
  if (!vueRoot) {
    return;
  }

  vueRoot.style.overflow = 'auto';
  vueRoot.style['-webkit-overflow-scrolling'] = 'touch';
  scrollTo({
    animationType,
    scrollElement: vueRoot,
    scrollTop: scrollDistance,
  });
}

/**
 * 解析url query字符串
 *
 * 不解析数组, 防止出现链接参数重复的时候导致解析成数组
 * 例如 ?a=1&a=1，qs会解析为 {a:['1','2']}，这个方法只会取后面的参数 {a:'2'}
 *
 * @param {String} [url] 传入url，不传则取当前页面url
 * @returns {Object}
 */
export function getUrlQuery (url) {
  const query = {};
  if (!url) {
    url = window.location.href;
  }
  try {
    url = url.split('#')[0];
    const queryStr = url.split('?')[1] || '';
    const pairs = queryStr.split('&');
    if (!pairs) return {};
    for (let i = 0; i < pairs.length; i++) {
      let pair = pairs[i].split('=');
        if (pair[0] && pair[1]) {
          query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
        }
    }
  } catch (error) {
    console.error('getUrlQuery error', error);
  }
  console.log(getUrlQuery, query);
  return query;
}


/**
 * 添加URL插件，用于一些常用的链接处理方法
 */

/**
 * 添加URL参数
 * @param {String} originUrl 原始链接
 * @param {Object} param 参数键值对,如：{p1: 1,p2: 2}
 * @return {String} url 拼装后的链接
 * @example
 *  appendParam('https://m.gamplus.qq.com', {p1: 1});
 *  appendParam('https://m.gamplus.qq.com?t1=1', {p1: 1});
 *  appendParam('https://m.gamplus.qq.com?t1=1&t2=2', {p1: 1});
 *  appendParam('https://m.gamplus.qq.com?t1=1&t2=2#s1=1', {p1: 1, p2: 2});
 * */
export function appendParam(originUrl, param) {
  if (!param) {
    return originUrl;
  }
  let tmpUrl = originUrl;
  let tail = '';
  if (originUrl.indexOf('#') > 0) {
    tmpUrl = originUrl.split('#')[0];
    tail = `#${originUrl.split('#')[1]}`;
  }
  try {
    const gap = tmpUrl.indexOf('?') < 0 ? '?' : '&';
    const paramArr = [];
    Object.keys(param).forEach((item) => {
      const value = encodeURIComponent(param[item]);
      paramArr.push(`${item}=${value}`);
    });
    return `${tmpUrl}${gap}${paramArr.join('&')}${tail}`;
  } catch (e) {
    console.log('链接转化异常', originUrl, e);
    return originUrl;
  }
}

/**
 * 获取策略列表中，命中策略的工具函数
 * @param { Array<{condition: boolean, value: T }> } strategyList 策略列表
 * @param { T } defaultStrategy 默认的命中策略，如果为空，返回策略列表第一项
 * @returns { T } strategy 策略列表中的某项value 或 defaultStrategy
 * @example
 * getStrategy([{condition: false, value: 1}, {condition: true, value: 2}]) => 2
 * getStrategy([condition: false, value: 2], 3) => 3
 * getStrategy([condition: false, value: 2]) => 2
 */
export function getStrategy(strategyList, defaultStrategy = undefined) {
  let strategy = defaultStrategy;
  if (typeof strategy === "undefined") {
    strategy = strategyList && strategyList[0];
  }

  for (let item of strategyList) {
    if (Boolean(item.condition) === true) {
      strategy = item.value;
      break;
    }
  }
  return strategy;
}

const util = {
  filterHtml,
  getRelativeTime,
  translateNumToText,
  getScript,
  debounce,
  cookie,
  transCamecase,
  humps,
  qs,
  setTitle,
  appendParam,
  getStrategy,
};

export default util;

/**
 * 帖子API
 * */
// import GamePlusAPI from '../util/gameplus-api';
// import postInfoMockData from '../mock/postInfo.json';
import { gameplusApi } from '@/util/http';

export default {
  /**
   * 获取用户任务列表和完成状态
   * @param { Object } params
   * @param { Number | String } params.uid 用户id
   * @return { Object } 返回获取结果
   * */
  getMissions({ uid } = {}) {
    return gameplusApi.request({
      url: '/community.MissionSrv/GetMissions',
      data: {
        uid,
      },
      // mock: postInfoMockData,
      options: {
        errorHandler: false,
      },
    });
  },
  /**
   * 通过type获取任务完成情况
   * @param { Object } params
   * @param { Number } params.type 帖子ID
   * @return { Object } 返回获取结果
   * DailyAnswer     = 0;  // 每日回答
   * DailyPostStar   = 1;  // 每日点赞5次
   * DailyShare      = 2;  // 每日分享帖子
   * DailyAnswerStar = 3;  // 每日回答被赞5次
   * OnceNotify      = 4;  // 开启消息通知
   * OncePhone       = 5;  // 绑定手机
   * */
  getMissionConfig({ type } = {}) {
    return gameplusApi.request({
      url: '/community.MissionSrv/GetMissionConfig',
      data: {
        type,
      },
      // mock: postInfoMockData,
      options: {
        errorHandler: false,
      },
    });
  },
};

import report from '@/api/report';
import getBaseUrl from '../get-base-url';
import Http from './http';
import { logger } from '../logger';
import serverCgiReport from '@/util/server-cgi-report';
import serverCgiProxy from '@/util/server-cgi-proxy';
import serverCgiCookie from '@/util/server-cgi-cookie';
import { isServerRender } from '@/util/env';
import {
  csrfToken, decamelizeRequest, putHeaders, retFilter, authenticateFailed, universalErrorHandler,
  camelizeResponse, requestId, errorLogger, encodeReq, decodeRes,
} from './middleware';
import { checkEnv } from '../browser-env';

const checkEnvObj = checkEnv();

const NETWORK_TIMEOUT_DELAY = 20000; // 超时时间

// eslint-disable-next-line import/prefer-default-export
export const gameplusApi = new Http({
  baseURL: getBaseUrl(),
  timeout: NETWORK_TIMEOUT_DELAY,
});

/**
 * 网络错误上报错误码
 * @enum {Number}
 */
const ERR_CODE = {
  ERR_NO_NETWORK: 1001, // 无网络。前端暂不报
  ERR_NEWORK: 1002, // 网络错误
  ERR_RESPONSE_EMPTY: 1011, // 返回体为空
  ERR_DECODE_ERROR_FRAME: 1012, // 框架解码错误。前端对应的是返回格式不正确
  ERR_DECODE_ERROR_BUSINESS: 1013, // 业务解码错误。前端暂不报
  ERR_TIMEOUT: 1003, // 网络超时
};

/**
 * 请求上报
 * @param {String} module - 模块名.方法名
 * @param {Number} status - 请求状态（成功: 1; 失败: 0）
 * @param {Number} time - 耗时（单位: 毫秒）
 * @param {Number} errorCode - 错误码
 * @param {Number} contentType - 请求格式 1:pb;2:json
 * @example
 *  reportRequestDetail('***.***', 1, 300, 0);
 * */
function reportRequestDetail({
  module, status, time, errorCode, protocol = '', contentType = '',
}) {
  const reportParams = {
    pageid: 1610,
    moduleid: 1,
    eventtype: 1,
    extendid: 1,
    ext1: module,
    ext2: status,
    ext3: contentType, // 1:pb;2:json
    ext4: protocol, // ios 暂不支持quic
    staytime: time,
    completionrate: errorCode,
  };
  report(reportParams);
}

/**
 *
 * @param {Object} reqConfig 请求axios config
 * @param {*} errorCode 上报错误码
 * @param {*} status 是否成功。成功:1，失败:0
 * @param {Object} resHeaders 响应头部
 */
function reportRequestDetailByConfig({
  reqConfig, errorCode, status, resHeaders,
}) {
  try {
    const { options, url, headers: reqHeaders } = reqConfig;
    if (url.indexOf('GCReportSrv') > -1) return;
    if (!options.startTime) return;
    const time = +new Date() - options.startTime;
    if (time <= 0 || time > 1000 * 60) return; // 过滤脏数据
    if (time > NETWORK_TIMEOUT_DELAY) {
      // eslint-disable-next-line no-param-reassign
      errorCode = ERR_CODE.ERR_TIMEOUT;
    }
    let contentType = -1; // 防止出现其他类型
    const reqContentType = reqHeaders['Content-Type'] || reqHeaders['content-type'] || '';
    if (reqContentType.indexOf('application/json') > -1) {
      contentType = 2;
    } else if (reqContentType.indexOf('application/pb') > -1) {
      contentType = 1;
    }
    const urlMatched = /\/community\.([^#?\s]+)/.exec(url);
    if (urlMatched && urlMatched[1]) {
      reportRequestDetail({
        module: urlMatched[1],
        status,
        errorCode,
        time,
        protocol: (resHeaders && resHeaders['x-protocol'] && resHeaders['x-protocol'] === 'quic') ? 2 : 1, // 1:http,2:quic
        contentType,
      });
    }
  } catch (e) {
    logger.error('reportRequestDetailByConfig error', e);
  }
}


if (isServerRender) {
  // 服务端返回码上报
  gameplusApi.use('request', serverCgiReport.request);

  // 服务端请求走内网
  gameplusApi.use('request', serverCgiProxy.request);
  gameplusApi.use('request', serverCgiCookie.request);
}


/* use request middleware */

// 请求参数转换成下划线
gameplusApi.use('request', decamelizeRequest);

// 放置commeq请求头
gameplusApi.use('request', putHeaders);

// CSRF Token注入
gameplusApi.use('request', csrfToken);

// RequestId Request注入
gameplusApi.use('request', requestId);

// 耗时打印
gameplusApi.use('request', reqConfig => ({
  ...reqConfig,
  options: {
    ...reqConfig.options,
    startTime: +new Date(),
  },
}));

// 转成 PB
gameplusApi.use('request', encodeReq);

/* use response middleware */

if (isServerRender) {
  gameplusApi.use('response', serverCgiReport.response);
  gameplusApi.use('error', serverCgiReport.error);

  gameplusApi.use('response', serverCgiProxy.response);
  gameplusApi.use('error', serverCgiProxy.error);
}

gameplusApi.use('response', decodeRes);

gameplusApi.use('response', (res) => {
  let errorCode;
  if (!res.data) {
    errorCode = ERR_CODE.ERR_RESPONSE_EMPTY; // 返回体为空
  } else if (res.data.ret === undefined || res.data.ret === null) {
    errorCode = ERR_CODE.ERR_DECODE_ERROR_FRAME; // 返回体格式不正确
  } else {
    errorCode = res.data.ret;
  }
  reportRequestDetailByConfig({
    reqConfig: res.config,
    errorCode,
    status: (res.data && res.data.ret === 0) ? 1 : 0,
    resHeaders: res.headers,
  });
});

gameplusApi.use('error', (err) => {
  if (err.status === 200) {
    // 前面进行上报了
  } else if (err.response) {
    reportRequestDetailByConfig({
      reqConfig: err.config,
      errorCode: err.response.status,
      status: 0,
      resHeaders: err.response.headers,
    });
  } else if (err.request) {
    reportRequestDetailByConfig({
      reqConfig: err.config,
      errorCode: ERR_CODE.ERR_NEWORK,
      status: 0,
    });
  }
});

// 返回数据下划线转换成驼峰
gameplusApi.use('response', camelizeResponse);

// 过滤业务类型错误
gameplusApi.use('response', retFilter);

// 判断是否有认证失败
if (!checkEnvObj.isApp && !isServerRender) {
  gameplusApi.use('error', authenticateFailed);
}

// 统一错误处理
gameplusApi.use('error', universalErrorHandler);

// 错误日志打印
gameplusApi.use('error', errorLogger);

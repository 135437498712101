/**
 * Created by homkerliu on 2017/10/17.
 */

import getUA from './getUA'

export default function getWnsEnv () {
  let ua = getUA() || ''
  return ((ua.match(/env\/(\w+)/) || [])[1] || '').toLowerCase()
}

// @"DEFAULT", @"TEST", @"TEST", @"DEBUG", @"DEBUG", @"PRE", @"RELEASE"


/**
 * 判断是否测试环境
 * @returns {boolean}
 */
export function isWnsTest () {
  return getWnsEnv() === 'test'
}

/**
 * 判断是否预发布环境
 * @returns {boolean}
 */
export function isWnsReady () {
  return getWnsEnv() === 'pre'
}


/**
 * 判断是否开发环境
 * @returns {boolean}
 */
export function isWnsDebug () {
  return getWnsEnv() === 'debug'
}


/**
 * 判断是否正式环境
 * @returns {boolean}
 */
export function isWnsProd () {
  return getWnsEnv() === 'release'
}

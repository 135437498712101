function getPreloadRoutes() {
  return [
    '/community/post-detail',
    '/community/post-video',
    '/community/question-detail',
    '/community/answer-detail',
  ];
}

export function getPreloadRoutesName() {
  return [
    'community-post-detail',
    'community-post-video',
    'community-question-detail',
    'community-answer-detail',
  ];
}

export default getPreloadRoutes;

/**
 * url2Object
 * @author donaldcen
 * @createTime 2017/9/19
 * @example
 * a=1&b=2 => {a:1, b:2}
 */

export default function (search) {
  let paramsList = {}
  let args = (search || '').split('&');
  (args || []).filter(item => !!item).forEach(item => {
    let kv = (item || '').split('=')
    try {
      paramsList[kv[0]] = decodeURIComponent(kv[1])
    } catch (e) {
      paramsList[kv[0]] = kv[1]
    }
  })
  return paramsList
}
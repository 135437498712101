
import jsbridge from "../../libs/jsbridge";

/**
 * 返回
 * 
 * @function imagePreview
 * @memberof ui
 * 
 * @param {Object} params
 * @param {Number} params.index 初始化打开的图片下标
 * @param {Array} params.images 图片集
 * @example
 * ```
   jsbridge.imagePreview([{"url":"https://xxx.png","height":"512","width":"768"},])//height 和width不是必须
   ```
 */
export function imagePreview(params) {
  return jsbridge({url: "ui/image_preview", params: params});
}



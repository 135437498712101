// 圈子相关
const CareerList = () => import(/* webpackChunkName: "Group" */ '../page/group/tool/career-list/index.vue');
const CareerDetail = () => import(/* webpackChunkName: "Group" */ '../page/group/tool/career-detail/index.vue');
const MdnfRoleList = () => import(/* webpackChunkName: "Group" */ '../page/group/tool/role-list/mdnf.vue');
// 福利站
const WelfareSite = () => import(/* webpackChunkName: "Group" */'../page/group/tool/welfare-site/index.vue');

const routes = [
  // 圈子工具相关
  {
    name: 'group-career-list',
    path: '/group/tool/career-list',
    component: CareerList,
    meta: {
      title: '',
    },
  },
  // 福利站
  {
    name: 'group-welfare-site',
    path: '/group/tool/welfare-site',
    component: WelfareSite,
    meta: {
      title: '',
    },
  },
  {
    name: 'group-career-detail',
    path: '/group/tool/career-detail',
    component: CareerDetail,
    meta: {
      title: '职业详情',
    },
  },
  {
    name: 'group-mdnf-role-detail',
    path: '/group/tool/role-list/mdnf',
    component: MdnfRoleList,
    meta: {
      title: '角色卡',
    },
  },
];

export default routes;

/* eslint-disable no-param-reassign */
/**
 * 数据上报接口
 */
import Http from '@/util/http/http';
import { getReportInfo, curdData, reportData } from '@/jsbridge';
import { logger } from '@/util/logger';
import { checkEnv } from '@/util/browser-env';
import getBaseUrl from '@/util/get-base-url';
import { isServerRender } from '@/util/env';
import {
  csrfToken, decamelizeRequest, putHeaders, retFilter, universalErrorHandler, requestId,
  errorLogger,
} from '@/util/http/middleware';
import getPvid from '@/util/pvid';
import compareVersion from '@/util/compareVersion';

const checkEnvObj = checkEnv();

const reportApi = new Http({
  baseURL: getBaseUrl(),
});

reportApi.use('request', decamelizeRequest);
reportApi.use('request', putHeaders);
reportApi.use('request', csrfToken);
reportApi.use('request', requestId);
reportApi.use('response', retFilter);
reportApi.use('error', universalErrorHandler);
reportApi.use('error', errorLogger);

/**
 * 数据上报枚举类型
 */
export const reportEnum = {
  businesstype: {
    H5: '2',
    H5_SHARE: '6',
  },
  pageid: {
    POST_DETAIL: 1502,
    POST_VIDEO: 1503,
    QUESTION_DETAIL: 1504,
    ANSWER_DETAIL: 1505,
    BIND_PHONE: 1605,
    POST_DETAIL_H5: 2002,
    POST_VIDEO_H5: 2003,
    QUESTION_DETAIL_H5: 2004,
    ANSWER_DETAIL_H5: 2005,
    FACE_TO_FACE_H5: 2009,
    ACTIVITY_CENTER: 1107,
    ACTIVITY_CENTER_WELF: 1109,
  },
  contenttype: {
    POST_DETAIL: '1',
    POST_VIDEO: '2',
    QUESTION_DETAIL: '3',
    ANSWER_DETAIL: '4',
  },
};

/**
 * @typedef {Object} reportCommParams 上报接口公共参数
 * @property {String} devid 终端设备号。无法获取的话用序列号等生成一个。uuid
 * @property {String} version APP版本号
 * @property {String} ip 设备ip地址
 * @property {String} sysid 操作系统。android、ios
 * @property {String} brand 手机品牌。若无法获取则不报
 * @property {String} model 设备型号。手机设备型号，如R9S，若无法获取则不报
 * @property {String} sysvername 操作系统版本名。如mui，若无法获取则不报。
 * @property {String} sysvercode 操作系统版本号
 * @property {String} businesstype 业务形态。1=客户端，2=H5，3=PC
 * @property {String} nettype 网络状态。网络类型 1=wifi;  2=2G；3=3G； 4=4G ；5=5G ；6=移动网络 7=以太网  8=其他
 * @property {String} loginuid 登录uid。用户登录后为该用户分配的唯一id
 * @property {String} logintype 登录类型。1=QQ登录，2=微信登录
 * @property {String} openid QQ登录用户上报QQ号，微信登录用户报open_id，未登录用户报设备号imei
 * @property {String} usertype 用户类型。accountType。
 * @property {String} inchannel 渠道包来源。用户的渠道包来源，即adtag
 *
 *
 * @typedef {Object} reportItemParams
 * @property {String} prev_id 上级来源页id
 * @property {String} exp 用户经验
 * @property {String} level 用户等级。1到20
 * @property {String} [strategy_id] 平台策略id。1=人工置顶，2=兜底逻辑，3=平台规则（如按照热度排序、按照时间排序）若平台有自己的规则，由平台自定义id编号
 * @property {String} [recall] 物料找回方式
 * @property {String} [recommend_source] 算法来源。1=游戏社区
 * @property {String} [recommend_id] 算法id。算法团队提供
 * @property {String} [related_item_id] 相关内容id。相关推荐场景，如内容详情页中推荐其他内容，相关推荐内容的曝光、点击均需要带上这个id
 * @property {String} [trace_id] 追踪id。每一个内容item曝光时，获得唯一的追踪id，当该item被曝光点击，
 * 后续的操作都会带上这个追踪id，如点击、评论、点赞、下载游戏（直到返回上一级）；目的是查看完整的关于某个item的全部操作数据
 * @property {String} [labelid] 标签id
 * @property {Number} businessid 业务id。operid的前2位是业务id，10=首页tab，11=关注tab，12=发现tab，13=我的tab，14=内容详情页，15=框架
 * @property {Number} pageid 页面id。operid的前4位是页面id
 * @property {Number} moduleid 模块id。operid第5~6位是模块id
 * @property {Number} indexid 位置id。如feeds中的第几个、tab中的第几个，如果没有位置，默认报1
 * @property {String} contenttype 内容类型。1=图文帖子，2=视频帖子，3=问题，4=回答
 * @property {String} contentstyle 内容样式。（后续拓展使用）如左图右文、三图、大图等，本期暂时没有这个
 * @property {String} contentname 内容名称。如文章的标题
 * @property {String} contentid 内容唯一标识。企鹅号文章上报内容rowkey，帖子上报帖子唯一id，问题上报问题唯一id，回答上报回答唯一id
 * @property {String} gameplatform 游戏平台。1=手游，2=PC端游，3=主机游戏，4=H5游戏，5=其他游戏
 * @property {String} gametype 游戏类型
 * @property {String} gamename 游戏名称
 * @property {String} appid 游戏appid
 * @property {String} authortype 内容作者类型
 * @property {String} authorname 内容作者名称
 * @property {String} authorid 内容作者id
 * @property {String} resourcetype 资源位类型。1=banner，2=文章，2=视频，3=问答
 * @property {String} resourcename 资源位名称。
 * @property {String} resourceid 资源位id。管理端配置资源位时生成id
 * @property {String} eventtype 操作事件类型id
 * @property {String} operid 操作id。
 * @property {Number} staytime 页面访问时长。单位秒
 * @property {Number} completionrate 页面完成率。视频：播放时长/总时长，文章帖子及问答：浏览百分比
 * @property {String} ext1 备用字段
 * @property {String} ext2 备用字段
 * @property {String} ext3 备用字段
 * @property {String} ext4 备用字段
 * @property {String} ext5 备用字段
 * @property {String} ext6 备用字段
 * @property {String} ext7 内容产生源上报，1=PC管理端发布，2=APP发布，3=企鹅号文章
 * @property {String} ext8 备用字段
 * @property {String} ext9 备用字段
 * @property {String} ext10 备用字段。后端报。新增激活专用上报（1：新用户，0：老用户）
 * @property {Number} extendid 保留id，非后端需求，用于前端拼接operid
 * @property {String} sparklevel 声望等级
 * @property {Number} sparkscore 声望值
 * @property {Object} recommend_extends 算法扩展字段
 *
 * @typedef {Object} reportOptions 配置选项
 * @property {String} description 描述上报信息。用于打印调试
 * @property {Boolean} [immediate=false] 是否立即上报。默认缓存上报
 */

// const common = {
//   devid: '49433c92-fe4e-43e4-9fca-604e1c7887ab', // uuid
//   version: '1.0.1',
//   ip: '10.64.214.49',
//   sysid: 'android',
//   brand: 'HUAWEI',
//   model: 'meta30',
//   sysvername: 'android',
//   sysvercode: '10',
//   loginuid: '1000035',
//   openid: 'ECA2A0028BB91FAB936AE8C4935BAFAF',
//   usertype: '1',
//   inchannel: '',
//   businesstype: '',
// };

function fixNumber(x, len) {
  let str;
  if (typeof x === 'number') {
    str = String(x);
  } else if (typeof x === 'string') {
    str = x;
  } else {
    return '';
  }
  while (str.length < len) {
    str = `0${str}`;
  }
  return str;
}

let commonData; // 从终端获取公共参数
export function getCommonData() {
  if (checkEnvObj.isApp) {
    return getReportInfo().then((res) => {
      if (res.result === 0) {
        return {
          ...res.data,
          pvid: getPvid(),
          businesstype: reportEnum.businesstype.H5,
        };
      }
      return {};
    }, () => Promise.resolve({}));
  }
  let sysid = '';
  if (checkEnvObj.ios) sysid = 'iOS';
  else if (checkEnvObj.android) sysid = 'Android';
  return Promise.resolve({
    businesstype: reportEnum.businesstype.H5_SHARE,
    sysid,
    pvid: getPvid(),
  });
}

let traceId = null; // 从终端获取搜索trace_id
function getTraceId() {
  if (!checkEnvObj.isApp) return Promise.resolve('');
  const params = { key: 'search_trace_id', user_related: 0, action: 1 };
  return curdData(params).then((res) => {
    const { data } = res;
    return data || '';
  })
    .catch((err) => {
      logger.error('call curdData error: ', err);
      return '';
    });
}

/**
 * 更新上报用的 trace_id
 * @param {String} data
 */
export function updateTraceId(data) {
  if (typeof data !== 'string') {
    throw Error('updateTraceId error: traceId must be string');
  }
  traceId = data;
}

// 从 userStore 里面调用更新userInfo
let userInfo = null;
export function updateUserInfo(data) {
  userInfo = data;
}

/**
 * 直接数据上报
 * @param {reportItemParams[]} params 上报业务参数。都是String类型
 */
export function postReport(reportList) {
  return reportApi.request({
    url: '/community.GCReportSrv/GCReport',
    data: {
      comm: commonData,
      item: reportList,
    },
  });
}

// 去除异常的停留时长上报
// 停留时长之前存在上报毫秒的问题，改成上报秒以后，本地仍然可能存在脏数据，这里做一次清理
// 大于 1000 的做个记录
// 大于 3600 的肯定是报毫秒的数据，丢弃不用
function clearStayTimeErrorData(params) {
  if (params.operid === '1501000010601') {
    if (params.staytime > 3600) {
      logger.warn('clearStayTimeErrorData 3600', params);
      return false;
    }
    if (params.staytime > 1000) {
      logger.warn('clearStayTimeErrorData 1000', params);
    }
  }
  return true;
}

/**
 * 缓存上报信息
 * 万一接口没成功，可以在localstorage里面重新上报
 */
class ReportDataStore {
  constructor() {
    this.storeKey = 'REPORT_DATA_STORE';
    // this.data = []; // 统一存localstorage
    // try {
    //   const data = localStorage.getItem(this.storeKey);
    //   logger.debug('H5ReportData from local', data);
    //   if (data) this.data = JSON.parse(data);
    // } catch {
    //   //
    // }
  }

  add(item) {
    try {
      let data = localStorage.getItem(this.storeKey);
      if (data) {
        data = JSON.parse(data);
      } else {
        data = [];
      }
      data.push(item);
      localStorage.setItem(this.storeKey, JSON.stringify(data));
    } catch (e) {
      logger.error('ReportDataStore add error', e);
    }
  }

  clear() {
    try {
      localStorage.removeItem(this.storeKey);
    } catch (e) {
      logger.error('ReportDataStore clear error', e);
      //
    }
  }

  getData() {
    // 取出并清空储存
    try {
      const data = localStorage.getItem(this.storeKey);
      if (data) {
        localStorage.removeItem(this.storeKey);
        const list = JSON.parse(data);
        list.filter(item => clearStayTimeErrorData(item)); // 清理本地脏数据
        return list;
      }
      return [];
    } catch (e) {
      logger.error('ReportDataStore getData error', e);
      return [];
    }
  }

  addData(list) {
    if (list instanceof Array) {
      try {
        let data = localStorage.getItem(this.storeKey);
        if (data) {
          data = JSON.parse(data);
        } else {
          data = [];
        }
        data.concat(list);
        localStorage.setItem(this.storeKey, JSON.stringify(data));
      } catch (e) {
        //
      }
    }
  }
}

const reportDataStore = new ReportDataStore();
let lastReportTime = (new Date()).getTime();
const REPORT_DELAY = 3000; // 10s 一次 先改成0测试
const REPORT_MAX_LENGTH = 20; // 20
let delayTimer = null;
let immediateTimer = null;

/**
 * 触发上报
 */
export function commitReport() {
  clearTimeout(delayTimer);
  clearTimeout(immediateTimer);
  lastReportTime = (new Date()).getTime();
  function reportNow(data) {
    if (data && data.length > 0) {
      postReport(data).then(() => {
        // 上报成功
      })
        .catch(() => {
        // 上报失败了，数据重新加到上报信息里面，等待下一次上报
        // reportDataStore.addData(data);
        });
    }
  }
  const totalData = reportDataStore.getData();
  // 分段上报
  let i = 0;
  while (i < totalData.length) {
    const data = totalData.slice(i, i + REPORT_MAX_LENGTH);
    i += REPORT_MAX_LENGTH;
    reportNow(data);
  }
}

/**
 * 缓存数据上报
 * @param {reportItemParams} params 上报业务参数
 * @param {String|reportOptions} options
 */
export default function report(params, options = {}) {
  if (isServerRender) {
    return;
  }
  const reportOptions = typeof options === 'string' ? {
    description: options,
  } : options;
  if (params) {
    if (userInfo) {
      params.exp = String(userInfo.exp);
      params.level = String(userInfo.level);
      if (userInfo.sparkLevel) params.sparklevel = userInfo.sparkLevel;
      if (userInfo.sparkScore) params.sparkscore = userInfo.sparkScore;
    }
    const { operid } = params;
    if (!operid) {
      const requiredParamKeys = ['pageid', 'moduleid', 'eventtype', 'extendid'];
      requiredParamKeys.forEach((key) => {
        if (!(key in params)) {
          throw Error(`缺少上报字段: ${key}`);
        }
      });
      // 自动生成operid，方便子组件上报
      params.operid = fixNumber(params.pageid, 4)
        + fixNumber(params.moduleid, 5)
        + fixNumber(params.eventtype, 2)
        + fixNumber(params.extendid, 2);
    } else {
      if (operid.length !== 13) {
        throw Error('operid不符合格式要求');
      }
      // 自动拆分operid字段，方便子组件上报
      params.pageid = operid.substr(0, 4);
      params.moduleid = Number(operid.substr(4, 5));
      params.eventtype = Number(operid.substr(9, 2));
      params.extendid = Number(operid.substr(11, 2));
    }
    if (!params.businessid) {
      // businessid 取 pageid前两位
      params.businessid = Number(fixNumber(params.pageid, 4).slice(0, 2));
    }
    const numberParamKeys = ['pageid', 'moduleid', 'indexid', 'businessid', 'staytime', 'completionrate', 'sparkscore'];
    const objectParamKeys = ['recommend_extends'];
    Object.keys(params).forEach((key) => {
      if (params[key] === undefined || params[key] === null) {
        if (process.env.NODE_ENV === 'development') {
          throw Error(`上报数据参数错误：reportItemParams key ${key} is ${params[key]}`);
        } else {
          logger.error(`上报数据参数错误：reportItemParams key ${key} is ${params[key]}`);
          delete params[key];
        }
      } else if (objectParamKeys.indexOf(key) > -1) {
        // do nothing
      } else if (numberParamKeys.indexOf(key) > -1) {
        params[key] = Number(params[key]);
      } else {
        params[key] = String(params[key]);
      }
    });
  }
  if (!commonData) {
    getCommonData().then((data) => {
      commonData = data;
      report(params, reportOptions);
    });
    return;
  }

  if (traceId === null) {
    getTraceId().then((data) => {
      traceId = data;
    })
      .catch(() => {
        traceId = '';
      })
      .finally(() => {
        report(params, reportOptions);
      });
    return;
  }

  if (params) {
    // eslint-disable-next-line no-param-reassign
    params = {
      trace_id: traceId,
      ...params,
    };
    // 1.3.1后开始走终端上报
    if (checkEnvObj.isApp && ((compareVersion('1.3.2') >= 0 && compareVersion('1.3.3') < 0) || compareVersion('1.3.4') >= 0)) {
      params.businesstype = reportEnum.businesstype.H5;
      logger.info('调用终端上报', 'H5ReportData native', JSON.stringify(params));
      reportData(params);
      return;
    }
    logger.info('H5ReportData', `${reportOptions.description}: ${params.operid}`);
    logger.debug('H5ReportData params', JSON.stringify(params));
    reportDataStore.add(params);
  }
  const now = (new Date()).getTime();
  if (reportOptions.saveToLocal) {
    logger.info('[H5ReportData] save to local');
  } else if (reportOptions.immediate) {
    commitReport();
  } else if ( // 超过时间或者数量时才上传
    (lastReportTime && (now - lastReportTime > REPORT_DELAY))
      || (reportDataStore.length > REPORT_MAX_LENGTH)
  ) {
    immediateTimer = setTimeout(() => {
      commitReport();
    }, 0);
  } else {
    delayTimer = setTimeout(() => {
      report(null, reportOptions);
    }, REPORT_DELAY);
  }
}

/**
 * @param {reportItemParams} baseParams
 * @returns {function(reportItemParams)}
 */
export function createReport(baseParams) {
  return function fn(params) {
    return report({
      ...baseParams,
      params,
    });
  };
}

import commentApi from '@/api/comment';

export default {
  namespaced: true,
  state: {
    accuseReasonList: [],
  },
  mutations: {
    setAccuseReasonList(state, accuseReasonList) {
      state.accuseReasonList = accuseReasonList;
    },
    resetState(state) {
      state.accuseReasonList = [];
    },
  },
  actions: {
    getCommentAccusedReason({ state, commit }) {
      if (state.accuseReasonList.length > 0) {
        return Promise.resolve();
      }
      return commentApi.getCommentAccusedReason()
        .then((res) => {
          if (res.reason && res.reason.length > 0) {
            commit('setAccuseReasonList', res.reason);
            return Promise.resolve(res);
          }
          return Promise.reject(res);
        });
    },
  },
};

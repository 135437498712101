/**
 * Created by xiaolinchi on 2017/11/20.
 */

import getUA from './getUA'

/**
 * 判断是否竖屏
 * 0:未知
 * 1:竖屏
 * 2:横屏
 */
export default function getScreenOrientationByUA () {
  let ua = getUA()
  return Number((ua.match(/orientation\/([\d]){1}/) || [])[1])
}
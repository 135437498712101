/**
 * jsAPI web 接入
 * @author donaldcen
 * @createTime 2017/9/18
 */
import {isAndroid, isIOS, compareVersion} from '../os'
import Observer from '../../utils/observer'
import {createSN, fireFN, setFN} from './fnStore'
import createJSAPI from './createJSAPI'
import {log} from '../logger'
import {isObject} from '../typeChecker'

function createIframe (url, failCallback) {
  let iframe = document.createElement('iframe')

  iframe.style.cssText = 'display:none;width:0px;height:0px;'


  if (isIOS()) {

    /*
         ios 必须先赋值, 然后 append, 否者连续的 api调用会间隔着失败
         也就是 api1(); api2(); api3(); api4(); 的连续调用,
         只有 api1 和 api3 会真正调用到客户端
         */
    iframe.onload = failCallback
    iframe.src = url;

    //
    (document.body || document.documentElement).appendChild(iframe)
  }

  /*
     android 这里必须先添加到页面, 然后再绑定 onload 和设置 src
     1. 先设置 src 再 append 到页面, 会导致在接口回调(callback)中嵌套调用 api会失败,
     iframe会直接当成普通url来解析
     2. 先设置onload 在 append , 会导致 iframe 先触发一次 about:blank 的 onload 事件

     */
  else if (isAndroid()) {
    // android 必须先append 然后赋值
    (document.body || document.documentElement).appendChild(iframe)

    iframe.onload = failCallback
    iframe.src = url
  } else {
    // 这里加默认，主要为了测试
    iframe.onload = failCallback
    // iframe.src = url
    iframe.setAttribute('src', url)

    (document.body || document.documentElement).appendChild(iframe)
  }
  // android 捕获了iframe的url之后, 也是中断 js 进程的, 所以这里可以用个 setTimeout 0 来删除 iframe
  // setTimeout(function () {
  //     iframe && iframe.parentNode && iframe.parentNode.removeChild(iframe);
  // }, 0);
}

function run (url, resolve, reject) {
  console.log(`jsbridge url: ${url}`)
  // 失败回调
  function failCallback () {
    /*
         正常情况下是不会回调到这里的, 只有客户端没有捕获这个 url 请求,
         浏览器才会发起 iframe 的加载, 但这个 url 实际上是不存在的,
         会触发 404 页面的 onload 事件
         */
    // execGlobalCallback(sn, {
    //     r: -201,
    //     result: 'error'
    // });
    reject()
  }

  if (isAndroid() && compareVersion('2.0.0.116') > 0) {
    let _img = new Image()
    // _img.onerror = failCallback
    _img.src = url
  } else {
    createIframe(url, failCallback)
  }

}

// (window.mqq && mqq.version > 20140616001 && mqq.execGlobalCallback || function(cb) {window[cb] && window[cb].apply(window, [].slice.call(arguments, 1));}).apply(window, [((0)), ((1))]);

function createSNCallback (SN) {
  window[SN] = function (...s) {
    fireFN(SN, s)
  }
}


/**
 * 调用终端接口返回Promise
 * @param {Object|String} data 字符串则当做schema处理，对象自动转换
 * @param {String} data.url 终端接口路径
 * @param {Object} data.params 终端接口参数
 * @param {object} options
 * @options {string} options.dataType // 'strict':开启严格模式，终端对调必须有result
 * @options {string} options.callbackType // 'observer':开启回调不回收模式，终端可以触发多次回调
 * @options {Boolean} options.customCallback // true: 开启自定义callback深度遍历
 * @returns {Promise}
 * @returns {Promise}
 */
export default function (data, options = {}) {
  let isStrict = options.dataType === 'strict'

  // 创建回调id
  let SN = createSN()

  // 塞入参数
  if (!data.params) data.params = {}

  if (options.customCallback) {
    //深度遍历参数进行替换
    data.params = deepCallback(data.params)
    //如果用户没有传入callback,自动创建一个
    if (!data.params.callback) {
      data.params.callback = SN
    }
  } else {
    data.params.callback = SN
  }

  // 生成schema
  let schema = createJSAPI(data)

  log('请求终端接口：', schema)

  // 如果参数里有function需要当做回调处理


  let _callback = options.callbackType !== 'observer' ? Promise : Observer
  return new _callback(function (resolve, reject) {
    // 绑定回调
    setFN(SN, (...s) => {
      log(schema + 'callback,result is ' + JSON.stringify(s))
      if (isStrict) {
        let result = s[0] && s[0].result
        if (result === 0) {
          resolve.apply(this, s)
        } else {
          reject.apply(this, s)
        }
      } else {
        resolve.apply(this, s)
      }
    })

    // 创建全局回调接口函数
    createSNCallback(SN)

    // 发送JSAPI请求
    run(schema, resolve, reject)
  })

}

/**
 * 深度遍历参数，如果含有function就进行替换
 */
function deepCallback (params) {
  if (typeof params === 'function') {
    // 创建回调id
    let SN = createSN()
    setFN(SN, params)
    // 创建全局回调接口函数
    createSNCallback(SN)
    return SN
  }

  // Object进行深度遍历
  if (isObject(params)) {
    Object.keys(params).forEach((key) => {
      params[key] = deepCallback(params[key])
    })
  }

  return params
}

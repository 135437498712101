/* eslint-disable no-param-reassign */
import { reportData } from '../../../../utils/index';

/**
 * 获取包裹当前组件的滚动容器的DOM元素
 * @param {*} element element 组件的DOM元素
 */
function getScrollEventTarget(element) {
  let target = element;
  while (target) {
    // eslint-disable-next-line no-underscore-dangle
    const isNativeScrollElement = ['ul'].includes(target._tagName);
    const targetStyle = target.style || {};
    const hasXAxisScrollStyle = targetStyle['overflow-x'] === 'scroll';
    const hasYAxisScrollStyle = targetStyle['overflow-y'] === 'scroll';
    if (isNativeScrollElement || hasXAxisScrollStyle || hasYAxisScrollStyle) {
      return target;
    }

    target = target.parentNode;
  }

  return null;
}

/**
 * 获取当前页面的高度
 * @param {*} Vue
 */
function getClientHeight(Vue) {
  const isNative = !!Vue.Native && Vue.Native.Platform !== 'browser';
  const dimensions = Vue.Native.Dimensions;
  let height = 0;
  if (isNative) {
    height = dimensions.screen.height || dimensions.window.height;
  } else {
    height = document.documentElement.clientHeight || document.body.clientHeight;
  }

  return height;
}

/**
 * 获取当前页面的宽度
 * @param {*} Vue
 */
function getClientWidth(Vue) {
  const isNative = !!Vue.Native && Vue.Native.Platform !== 'browser';
  const dimensions = Vue.Native.Dimensions;
  let width = 0;
  if (isNative) {
    width = dimensions.screen.width || dimensions.window.width;
  } else {
    width = document.documentElement.clientWidth || document.body.clientWidth;
  }

  return width;
}

/**
 * Hippy页面，获取当前组件是否在可视化曝光的区域内
 * - 曝光需要露出组件的1 / 2
 * @param {*} Vue
 * @param {*} element 组件的DOM元素
 */
async function getIsInView(Vue, element) {
  if (!element) {
    return false;
  }
  const {
    width, height, top, left,
  } = await element.getBoundingClientRect();
  // hippy列表元素不在可视区域内时，BCR全部返回-1
  if (width === -1 && height === -1) {
    return false;
  }

  const clientWidth = getClientWidth(Vue);
  const clientHeight = getClientHeight(Vue);
  const halfWidth = width / 2;
  const halfHeight = height / 2;
  // 曝光需要露出组件的1 / 2
  let isInVerticalView = (top >= 0) && (clientHeight - top >= halfHeight);
  isInVerticalView = isInVerticalView || ((top < 0) && (top + height >= halfHeight));
  let isInHorizontalView = (left >= 0) && (clientWidth - left >= halfWidth);
  isInHorizontalView = isInHorizontalView || ((left < 0) && (left + width > width / 2));
  return isInVerticalView && isInHorizontalView;
}

/**
 * Hippy页面，曝光数据上报指令
 * @param {*} Vue
 */
function exposureReportInHippy(Vue) {
  return {
    inserted: (el, binding) => {
      if (!binding) {
        return;
      }

      const handleExposure = async () => {
        const isInView = await getIsInView(Vue, el);
        if (isInView) {
          reportData(Vue, binding.value);
          return;
        }

        const target = getScrollEventTarget(el);
        // eslint-disable-next-line prefer-const
        let handleScroll;
        const onScroll = async () => {
          const isScrollInView = await getIsInView(Vue, el);
          if (isScrollInView) {
            reportData(Vue, binding.value);
            target.removeEventListener('scroll', handleScroll, true);
          }
        };
        // 这里再包裹一层，因为安卓端addEventListener的callback只能是Function类型，不能是asyncFunction，否则会报错。
        handleScroll = () => {
          onScroll();
        };
        target.addEventListener('scroll', handleScroll, true);
        el.customOnScroll = handleScroll;
        el.customScrollTarget = target;
      };
      // hippy渲染组件有延迟，因此这里加个定时器
      setTimeout(() => {
        handleExposure();
      }, 1000);
    },
    unbind: (el) => {
      if (!el.customScrollTarget) {
        return;
      }

      el.customScrollTarget.removeEventListener('scroll', el.customOnScroll, true);
      el.customOnScroll = null;
      el.customScrollTarget = null;
    },
  };
}

export { exposureReportInHippy };

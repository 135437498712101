
import jsbridge from "../../libs/jsbridge";

/**
 * 返回
 * 
 * @function setTitleBar
 * @memberof ui
 * 
 * @param {Object} params
 * @param {String} params.bgColor 导航栏和状态栏背景色。rgb格式#112233
 * @param {String} params.textColor 导航栏文字颜色。rgb格式#112233
 * @example
 * ```
   jsbridge.ui.setTitleBar({
     bgColor: '#876567',
     textColor: '#876567',
   })
   ```
 */
export function setTitleBar (params) {
  return jsbridge({url: "ui/setTitleBar", params: params});
}



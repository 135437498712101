'use strict';
/**
 * @fileoverview 数据相关的操作
 * @namespace data
 */
import jsbridge from '../../libs/jsbridge';

/**
 * 获取设备信息
 * @deprecated
 * @function getDeviceInfo
 * @memberof data
 *
 * @warning 完成状态
 *
 * @example
 * getDeviceInfo().then((res) => {
 *   console.log(res);
 * });
 */
export function getDeviceInfo () {
  return jsbridge({
    url: 'device/getDeviceInfo'
  }, {
    callbackType: 'observer',
    customCallback: true
  })
}

/**
 * 获取网络信息
 * @deprecated
 * @function getNetWorkInfo
 * @memberof data
 *
 * @example
 * getNetWorkInfo().then((res) => {
 *   console.log(res);
 * });
 */
export function getNetWorkInfo () {
  return jsbridge({
    url: "device/getNetworkStatus"
  });
}

/**
 * 获取上报接口公共参数
 * @function getReportInfo
 * @memberof data
 */
export function getReportInfo () {
  return jsbridge({
    url: 'data/getReportInfo'
  });
}

/**
 * app内通用存储接口
 *
 * @function curdData
 * @memberof data
 *
 * @param {Object} params
 * @param {String} params.key 键名
 * @param {String} [params.value] 键值
 * @param {Number} params.action 1 查询 2 删除 3 存储
 * @param {Number} params.user_related 0 用户相关 1 用户不相关
 * @example
 * curdData({"key":"testKey","value": "testValue", "action": 3, "user_related": 0"})
 * @return {Object} Promise
 */
export function curdData (params) {
  return jsbridge({
    url: 'data/curdData',
    params: params
  });
}

/**
 * 通过终端获取请求签名
 *
 * @function getReqQueryString
 * @memberof data
 *
 * @param {Object} params
 * @param {String} params.path 路径
 * @param {Object} params.body 请求体
 * @param {commReq} params.commReq 通用参数
 * @example
 * getReqQueryString({"path":"/community.GCFeedDetailSrv/GetHomePageFeedsDetail","body": {}, "action": 3, "callback": function(){}})
 * @return {Object} Promise
 */
export function getReqQueryString (params) {
  return jsbridge({
    url: 'data/getReqQueryString',
    params: params
  });
}

/**
 * @typedef {Object} getWebviewTraceInfoCallbackParams
 * @description getWebviewTraceInfo回调参数
 * @memberof data
 * @property {Number} result 状态，0=成功，其他为失败
 * @property {Object} data
 * @property {Number} data.openType webview打开方式, 0=普通webview,1=webbundle
 * @property {Number} data.webviewStart 最接近点击的时间的时机戳；非webbundle可以是webviewBeforeCreate，webbundle则是点击调用webbundle时间
 * @property {Number} data.webviewBeforeCreate 开始创建webview的时机戳。
 * @property {Number} data.webviewBeforeLoadURL webview开始加载URL的时机戳
 * @property {Number} data.webviewLoadFinish 页面加载完成（包括网络耗时和解析页面）的时机戳
 * 
 */

/**
 * 获取终端webview打开速度等参数
 * 
 * @function getWebviewTraceInfo
 * @memberof data
 * 
 * @returns {Promise<getWebviewTraceInfoCallbackParams>}
 * 
 * @example
 * ```
 * jsbridge.data.getWebviewTraceInfo()
 *   .then((res) => {
 *       if (res.result === 0) {
 *           console.log(res.data);
 *       }
 *    });
 * {
    result: 0,
    data: {
      webviewStart: 1597889235619,
      webviewBeforeCreate: 1597889235619,
      webviewBeforeLoadURL: 1597889235619,
      webviewLoadFinish: 1597889235619
      }
    }
 * ```
 * 
 */
export function getWebviewTraceInfo () {
  return jsbridge({
    url: 'data/getWebviewTraceInfo',
  });
}

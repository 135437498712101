<template>
  <transition name="ui-toast-fade">
    <div
      v-show="visible"
      class="ui-toast"
    >
      <div class="ui-toast-inner">
        <div class="ui-toast-text">
          <slot>{{ message }}</slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Toast',
  props: {
    message: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      visible: false,
    };
  },
};
</script>

<style lang="scss" src="./scss/index.scss"></style>

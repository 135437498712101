/**
 * getNetType
 * @author marvinqi
 * @createTime 2017/9/20
 */
/**
 *@desc 网络类型 wifi 4g 3g 2g unknown,un,notreachable ，没有NetType返回空字符串
 *@returns {string}
 */
import getUA from './getUA'

export default function () {
  return String((new RegExp('nettype\\/(\\w*)').exec(getUA()) || ['', ''])[1]).toLowerCase()
}
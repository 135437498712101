/* eslint-disable no-console */
/**
 * 统一客户端日志打印插件,
 * @example
 *  logger.info('字符串消息');
 *  logger.info('系统消息', '字符串');
 *  logger.info('系统消息', {a: 1, b: 2});
 *  logger.info('系统消息', '多条消息', {a: 1, b: 2}, {c: 3, d: 4});
 */
import { checkEnv } from '@/util/browser-env';
import { serverWindow, isServerRender } from '@/util/env';
import aegis from '@/util/aegis';

const window = serverWindow;

const env = checkEnv();

// 用来调用客户端log函数的对象
let nativeLogger = {
  debug() {
    return false;
  },
  info() {
    return false;
  },
  warn() {
    return false;
  },
  error() {
    return false;
  },
};

// aegis日志上报logger
const aegisLogger = {
  debug: (...msg) => {
    aegis.info(`[debug] ${JSON.stringify(msg)}`);
  },
  info: (...msg) => {
    // 离线日志
    aegis.info(`[info] ${JSON.stringify(msg)}`);
  },
  warn: (...msg) => {
    // 全量上报
    aegis.infoAll(`[warn] ${JSON.stringify(msg)}`);
  },
  error: (...msg) => {
    // 全量上报
    aegis.infoAll(`[error] ${JSON.stringify(msg)}`);
    // 通过console.error上报错误
    // aegis.report用于触发告警的错误
    console.error(`[error] ${JSON.stringify(msg)}`);
  },
};


function invokeNativeLoggerMethod(level, msg) {
  try {
    if (Array.isArray(msg)) {
      if (msg.length > 1) { // 数组
        window.jsbridge.log(level, `[H5LOG]${JSON.stringify(msg[0])}`, JSON.stringify(msg.slice(1)));
      } else {
        window.jsbridge.log(level, '[H5LOG]', JSON.stringify(msg));
      }
    } else { // 非数组
      window.jsbridge.log(level, '[H5LOG]', msg);
    }
  } catch (e) {
    console.error('callNativeLogError', e);
  }
}

function formatNativeLoggerMsg(msg) {
  if (Array.isArray(msg)) {
    if (msg.length > 1) { // 数组
      return `[H5LOG]${JSON.stringify(msg[0])} ${JSON.stringify(msg.slice(1))}`;
    }
    return `[H5LOG] ${JSON.stringify(msg[0])}}`;
  } // 非数组
  return `[H5LOG] ${JSON.stringify(msg)}}`;
}

if (env.isApp) {
  nativeLogger = {
    debug: (...msg) => {
      if (env.ios) {
        invokeNativeLoggerMethod(0, msg);
        console.debug(formatNativeLoggerMsg(msg));
      } else {
        console.debug(formatNativeLoggerMsg(msg));
      }
    },
    info: (...msg) => {
      if (env.ios) {
        invokeNativeLoggerMethod(1, msg);
        console.info(formatNativeLoggerMsg(msg));
      } else {
        console.info(formatNativeLoggerMsg(msg));
      }
    },
    warn: (...msg) => {
      if (env.ios) {
        invokeNativeLoggerMethod(2, msg);
        console.warn(formatNativeLoggerMsg(msg));
      } else {
        console.warn(formatNativeLoggerMsg(msg));
      }
    },
    error: (...msg) => {
      if (env.ios) {
        invokeNativeLoggerMethod(3, msg);
        console.error(formatNativeLoggerMsg(msg));
      } else {
        console.error(formatNativeLoggerMsg(msg));
      }
    },
  };
}

const tgcConsole = {
  ...window.console,
  debug(...msg) {
    if (isServerRender) {
      console.log(formatNativeLoggerMsg(msg));
    } else if (env.isApp) {
      nativeLogger.debug(...msg);
    } else {
      // console.log(...msg);
    }
    aegisLogger.debug(...msg);
  },
  info(...msg) {
    if (isServerRender) {
      console.log(formatNativeLoggerMsg(msg));
    } else if (env.isApp) {
      nativeLogger.info(...msg);
    } else {
      // console.info(...msg);
    }
    aegisLogger.info(...msg);
  },
  warn(...msg) {
    if (isServerRender) {
      console.warn(formatNativeLoggerMsg(msg));
    } else if (env.isApp) {
      nativeLogger.warn(...msg);
    } else {
      // console.warn(...msg);
    }
    aegisLogger.warn(...msg);
  },
  error(...msg) {
    if (isServerRender) {
      console.error(formatNativeLoggerMsg(msg));
    } else if (env.isApp) {
      nativeLogger.error(...msg);
    } else {
      // console.error(...msg);
    }
    aegisLogger.error(...msg);
  },

};

// 可兼容一些web方法，折叠方法主要用于调试
export const logger = {
  ...tgcConsole,
  table: tgcConsole.table || tgcConsole.log || null,
};

export default logger;

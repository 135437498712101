<template>
  <div v-bem>
    <template v-if="info">
      <div
        v-show="isShow"
        v-bem:shadow
        @touchmove.stop="preventDefault"
      />
      <div
        v-show="isShow"
        v-bem:content
      >
        <div
          v-bem:title
          @touchmove.stop="preventDefault"
        >
          {{ info.title || '请选择' }}
        </div>
        <div
          v-if="info.desc && info.desc.length"
          v-bem:desc
        >
          <span
            v-for="(desc, index) in info.desc"
            :key="index"
          >{{ desc }}</span>
        </div>
        <template v-if="info && info.item">
          <div
            v-for="(item, index) in info.item"
            :key="index"
            v-bem:item
            @click="clickItem(index)"
          >
            <img
              v-if="item.icon"
              v-bem:icon
              :src="item.icon"
            >
            <span>{{ item && item.name }}</span>
          </div>
        </template>
        <div v-bem:grey-divider />
        <div
          v-bem:cancel
          @click="clickCancel"
          @touchmove.stop="preventDefault"
        >
          取消
        </div>
      </div>
    </template>
  </div>
</template>
<script>

import { SHOW_ACTION_EVENT } from './index';
import { onEvent } from '@/util/vueComponetEvent';

export default {
  name: 'ComActionSheet',
  data() {
    return {
      isShow: false,
      info: null,
      list: [],
    };
  },
  created() {
    this.bindEvent();
  },
  methods: {
    bindEvent() {
      onEvent(SHOW_ACTION_EVENT, (info = {}) => {
        const mInfo = { ...info };
        if (typeof mInfo.desc === 'string') {
          mInfo.desc = [mInfo.desc];
        }
        if (!this.info) {
          this.info = mInfo;
          this.isShow = true;
        } else {
          this.list.push(mInfo);
        }
      });
    },
    preventDefault(event) {
      event.preventDefault();
      return false;
    },
    hideDialog() {
      this.isShow = false;
      this.info = null;
      this.showNext();
    },
    clickItem(index) {
      if (typeof (this.info.callback) === 'function') {
        this.info.callback({
          ret: 0,
          index,
        });
      }
      this.hideDialog();
    },
    clickCancel() {
      if (typeof (this.info.callback) === 'function') {
        this.info.callback({
          ret: 1, // 取消
        });
      }
      this.hideDialog();
    },
    showNext() {
      if (this.list.length > 0) {
        this.info = this.list.shift();
        this.isShow = true;
      }
    },
  },
};
</script>
<style lang="scss" src="./scss/index.scss"></style>

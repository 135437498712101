import { PLATFORM } from './constants/index';
import { report } from './directive/report/index';

/** 数据埋点上报指令插件 */
class ReportDataPlugin {
  static install(Vue, options) {
    Vue.directive('report', report(Vue, options));
  }
}

export { ReportDataPlugin, PLATFORM };

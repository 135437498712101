import util from '@/util/util';
import missionApi from '@/api/mission';
import { showTaskToast } from '@/jsbridge';
import report from '@/api/report';
import { logger } from '@/util/logger';

export default {
  namespaced: true,
  state: {
    viewPost: { // 浏览帖子
      list: [], // 数量
      status: false, // 是否完成
    },
  },
  mutations: {
    /**
     * 设置任务完成参数
     * @param state 当前的状态数据
     * @param task 任务信息
     * @param task.name 任务名
     * @param task.value 任务数据
     * */
    setTask(state, task) {
      state[task.name] = task.value;
    },
    resetState(state) {
      state.viewPost = { // 浏览帖子
        list: [], // 数量
        status: false, // 是否完成
      };
    },
  },
  actions: {
    setTask({ state, commit }, data) {
      const userId = util.cookie.get('uid') || 0;
      if (data.name === 'viewPost' && Number(userId)) {
        const TASKNUM = 10; // 帖子浏览次数
        const DATEFLAG = `d_${Math.floor(+new Date() / 86400000)}`;

        const storeKey = `viewPost_${userId}`;
        const orignStoreValue = JSON.parse(localStorage.getItem(storeKey));
        let storeValue = (orignStoreValue && orignStoreValue[DATEFLAG]) || null;

        // 如果本次不存在数据，则赋值初始化值
        if (!storeValue) {
          storeValue = state.viewPost;
        }

        // 将内容ID存储起来，为了保证达到阈值时不提示，这里把阈值的id也存入数组
        if (!storeValue.list.includes(data.contentId) || storeValue.list.length === TASKNUM) {
          storeValue.list.push(data.contentId);
        }
        logger.info('浏览次数: ', storeValue.list.length, storeValue.status);
        // 弹出任务弹窗
        if (storeValue.list.length >= TASKNUM && !storeValue.status) {
          missionApi.getMissionConfig({
            type: 6,
          }).then((conf) => {
            if (conf.ret === 0) {
              showTaskToast({
                title: '已浏览10个帖子',
                integral: conf.content.point,
                experience: conf.content.exp,
                addition: '',
              });
              // 弹窗曝光
              report({
                pageid: 1407,
                moduleid: 27,
                eventtype: 2,
                extendid: 9,
              }, '浏览10个帖子任务弹窗曝光');
            }
          });
          storeValue.status = true;
        }

        // 构造新的存储值
        const newStoreValue = {
          list: storeValue.list,
          status: storeValue.list.length > TASKNUM || storeValue.status, // 首次为10的时候
        };

        // 存localStorage
        const storeValueExt = {};
        storeValueExt[DATEFLAG] = newStoreValue;

        localStorage.setItem(storeKey, JSON.stringify(storeValueExt));

        commit('setTask', {
          name: 'viewPost',
          value: newStoreValue,
        });
      }
    },
  },
  getters: {
    viewPost(state) {
      return state.task.viewPost;
    },
  },
};

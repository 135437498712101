<template>
  <div>
    <slot>Not Found</slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      titleData: this.title,
    };
  },
  computed: {
    titleComputed() {
      return this.title;
    },
  },
  created() {
    if (process.env.VUE_ENV === 'server') {
      if (this.$ssrContext.useSonic) {
        this.$ssrContext.installPlugin('sonicHelper');
      }
    }
  },
  head() {
    return {
      title: this.title || '',
      meta: [
        { charset: 'utf-8' },
        { 'http-equiv': 'x-dns-prefetch-control', content: 'on' },
        { name: 'format-detection', content: 'telephone=no' },
        { name: 'viewport', content: 'width=device-width,initial-scale=1.0,user-scalable=no,viewport-fit=cover' },
        { name: 'apple-mobile-web-app-capable', content: 'yes' },
        { name: 'apple-mobile-web-app-status-bar-style', content: 'black' },
      ],
      bodyAttrs: {
        class: 'OK',
      },
    };
  },
  template: {
    plugins: ['aegis', 'liteJsbridge', 'speedReport'],
  },
};
</script>

<style lang="scss">
  @import '~@/assets/scss/common/base';

  #app {
    width: 100%;
    height: 100%;
    &.platform-android.is-app {
      overflow-y: scroll;
      scrollbar-width: thin;
      &::-webkit-scrollbar {
        width: 4px;
        height: 8px;
        border-radius: 3px;
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background: #ccc;
        border-radius: 3px;
      }
    }
  }
</style>

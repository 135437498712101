import Vue from 'vue';
import {
  translateNumToText,
  getRelativeTime,
  getLocalDate,
} from '@/util/util';

Vue.filter('translateNumToText', translateNumToText);
Vue.filter('getRelativeTime', getRelativeTime);
Vue.filter('getLocalDate', getLocalDate);

Vue.filter('viewNumToText', (number) => {
  const num = Number(number) > 0 ? Number(number) : 0;
  if (num > 9999) {
    return `${Math.round(num / 1000) / 10}w`;
  }
  return num;
});

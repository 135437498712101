/**
 * 去除url域名，提取path部分
 * @param {String} url
 * @returns {String}
 *
 * @example
 *  getUrlPath('https://mtest.gameplus.qq.com/community/post-detail?postId=123123123');
 *  getUrlPath('mtest.gameplus.qq.com/community/post-detail?postId=123123123');
 */
export default function getUrlPath(url) {
  const regexp = /(?:=https?\/\/)?[a-zA-z1-9.]+(\/.+)/;
  const result = regexp.exec(url);
  const path = (result ? result[1] : '').replace(/^\/direct\/pages\//, '/'); // 去除baseURL
  return path;
}

/**
 * isIOS
 * @author donaldcen
 * @createTime 2017/9/19
 */
import toNum from '../../../utils/toNum'
import platform from './platform'

export default function () {
  return toNum(platform('os ')) || toNum(platform('os/'))
}

import jsbridge from '../../libs/jsbridge';
import * as os from '../../libs/os';

/**
 * 直接调用jsbridge
 * @option {String} schema 协议，默认jsbridge
 * @param {String} url 路径 [namespace]/[api]
 * @option {*} params 参数
 */
export function call (schema, url, params) {
  let schemaFormatted = '';
  let urlFormatted = '';
  let paramsFormatted = {};
  if (arguments.length === 1) {
    // 如果只有一个参数，那么认为一定是url
    schemaFormatted = '';
    urlFormatted = schema;
    paramsFormatted = {};
  } else if (arguments.length === 2) {
    if (typeof url === 'object') {
      // 此时证明schema是url，url是params
      schemaFormatted = '';
      urlFormatted = schema;
      paramsFormatted = url;
    } else if (typeof url === 'string'){
      // 此时证明params为空
      schemaFormatted = schema;
      urlFormatted = url;
      paramsFormatted = {};
    }
  } else if (arguments.length === 3) {
    schemaFormatted = schema;
    urlFormatted = url;
    paramsFormatted = params;
  }
  return jsbridge({
    schema: schemaFormatted,
    url: urlFormatted,
    params: paramsFormatted,
  }, {
    callbackType: 'observer',
    customCallback: true
  });
}

export const env = os;

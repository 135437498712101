
import jsbridge from "../../libs/jsbridge";

/**
 * @typedef Response 返回
 * @private
 * @type {Object}
 * @property {Object} data 返回参数，直接透传评论接口返回内容
 * @property {Number} result 0 代表成功
 */

/**
 * 提交评论
 * 
 * @function postComment
 * @memberof user
 * 
 * @param {Object} params
 * @param {Number} params.type 评论类型 1:问题回答 2:一级评论 3:二级评论
 * @param {Number} params.parent_id 父节点ID 一级评论时为帖子id，二级评论时为一级评论id
 * @param {Number} params.root_id 根节点ID
 * @param {Number} params.commentator_id 被评论者id
 * @param {Number} params.reply_id 被回复的二级评论id
 * @param {Number} params.attribute 评论属性 1:普通评论 2:官方评论 3:置顶评论
 * @param {String} params.nickname 被评论者头像
 * @param {String} params.content 被评论的内容 - 用于输入框展示
 * @returns {Promise<Response>}
 */
export function postComment(params = {}) {
  return jsbridge({url: "user/postComment", params});
}


import jsbridge from "../../libs/jsbridge";

/**
 * 启用下拉刷新
 * 安卓1.5版本增加
 * 
 * @function enablePullDown
 * @memberof ui
 * 
 * @param {any} params 
 */
export function enablePullDown(params) {
  return jsbridge({url: "ui/enablePullDown", params: params},{
    callbackType: 'observer',
    customCallback: true,
  });
}



/**
 * 收起下拉刷新动画
 * 安卓1.5版本增加
 * 
 * @function refreshCompleted
 * @memberof ui
 * 
 * @param {any} params 
 */
export function refreshCompleted(params) {
  return jsbridge({url: "ui/refreshCompleted", params: params});
}



/**
 * 用户模块相关API
 * */
import { gameplusApi } from '@/util/http';

export default {
  /**
   * 获取用户信息
   * @deprecated 内部测试用的接口。改成使用 getUserLoginInfo
   * @param { Object } params
   * @param {Number} params.uid 用户uid
   * @param {Number} params.summary 用户uid 1：获取摘要，其他：详情
   * @return { Object } 返回获取结果
   * */
  getUserInfo({ uid, summary = 2 } = {}) {
    return gameplusApi.request({
      method: 'post',
      url: '/community.UserSrv/GetUserInfo',
      data: {
        uidList: [uid],
        summary,
        online_status: 1,
      },
      options: {
        errorHandler: false,
      },
    }).then((res) => {
      if (res && res.data && res.data.ret === 0) {
        return res.data;
      }
      return Promise.reject(res);
    });
  },
  /**
   * 获取用户信息
   * @param { Object } params
   * @param {Number} params.uid 用户uid
   * @return { Object } 返回获取结果
   * */
  getUserLoginInfo({ uid } = {}) {
    return gameplusApi.request({
      method: 'post',
      url: '/community.UserSrv/GetUserLoginInfo',
      data: {
        uid,
      },
      options: {
        errorHandler: false,
      },
    });
  },
  /**
   * 获取别的用户信息
   * @param { Object } params
   * @param {Number} params.uid 用户uid
   * @return { Object } 返回获取结果
   * */
  getPersonalDetail({
    uid,
  } = {}) {
    return gameplusApi.request({
      method: 'post',
      url: '/community.UserSrv/GetPersonalDetail',
      data: {
        uid,
      },
      options: {
        errorHandler: false,
      },
    });
  },
  /**
   * 关注用户
   * @param { Object } params
   * @param {Array|Number} params.uid 关注人uid
   * @return { Object } 返回获取结果
   * */
  followUser({ uid } = {}) {
    return gameplusApi.request({
      url: '/community.UserSrv/Follow',
      data: {
        uid,
      },
    });
  },
  /**
   * 取消关注用户
   * @param { Object } params
   * @param {Array|Number} params.uid 关注人uid
   * @return { Object } 返回获取结果
   * */
  cancelFollowUser({ uid } = {}) {
    return gameplusApi.request({
      url: '/community.UserSrv/CancelFollow',
      data: {
        uid,
      },
    });
  },

  /**
   * 发送验证码
   */
  sendCaptcha({
    uid, phone, countryCode,
  }) {
    return gameplusApi.request({
      url: '/community.UserSrv/SendCaptcha',
      data: {
        uid,
        countryCode,
        phone,
      },
      options: {
        decamelizeRequest: true,
      },
    });
  },

  /**
   * 绑定手机
   * @param {String} uid 用户的uid
   * @param {String} phone 用户手机号
   * @param {String} countryCode 国家号，例如 86
   * @param {String} captcha 验证码
   * @param {Number} bindType 绑定类型。普通绑定：0，解绑再绑定：1
   */
  bindPhone({
    uid, phone, countryCode, captcha, bindType,
  }) {
    return gameplusApi.request({
      url: '/community.UserSrv/BindPhone',
      data: {
        uid,
        countryCode,
        phone,
        captcha,
        bindType,
      },
      options: {
        decamelizeRequest: true,
        excludeRet: [20001], // 这个不是错误，正常返回即可
      },
    });
  },

  /**
   * 解除绑定手机
   * @param {String} uid 用户的uid
   */
  unbindPhone({
    uid,
  }) {
    return gameplusApi.request({
      url: '/community.UserSrv/UnbindPhone',
      data: {
        uid,
      },
      options: {
        decamelizeRequest: true,
      },
    });
  },

  /**
   * wx登录用户绑定QQ
   * @param {String} uid 用户的uid
   * @param {String} qq qq号
   */
  bindUserQQ({
    uid,
    qq,
  }) {
    return gameplusApi.request({
      url: '/community.UserSrv/SetUserQQ',
      data: {
        uid,
        qq,
      },
      options: {
        decamelizeRequest: true,
      },
    });
  },
  /**
   * 注销用户
   * @param {Object} param 传参对象
   * @param {String} param.captcha 验证码
   * */
  closeUserAccount({ captcha }) {
    return gameplusApi.request({
      url: '/community.UserSrv/CloseUserAccount',
      data: {
        captcha,
      },
      options: {
        decamelizeRequest: true,
        errorHandler: false,
      },
    });
  },
  /**
   * 判断用户是否满足注销条件
   * */
  checkCloseAccount() {
    return gameplusApi.request({
      url: '/community.UserSrv/CheckCloseAccount',
      data: {
      },
      options: {
        decamelizeRequest: true,
        errorHandler: false,
      },
    });
  },
  /**
   * 取消用户注销信息
   * @deprecated 内部测试用的接口。
   * @param {Object} param 传参对象
   * @param {Number} param.uid 用户ID
   * */
  cancelCloseUserAccount({ uid }) {
    return gameplusApi.request({
      url: '/community.UserSrv/CancelCloseUserAccount',
      data: {
        uid,
      },
      options: {
        decamelizeRequest: true,
        errorHandler: false,
      },
    });
  },

};

import jsbridge from "../../libs/jsbridge";
import {
  checkCommand,
  checkPass,
  hasKeys, paramsIsObject,
  validator
} from "../../utils/verifyHelper";
/**
 * 显示积分任务完成弹窗
 *
 * @function showTaskToast
 * @memberof ui
 *
 * @param {Object} params
 * @param {String} params.title 页面title
 * @param {Number} params.integral 积分数
 * @param {Number} params.experience 经验值
 * @param {String} params.addition 额外信息
 * {"title":"标题","integral": 100, "experience": 100, "addition": "额外信息"}
 */
export function showTaskToast(params) {
  return jsbridge({url: "ui/showTaskToast", params: params});
}



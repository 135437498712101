
import jsbridge from "../../libs/jsbridge";

/**
 * 监听并拦截app返回键
 * 安卓 1.8以前只支持拦截侧边返回事件
 * 1.8.1安卓和ios新增支持拦截顶部返回按钮、系统返回键、侧边返回
 * 
 * @function setBackListener
 * @memberof ui
 * 
 * @param {Object} params
 * @param {Number} params.action 行为 1=增加监听，0=移除监听
 * @param {Funciton} params.callback 回调
 * @example
 * ```
 * jsbridge.ui.setBackListener({
    action: 1, // 增加监听
    callback: () => {
      console.log('setBackListener callback');
    },
  });
 * ```
 */
export function setBackListener(params) {
  return jsbridge({
    url: "ui/setBackListener",
    params: params
  }, {
    callbackType: 'observer',
    customCallback: true
  });
}



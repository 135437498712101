/**
 * compareAppVersion
 * @author donaldcen
 * @createTime 2017/10/16
 */

import getAppVersion from './getAppVersion'

/**
 * 比较传入版本和当前版本，传入版本比当前版本大为1 ，小或相等则为-1
 * @param v
 * @returns {number}
 */
export default function (v) {
  let i, l, r, len
  //安卓的版本号为2.1.1.118_118
  let a = String((v || '').replace('_', '.')).split('.')
  let appv = getAppVersion()//当前的版本号
  let b = String((appv || '').replace('_', '.')).split('.')

  for (i = 0, len = Math.max(a.length, b.length); i < len; i++) {
    l = (isFinite(a[i]) && Number(a[i])) || 0
    r = (isFinite(b[i]) && Number(b[i])) || 0
    if (l < r) {
      return 1
    } else if (l > r) {
      return -1
    }
  }
  return 0
}
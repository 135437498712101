import jsBridge from "../../libs/jsbridge";
import {checkCommand, checkPass, paramsIsObject, validator} from "../../utils/verifyHelper";

/**
 * 关闭webview
 * 
 * @function closeWebviews
 * @memberof ui
 * 
 * @param {Object} params
 * @param {Number} [params.mode=0] 关闭模式。0: 关闭所有相邻 webview 1: 关闭在当前webview之上的所有相邻webview 2: 关闭在当前webview之下的所有相邻webview
 * @param {Boolean} [params.exclude=true] 是否关闭当前webview。true: 关闭当前webview；false：不关闭当前
 * 我也不太清楚
 * @returns {Promise}
 */
export function closeWebviews(params) {
  let paramsCheck = checkPass(
    checkCommand(paramsIsObject('closeWebviews'))(params)
  );
  if (paramsCheck) {
    return Promise.reject(paramsCheck);
  }
  return jsBridge({
    url: "ui/closeWebviews",
    params: params
  });
}

import jsbridge from "../../libs/jsbridge";
import {checkCommand, checkPass, hasKeys, paramsIsObject, validator} from "../../utils/verifyHelper";
import {isString} from "../../libs/typeChecker";

/*
* 打开时间选择器 1.8.2 新增
* @function selectDate
* @memberof ui
*
* @param {Object} params
* @param {String} params.title 标题
* @param {int} params.startDate 开始时间戳
* @param {int} params.endDate 结束时间
* @param {int} params.initDate 初始化时间
* @param {String} params.desc 描述小字
* @param {String} params.confirmText 确认按钮文案，默认为 "完成"
* @param {Function} params.callback 回调函数
*
* 回调数据
* @return {Object} data
* @return {int} data.date 时间戳
* */
export function selectDate(params) {
  let checkResult = checkPass(
    checkCommand(
      paramsIsObject('selectDate'),
      hasKeys('selectDate',"title"),
      validator("selectDate:params.title必须为string", isString, "title")
    )(params)
  );
  if (checkResult) {
    return Promise.reject(checkResult);
  }
  return jsbridge({
    url: "ui/selectDate",
    params: params
  });
}

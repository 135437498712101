/**
 * Created by Dreamzhu on 2017/6/27.
 */

import { isServerRender, tswPlug } from '@/util/env';
import { parseUrl } from '@/util/uri';
import { logger } from '@/util/logger';

let L5;
let config;

if (isServerRender && tswPlug) {
  L5 = tswPlug('api/L5/L5.api');
  config = tswPlug('config');
}

function requestCb(params) {
  if (isServerRender && tswPlug && process.env.NODE_ENV === 'production') {
    const l5Config = config.cgiL5;
    if (params && params.url && l5Config) {
      const location = parseUrl(params.baseURL + params.url);
      if (location.host && l5Config[location.host]) {
        const cgiConfig = l5Config[location.host];
        const route = L5.ApiGetRouteSync(cgiConfig.l5);
        logger.info({ message: 'l5 ApiGetRouteSync ', route });
        if (route && route.ret === 0 && route.ip && route.port) { // 通过L5获取
          // eslint-disable-next-line no-param-reassign
          params.proxy = {
            host: route.ip,
            port: route.port,
          };
          route.beginTime = Date.now();
          // eslint-disable-next-line no-param-reassign
          params.l5 = route;
        } else if (cgiConfig.online) { // 获取失败
          // eslint-disable-next-line no-param-reassign
          params.proxy = {
            host: cgiConfig.online.ip,
            port: cgiConfig.online.port,
          };
          logger.warn({ message: 'l5 ApiGetRouteSync error ', useProxy: params.proxy, route });
        } else {
          logger.warn({ message: 'l5 ApiGetRouteSync error no proxy', route });
        }
      }
    }
  } else if (isServerRender && process.env.NODE_ENV === 'development') {
    // 配置成为自己fiddler的地址即可抓到服务端请求的包
    const fiddler = (process.yargs && process.yargs.cgi_proxy) || process.env.PROXY || '';

    const arr = fiddler.split(':') || [];

    if (fiddler && arr.length === 2) {
      // eslint-disable-next-line no-param-reassign
      params.proxy = {
        host: arr[0] || '127.0.0.1',
        port: arr[1] || '8888',
      };
      // console.log('服务器请求已代理到', params.proxy);
    }
  }
  return params;
}

function report(type, params) {
  if (isServerRender && tswPlug) {
    if (params && params.config && params.config.l5) {
      const l5Data = params.config.l5;
      const data = {
        modid: l5Data.modid,
        cmdid: l5Data.cmd,
        cmd: l5Data.cmd,
        usetime: Date.now() - l5Data.beginTime,
        ret: type === 'error' ? -1 : 0,
        ip: l5Data.ip,
        port: l5Data.port,
        pre: l5Data.pre,
        flow: l5Data.flow,
        debug: config.isReady,
        timeout: l5Data.timeout,
      };
      logger.info({ message: 'L5 ApiRouteResultUpdateSync', data });
      L5.ApiRouteResultUpdateSync(data);
    }
  }
}

function responseCb(response) {
  report('suc', response.config);
}

function errorCb(response) {
  report('error', response.config);
}

export default {
  request: requestCb,
  response: responseCb,
  error: errorCb,
};

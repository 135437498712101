/**
 * @file 请求中间件函数
 * @description 分为request, response, error 这几种类型。需注意类型和引入顺序
 * @example
 *  http.use('request', csrfToken);
 *  http.use('response', retFilter);
 *  http.use('error', universalErrorHandler);
 */
// import cookie from 'js-cookie';
import cookie from '@/util/cookie';
import { humps } from '@/util/util';
import toast from '@/ui/toast/index';
import * as protobuffer from '@/protobuffer/index';
import { logger } from '../logger';
import { serverDocument as document } from '@/util/env';

/**
 * 获取Token（防CSRF攻击）
 * @return {Number} token
 * */
function getToken() {
  let hash = 5188;
  const str = cookie.get('sx_access_token') || cookie.get('accessToken') || cookie.get('access_token') || ''; // 用户登录相关key
  logger.info('getcctk', `das1${str}31nkj`); // TODO 优化日志打印
  for (let i = 0, len = str.length; i < len; ++i) {
    // eslint-disable-next-line no-bitwise
    hash += (hash << 5) + str.charCodeAt(i);
  }
  // eslint-disable-next-line no-bitwise
  return String(hash & 0x7fffffff);
}

/**
 * 获取请求ID，方便跟踪
 * @return {String} requestId
 * */
function getRequestId() {
  const guid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line one-var,no-bitwise
    const r = Math.random() * 16 | 0;
    // eslint-disable-next-line no-bitwise,no-mixed-operators
    const v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
  const ts = new Date().getTime();
  return `${guid}-${ts}`;
}

/**
 * 注入CSRF token
 * @param {Object} reqConfig 请求
 */
export function csrfToken(reqConfig) {
  // console.warn(reqConfig);
  // eslint-disable-next-line no-param-reassign
  reqConfig.headers['G-Token'] = getToken(); // 添加csrf
  // logger.info('Request', reqConfig);
  return reqConfig;
}

/**
 * 注入请求ID
 * @param {Object} reqConfig 请求
 */
export function requestId(reqConfig) {
  // eslint-disable-next-line no-param-reassign
  reqConfig.headers['request-id'] = getRequestId();
  return reqConfig;
}

/**
 * 注入CSRF token
 * @param {Object} reqConfig 请求
 */
export function decamelizeRequest(reqConfig) {
  if (reqConfig.options.decamelizeRequest) {
    if (reqConfig.method.toLowerCase() === 'post') {
      // eslint-disable-next-line no-param-reassign
      reqConfig.data = humps.decamelizeKeys(reqConfig.data);
    }
  }
  return reqConfig;
}

export function putHeaders(reqConfig) {
  const commReq = cookie.get('commReq');
  // eslint-disable-next-line no-param-reassign
  if (commReq) reqConfig.headers.CommReq = commReq;
  return reqConfig;
}

/**
 * 返回数据转换成驼峰
 * @param {Object} res
 */
export function camelizeResponse(res) {
  try {
    if (res.data) {
      res.data = humps.camelizeKeys(res.data);
    }
  } catch (err) {
    logger.error('camelizeResponse error', err);
    Promise.reject(err);
  }
  return res;
}

/**
 * 过滤业务返回错误
 * @param {Object} res
 */
export function retFilter(res) {
  const { options } = res.config;
  if (res.data) {
    if (res.data.ret === 0 || options.excludeRet.indexOf(res.data.ret) > -1) {
      return Promise.resolve(res.data);
    }
    return Promise.reject(res);
  }
  return Promise.reject(res);
}

/**
 * 统一错误处理
 * @param {Object} err
 */
export function universalErrorHandler(err) {
  if (err.status === 200 && err.data) {
    // 业务错误
    if (err.config.options.errorHandler === 'auto' && err.data.msg) {
      toast(err.data.msg);
    }
    return Promise.reject(err);
  }
  if (err.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    // 服务器错误
    if (err.config.options.errorHandler !== false) {
      toast('服务器出错了，请稍后重试');
    }
    return Promise.reject(err);
  }
  if (err.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    // 一般为请求问题，比如请求超时，请求跨域报错等等
  } else {
    // Something happened in setting up the request that triggered an Error
    // 一般为代码错误
  }
  return Promise.reject(err);
}

export function errorLogger(err) {
  try {
    logger.info('----------------- Http Error Start ------------------');
    logger.error('Http Error', err);
    if (err.status === 200) {
      if (err.data) logger.info('Http Error Type', '业务错误');
      else logger.info('Http Error Type', '返回体为空');
    } else if (err.response) {
      logger.info('Http Error Type', '接口响应码错误');
    } else if (err.request) {
      logger.info('Http Error Type', '请求失败');
    }
    logger.info('Http Error URL', err.config.url);
    logger.info('Http Error params', err.config.data || err.config.params);
    logger.info('Http Error config', err.config);
    logger.info('Http Error Req Headers', err.config.headers);
    logger.info('Http Error Request', err.request || '(No Request!)');

    if (document && document.cookie) logger.info('Http Error Req Cookie', document.cookie);
    if (err.data) { // 业务返回的错误
      logger.info('Http Error Response', err || '(No Data!)');
      logger.info('Http Error Res Headers', err.headers || '(No Headers!)');
      logger.info('Http Error Res Data', err.data || '(No Headers!)');
    } else {
      logger.info('Http Error Response', err.response || '(No Response!)');
      if (err.response) logger.info('Http Error Res Headers', err.response.headers);
      if (err.response) logger.info('Http Error Res Data', err.response.data || '(No Data!)');
    }
    logger.info('----------------- Http Error End ------------------');
  } catch (error) {
    // 打印日志时候出错
    logger.error('Http Logger Error', error);
  }
  return Promise.reject(err);
}

// 微信场景出现cookie污染导致401，这里发现鉴权失败就清除一次cookie
// TODO: 继续跟进，后端网关插件里增加开关控制，对读接口鉴权失败当做游客模式
export function authenticateFailed(err) {
  logger.warn('authenticateFailed call', err);
  try {
    const data = (err.response && err.response.data) || {};
    const storageKey = '__clear_cookies';
    const nowTime = +(new Date());
    const lastClearTime = window.localStorage.getItem(storageKey) || 0;
    const diffLargeTime = 129600000; // 1.5 * 24 * 60 * 60 * 1000
    if (data.ret === 7 && (nowTime - lastClearTime >= diffLargeTime)) {
      window.localStorage.setItem(storageKey, nowTime);
      logger.warn(`authenticateFailed success at ${nowTime}`);
      const clearCookieList = ['accessToken', 'access_token', 'appId', 'appid', 'openId', 'openid', 'loginType', 'acctype'];
      const domainList = window.location.hostname.replace(/(.*).qq\.com/ig, '$1.').split('.');
      const domain = 'qq.com';
      clearCookieList.forEach((key) => {
        let clearDomain = domain;
        for (let i = domainList.length + 1;i >= 0; i--) {
          clearDomain = typeof domainList[i] !== 'undefined' ? `${domainList[i]}.${clearDomain}` : clearDomain;
          cookie.remove(key, { path: '/', domain: clearDomain });
          clearDomain = clearDomain.replace(/^\./ig, '');
        }
        cookie.remove(key, { path: '/' });
        cookie.remove(key);
      });
      window.location.reload();
    }
  } catch (e) {
    // nothing to do
  }
  return Promise.reject(err);
}

// 请求数据改成pb
export function encodeReq(reqConfig) {
  if (!reqConfig.options.reqProto) {
    return reqConfig;
  }
  // eslint-disable-next-line no-param-reassign
  reqConfig.headers['content-type'] = 'application/pb';
  // eslint-disable-next-line no-param-reassign
  reqConfig.responseType = 'arraybuffer';
  const encodeData = protobuffer.encode(reqConfig.data, reqConfig.options.reqProto);
  // eslint-disable-next-line no-param-reassign
  reqConfig.data = Buffer.from(encodeData);
  return reqConfig;
}

// pb响应数据解码
export function decodeRes(res) {
  const { options } = res.config;
  if (res.data && options.resProto) {
    const buffer = res.data;
    try {
      res.data = protobuffer.decode(buffer, options.resProto);
    } catch (error) {
      logger.error('pb响应数据解码 err', error);
    }
  }
  return Promise.resolve(res);
}

/* eslint-disable no-console */
import protobuf from 'protobufjs/light';
import { logger } from '@/util/logger';

// proto描述文件，使用protobuf_tool工具生成。见 https://git.code.oa.com/jesonliao/protobuf_tool
import postProto from './GetHomePageFeedsDetail.proto.min.json';

const root = protobuf.Root.fromJSON(postProto);

export function encode(payload, messageName) {
  console.time('encodeReq lookupType');
  let Message;
  try {
    Message = root.lookupType(`${messageName}`);

    console.timeEnd('encodeReq lookupType');
    const errMsg = Message.verify(payload);
    if (errMsg) throw Error(errMsg);
    // Create a new message
    const message = Message.create(payload); // or use .fromObject if conversion is necessary

    // Encode a message to an Uint8Array (browser) or Buffer (node)
    console.time('encodeReq encode');
    const buffer = Message.encode(message).finish();
    console.timeEnd('encodeReq encode');

    return buffer;
  } catch (error) {
    logger.error('protobuf encode error', error);
    throw error;
  }
}

export function decode(payload, messageName) {
  try {
    console.time('decodeRes lookupType');
    const Message = root.lookupType(`${messageName}`);
    console.timeEnd('decodeRes lookupType');

    const buffer = protobuf.util.newBuffer(payload);

    // Create a new message
    console.time('decodeRes decode');
    const message = Message.decode(buffer); // or use .fromObject if conversion is necessary
    const obj = Message.toObject(message, {
      defaults: true,
    });
    console.timeEnd('decodeRes decode');

    // Encode a message to an Uint8Array (browser) or Buffer (node)

    return obj;
  } catch (error) {
    logger.error('protobuf decode error', error);
    throw error;
  }
}

export const a = 1;

import { v4 as uuidV4 } from 'uuid'; // from https://github.com/uuidjs/uuid
import cookie from 'js-cookie';
import { logger } from './logger';

const PVID_COOKIE_KEY = '__gc_pvid';
const PVID_COOKIE_DOMAIN = '.qq.com';

/**
 * 获取唯一设备id
 * 或者生成一个存在cookie中
 *
 * @returns {String} uuid
 */
export default function () {
  try {
    let pvid = cookie.get(PVID_COOKIE_KEY);
    if (!pvid) {
      pvid = uuidV4();
      cookie.set(PVID_COOKIE_KEY, pvid, { path: '', domain: PVID_COOKIE_DOMAIN, expires: 365 });
    }
    return pvid;
  } catch (error) {
    logger.error('get pvid error', error);
    return '';
  }
}

import Preload from './index_old.vue';


const routes = [
  {
    name: 'preload-loading',
    path: '/preload',
    component: Preload,
  },
];

export default routes;

/**
 * 获取格式化处理后的数据上报参数
 * @param {*} params
 */
function getFormatReportParams(params) {
  const formatParams = { ...params };
  if ('canReport' in formatParams) {
    delete formatParams.canReport;
  }
  return formatParams;
}

/**
 * 从Vue中获取reportData函数，进行数据上报
 * @param {*} Vue
 * @param {*} params 数据上报的参数对象
 * @param {*} errorInfo 数据上报钩子函数不存在时的错误提示信息
 */
function reportData(Vue, params, errorInfo = '尚未配置reportData数据上报函数') {
  if (Vue && typeof Vue.reportData === 'function') {
    const { canReport = true } = params;
    if (canReport) {
      const formatParams = getFormatReportParams(params);
      Vue.reportData(formatParams);
    }
  } else {
    throw Error(errorInfo);
  }
}

export { reportData };

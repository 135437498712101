import jsbridge from "../../libs/jsbridge";
import {
    checkCommand,
    checkPass,
    hasKeys, paramsIsObject,
    validator
} from "../../utils/verifyHelper";
import {isObject, isString} from "../../libs/typeChecker";

/**
 * toast 提示
 * 
 * @function showTips
 * @memberof ui
 * 
 * @param {Object} params 
 * @param {String} params.text 提示文本内容
 * @returns {Promise<any>}
 */
export function showTips(params) {
  let checkResult = checkPass(
    checkCommand(
      paramsIsObject('showTips'),
      hasKeys('showTips',"text"),
      validator("showTips:params.text必须为string", isString, "text")
    )(params)
  );
  if (checkResult) {
    return Promise.reject(checkResult);
  }
  return jsbridge({
    url: "ui/showTips",
    params: params
  });
}

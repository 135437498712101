/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-multi-assign */
import Vue from 'vue';
import Vuex from 'vuex';
import { checkEnv } from '@/util/browser-env';
import { serverWindow } from '@/util/env';
import user from './user';
import comment from './comment';
import app from './app';
import setting from './setting';
import task from './task';

const checkEnvObj = checkEnv();
Vue.use(Vuex);

if (process.env.NODE_ENV === 'development' && serverWindow.location.search.indexOf('testApp=1') > -1) {
  checkEnvObj.isApp = 1;
}

Vue.use(Vuex);

function registerModule(store, modules) {
  if (store && modules) {
    store._modulesCount = store._modulesCount || {};
    const moduleCount = store._modulesCount;
    Object.keys(modules).forEach((name) => {
      if (moduleCount[name] > 0) {
        moduleCount[name] += 1;
      } else {
        const newModule = { ...modules[name], state: store.state[name] };
        store.registerModule(name, store.state[name] ? newModule : modules[name]);
        moduleCount[name] = 1;
      }
    });
  }
}

function unregisterModule(store, modules) {
  if (store && modules) {
    store._modulesCount = store._modulesCount || {};
    const moduleCount = store._modulesCount;
    Object.keys(modules).forEach((name) => {
      if (moduleCount[name] > 0) {
        moduleCount[name] -= 1;
      }
      if (moduleCount[name] === 0) {
        store.unregisterModule(name);
        delete moduleCount[name];
      }
    });
  }
}

function registerAllModules(store, component) {
  registerModule(store, component.storeModules);
  if (component.components) {
    Object.keys(component.components).forEach((name) => {
      if (typeof component.components[name] !== 'function') {
        registerAllModules(store, component.components[name]);
      }
    });
  }
}

function unregisterAllModules(store, component) {
  unregisterModule(store, component.storeModules);
  if (component.components) {
    Object.keys(component.components).forEach((name) => {
      if (typeof component.components[name] !== 'function') {
        unregisterAllModules(store, component.components[name]);
      }
    });
  }
}

export function createStore() {
  const options = {
    state: {
      isApp: checkEnvObj.isApp,
      isIOS: checkEnvObj.ios,
      isAndroid: checkEnvObj.android,
      isIOS_11_1_2: checkEnvObj.isIOS_11_1_2,
    },
    modules: {
      user,
      comment,
      app,
      setting,
      task,
    },
    actions: {
      // webbundle重用，需要重置状态
      resetState({ commit }) {
        commit('user/resetState');
        commit('app/resetState');
        commit('comment/resetState');
        commit('task/resetState');
        commit('setting/resetState');
      },
    },
    plugins: [

    ],
  };
  if (process.env.NODE_ENV !== 'production') {
    // options.plugins.push(createLogger());
  }
  const store = new Vuex.Store(options);
  store.registerAllModules = (component) => {
    registerAllModules(store, component);
    return store;
  };
  store.unregisterAllModules = (component) => {
    unregisterAllModules(store, component);
    return store;
  };
  store.registerModules = (components) => {
    registerModule(store, components);
  };
  store.unregisterModules = (components) => {
    unregisterModule(store, components);
  };
  return store;
}
const store = createStore();
export default store;

/**
 * 评论API
 * */
// import GamePlusAPI from '../util/gameplus-api';
// import commentMockData from '../mock/commentList.json';
// import groupInfoMockData from '../mock/groupInfo.json';
import { gameplusApi } from '@/util/http';

// const http = new GamePlusAPI();

export default {
  /**
   * 获取二级评论列表
   * @param {Number} params.userId 查询用户ID
   * @param {Number} params.parentId 父节点ID
   * @param {Number} params.rootId 帖子ID/问题ID
   * @param {Number} params.groupId 圈子ID
   * @param {Number} params.type 评论类型 1：问题回答 2：一级评论 3.二级评论 99非法类型
   * @param {Number} params.commentId 评论ID,查询详情使用
   * @param {Number} params.scenery 使用场景 1.回答热评 2：评论详情 3：关注tab热评 4：圈子热评 5个人中心热评
   * @param {Number} params.orderBy 1:按照热度排序 2.按照点赞数 3：按事件降序 4：按时间升序
   * @param {Number} params.pageSize 分页参数：每页获取记录数
   * @param {Number} params.pageContext 分页参数：下一次请求上下文
   * @param {Number} params.sonNumber 带有子评论摘要个数：推荐为2，当前再获取L1评论详情列表中使用
   * @param {Number} params.parentPageContext 父分页参数，如果是二级评论展开，则传评论页返回的pageContext
   * @param {skipNum} params.skipNum 跳转的评论条数，再二级评论展开
   * @returns { Object }
   */
  getSubCommentListByPostId(params) {
    return gameplusApi.request({
      url: '/community.CommentSrv/GetCommentList',
      data: {
        userId: params.userId,
        parentId: params.parentId,
        rootId: params.rootId,
        groupId: params.groupId,
        type: params.type,
        commentId: params.commentId,
        scenery: params.scenery,
        orderBy: params.orderBy,
        pageSize: params.pageSize || 10,
        pageContext: params.pageContext,
        sonNumber: params.sonNumber,
        parentPageContext: params.parentPageContext,
        skipNum: params.skipNum,
      },
      // mock: commentMockData,
      options: {
        errorHandler: false,
        // reqProto: 'GetHomePageCommentListReq',
        // resProto: 'GetHomePageCommentListRsp',
      },
    });
  },
  /**
   * 通过postId获取评论列表
   * @param { Object } params
   * @param { Number | String } params.userId 用户id
   * @param { Number | String } params.id 帖子id或者评论id
   * @param { Number } params.type 2 一级评论 3 二级评论
   * @param { Number } params.sortType 2. 按照点赞数 3:按时间降序 4:按时间升序
   * @param { Number } params.pageSize 每页数量
   * @param { Number } params.pageContext 下次调用传参用
   * @param { Number } params.sonNumber 子评论个数，先设定为2
   * @param { Number } params.scenery 1 热评 2 一级评论列表
   * @return { Object } 返回获取结果
   * */
  getCommentListByPostId(params) {
    return gameplusApi.request({
      url: '/community.GCFeedDetailSrv/GetHomePageCommentList',
      data: {
        id: Number(params.id),
        scenery: params.scenery,
        type: params.type,
        sortType: params.sortType,
        pageSize: params.pageSize || 10,
        pageContext: params.pageContext,
        sonNumber: params.sonNumber,
        userId: params.userId,
        oderBy: params.oderBy,
      },
      // mock: commentMockData,
      options: {
        errorHandler: false,
        // reqProto: 'GetHomePageCommentListReq',
        // resProto: 'GetHomePageCommentListRsp',
      },
    });
  },
  /**
   * 通过postId添加帖子的评论（非禁言用户且有权限的用户可以添加帖子评论）
   * @param { String } comment 评论内容
   * @param { Number } postId 帖子ID
   * @return { Object } 返回添加结果和ID
   * */
  addCommentByPostId(comment, postId) {
    return gameplusApi.request({
      url: 'comment/addCommentByPostId',
      data: {
        comment,
        postId,
      },
    });
  },
  /**
   * 删除评论（仅能删除自己的评论或自己帖子的评论）
   * @param { Number } commentId 评论ID
   * @return { Object } 返回添加结果和ID
   * */
  delCommentById(commentId) {
    return gameplusApi.request({
      url: 'comment/delCommentById',
      data: {
        commentId,
      },
    });
  },
  /**
   * 获取评论详细信息
   * @param { Number } commentId 评论ID
   * @return { Object } 返回添加结果和ID
   * */
  getCommentById(commentId) {
    return gameplusApi.request({
      url: 'comment/getCommentById',
      data: {
        commentId,
      },
    });
  },
  /**
   * 更新评论状态
   * @param { Number } commentId 评论ID
   * @param { Object } updateBitSet 更新评论位图
   * @param { Object } data 评论对象，写key则意味着更新
   * @param { Object } accuse_reason 举报理由
   * @return { Object } 返回添加结果和ID
   * */
  updateCommentById({
    commentId, updateBitSet = '', data = {}, accuseReason = null,
  } = {}) {
    return gameplusApi.request({
      url: '/community.CommentSrv/UpdateComment',
      data: {
        comment_id: commentId,
        update_bit_set: updateBitSet,
        data: {
          user_id: data.userId,
          root_id: data.rootId,
          parent_id: data.parentId,
          group_id: data.groupId,
          type: data.type,
          like: data.like,
          status: 1,
        },
        accuse_reason: accuseReason,
      },
      // mock: { ret: 0, msg: 'SUCCEED', commentId: '0' },
    });
  },
  /**
   * 删除评论
   * @param { Number } commentId 评论ID
   * @param { Object } data 评论对象，写key则意味着更新
   * @return { Object } 返回添加结果和ID
   * */
  deleteCommentById({ commentId, data = {} } = {}) {
    return gameplusApi.request({
      url: '/community.CommentSrv/DeleteComment',
      data: {
        comment_id: commentId,
        data: {
          user_id: data.userId,
          root_id: data.rootId,
          parent_id: data.parentId,
          type: data.type,
          status: 1,
        },
      },
      // mock: { ret: 0, msg: 'SUCCEED', commentId: '0' },
    });
  },
  /**
   * 获取评论举报理由
   * @return { Object } 理由
   * */
  getCommentAccusedReason() {
    return gameplusApi.request({
      url: '/community.CommentSrv/GetCommentAccusedReason',
    });
  },
};

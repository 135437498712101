import settingApi from '@/api/setting';

const feedbackTypeListCacheKey = 'setting.feedback.typeList';

export default {
  namespaced: true,
  state: {
    feedbackTypeList: [],
  },
  mutations: {
    setFeedbackTypeList(state, list) {
      state.feedbackTypeList = list;
    },
    resetState(state) {
      state.feedbackTypeList = [];
    },
  },
  actions: {
    getFeedbackTypeListRemote({ commit }) {
      return settingApi.getFeedbackType().then((typeList) => {
        localStorage.setItem(feedbackTypeListCacheKey, JSON.stringify(typeList));
        commit('setFeedbackTypeList', typeList);
      });
    },
    getFeedbackTypeList({ dispatch, commit }) {
      const cacheValue = localStorage.getItem(feedbackTypeListCacheKey);
      if (cacheValue) {
        commit('setFeedbackTypeList', JSON.parse(cacheValue));
        setTimeout(() => {
          dispatch('getFeedbackTypeListRemote');
        }, 100);
        return Promise.resolve();
      }
      return dispatch('getFeedbackTypeListRemote');
    },
  },
  getters: {
    feedbackTypeList(state) {
      return state.feedbackTypeList;
    },
  },

};


import jsbridge from "../../libs/jsbridge";


/**
 * 设置右上角按钮
 * 
 * @function setRightButton
 * @memberof ui
 * 
 * @param {Object} params
 * @param {function} callback 点击右上角的时候会调用这个函数
 * @returns {Promise<any>}
 * 
 * @example
 * // 设置点击右上角触发分享弹框
 * jsbridge.ui.setRightButton(function () {
 *   jsbridge.share.showShareDialog({
 *     // 分享参数见 showShareDialog
 *   });
 * })
 */
export function setRightButton(params) {
  return jsbridge({url: "ui/setRightButton", params: params}, {
    callbackType: 'observer',
    customCallback: true,
  });
}

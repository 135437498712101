/**
 * 检查命令生成器
 * @param validators
 * @returns {function(*=): (*|Array)}
 */
import {isArray, isArrayNumber, isObject} from "../libs/typeChecker";

/**
 * 拼装检查函数提
 * @param validators
 * @returns {function(*=): (*|Array)}
 * @example
 * let checkResult = checkPass(
 *checkCommand(
 *paramsIsObject(functionName),
 *hasKeys(functionName, "matchId", "matchCheckInfo", "userOpenIdList"),
 *validator(`${functionName} params.matchId is String`, isString, 'matchId'),
 *validator(`${functionName} params.matchCheckInfo is String`, isString, 'matchCheckInfo'),
 *validator(`${functionName} params.userOpenIdList is ArrayNumber`, isArrayNumber, 'userOpenIdList')
 *)(params)
 *);
 */
export function checkCommand (...validators) {
  return obj =>
    validators.reduce(
      (err, check) =>
        (check(obj)
          ? err
          : err.concat([check.message])),
      []
    );
}

/**
 * @description 校验函数生成器
 * @param message
 * @param fun
 * @param key
 * @returns {function(...[*]=): *}
 */
export function validator (message, fun, key) {
  let f = args =>
    fun.apply(fun, [
      key
        ? isObject(args) && Object(args).hasOwnProperty(key)
          ? args[key]
          : args
        : args
    ]);
  f["message"] = message;
  return f;
}

/**
 * 检查最后的校验结果内容是否为空
 * @param arr
 * @returns {string | *}
 */
export function checkPass (arr) {
  let check = arr =>
    arr.reduce(
      (_arr, item) =>
        (item
          ? Array.isArray(item)
            ? _arr.concat(check(item))
            : _arr.concat([item])
          : _arr),
      []
    );
  return check(isArray(arr) ? arr : [arr]).join(",");
}

/**
 * 判断对象内是否有对应的key
 * @param functionName
 * @param args
 * @returns {function(*=): boolean[]}
 */
export function hasKeys (functionName, ...args) {
  let fun = obj => args.map(key => Object(obj).hasOwnProperty(key));
  fun.message = args.concat(["，在" + functionName + "调用中，如上的key是必须的"]).join(" ");
  return fun;
}

export let paramsIsObject = functionName => validator(functionName + ' params必须为object', isObject)
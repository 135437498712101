/**
 * getPlatform
 * @author marvinqi
 * @createTime 2017/9/20
 */

/**
 *@desc 获取设备类型
 *@returns {string} iOS, pc, android
 */
import isAndroid from './isAndroid'
import isIOS from './isIOS'

export default function () {
  if (isAndroid()) {
    return 'android'
  } else if (isIOS()) {
    return 'iOS'
  } else {
    return 'pc'
  }
}

/**
 * 通过指令来方便 BEM class 的书写方式
 */
import { transCamecase } from '@/util/util';

/**
 * BEM 命名法
 *
 * v-bem:${E}.${M} -> ${B}__${E}--${M}
 * B:block, 自动提取组件的 name，会将驼峰转化为中划线
 * E:element, 如 v-bem:content
 * M:modifier, 如 v-bem:content.active
 *
 * 例如：
 * <template>
 *   <div v-bem>
 *     <div v-bem:title>标题</div>
 *     <div v-bem:name.active>内容</div>
 *     <div v-bem="`icon--success`">内容</div>
 *   </div>
 * </template>
 *
 * <script>
 * export default {
 *   name: 'PPostDetail',
 *   //...
 * };
 * </script>
 *
 * 等价于
 * <template>
 *   <div class="p-post-detail">
 *     <div class="p-post-detail__title">标题</div>
 *     <div class="p-post-detail__name--active">内容</div>
 *     <div class="p-post-detail__icon--success"></div>
 *   </div>
 * </template>
 *
 * TODO: 暂时没办法动态添加类，遇到这种情况只好写回 v-bind:class 的写法
 *
 */
export default {
  bind: (el, binding, vnode) => {
    const { context } = vnode;
    const componentName = context.$options.name || '';
    const block = transCamecase(componentName);
    let element = '';
    let modifier = '';
    let bemClass = `${block}`;

    // 写法分为v-bem=value和v-bem:arg
    if (binding.value) {
      bemClass += `__${binding.value}`;
    } else {
      element = binding.arg;
      Object.keys(binding.modifiers).forEach((m) => {
        if (binding.modifiers[m]) {
          modifier += m;
        }
      });
      if (element) {
        bemClass += `__${element}`;
      }
      if (modifier) {
        bemClass += `--${modifier}`;
      }
    }
    el.classList.add(bemClass);
  },
  stringify: (vnode, options) => {
    const componentName = vnode.context.$options.name || '';
    const block = transCamecase(componentName);
    let bemClass = `${block}`;
    if (options.arg) {
      bemClass += `__${options.arg}`;
    }
    if (vnode.data.staticClass) {
      // eslint-disable-next-line no-param-reassign
      vnode.data.staticClass += ` ${bemClass}`;
    } else {
      // eslint-disable-next-line no-param-reassign
      vnode.data.staticClass = bemClass;
    }

    // console.log(vnode.context.$options.name, bemClass, options);
    //
    //
    //
    // //const block = transCamecase(componentName);

    // stringify (vnode,options){
    //   let to;
    //   if (vnode.context.$options && vnode.context.$options.propsData && vnode.context.$options.propsData.to) {
    //     to = vnode.context.$options.propsData.to;
    //   }
    //   let detailUrl = 'cdn.vip.qq.com/club/client/comic/release/html/detail.html';
    //   if (to && ( to.name == 'large-scale/comic/detail' || ( to.path &&  to.path.indexOf(detailUrl) != -1))
    // || (typeof (to) === 'string' && to.indexOf(detailUrl) != -1)) {
    //     let value = options.value || {}; //数据上报的数据
    //     let sng_trace_id = value.item_id + Date.now();
    //     let prePage = createCurrentPath({page_id: value.page_id, oper_obj_id: value.oper_obj_id,
    // ses_id: value.ses_id, ext4: value.ext4, ext8:value.ext8});
    //     if (typeof (to) === 'string') {
    //       vnode.context.$options.propsData.to = {path: to,
    // query: {prepage: prePage, pos: value.page_id,sng_trace_id:sng_trace_id}};
    //     } else {
    //       to.query || (to.query = {});
    //       to.query.prepage = prePage;
    //       to.pos = value.page_id;
    //       to.query.sng_trace_id = sng_trace_id;
    //     }
    //     const attributes = vnode.data.attrs = vnode.data.attrs || {};
    //     attributes.sng_trace_id = sng_trace_id
    //   }
    //   append(options.rawName, vnode, options.value);
    // }
  },
};


import jsbridge from "../../libs/jsbridge";
import { transferUserInfo } from './util';


/**
 * 登录
 * 
 * @function login
 * @memberof user
 *
 * @param {any} params
 * @returns {Promise<any>}
 */
export function login (params) {
  return jsbridge({url: "user/login", params}).then((res) => {
    if (res.result === 0) {
      const userInfo = transferUserInfo(res.data);
      return {
        ...res,
        data: userInfo
      };
    }
    return res;
  });
}

import {log} from "../libs/logger";
import {isWeex} from "../libs/os";

let setTimeoutFunc = setTimeout;

// 空函数辅助用
function noop () {
}

// 补充bind函数的缺失
function bind (fn, thisArg) {
  return function () {
    fn.apply(thisArg, arguments);
  };
}

// 构造函数
function Observer (fn) {
  if (typeof this !== "object")
    throw new TypeError("Observers must be constructed via new");
  if (typeof fn !== "function") throw new TypeError("not a function");
  this._state = 0;
  this._handled = false;
  this._value = undefined;
  this._deferreds = [];

  doResolve(fn, this);
}

function handle (self, deferred) {
  while (self._state === 3) {
    self = self._value;
  }
  if (self._state === 0) {
    self._deferreds.push(deferred);
    return;
  }
  self._handled = true;
  log("[before immediateFn] " + JSON.stringify(self._value));
  Observer._immediateFn(function () {
    let cb = self._state === 1 ? deferred.onFulfilled : deferred.onRejected;
    if (cb === null) {
      (self._state === 1 ? resolve : reject)(deferred.Observer, self._value);
      return;
    }
    let ret = null;
    try {
      log("[after immediateFn] " + JSON.stringify(self._value));
      ret = cb(self._value);
    } catch (e) {
      reject(deferred.Observer, e);
      return;
    }
    ret && resolve(deferred.Observer, ret);
  });
}

function resolve (self, newValue) {
  log("[notify success] observer" + JSON.stringify(newValue));
  try {
    if (newValue === self)
      throw new TypeError("A Observer cannot be resolved with itself.");
    if (
      newValue &&
            (typeof newValue === "object" || typeof newValue === "function")
    ) {
      let {then} = newValue;
      if (newValue instanceof Observer) {
        self._state = 3;
        self._value = newValue;
        finale(self);
        return;
      } else if (typeof then === "function") {
        doResolve(bind(then, newValue), self);
        return;
      }
    }
    self._state = 1;
    self._value = newValue;
    finale(self);
  } catch (e) {
    reject(self, e);
  }
}

function reject (self, newValue) {
  self._state = 2;
  self._value = newValue;
  finale(self);
}

function finale (self) {
  self._deferreds = self._deferreds || [];
  if (self._state === 2 && (self._deferreds.length === 0 || !self._deferreds)) {
    Observer._immediateFn(function () {
      if (!self._handled) {
        Observer._unhandledRejectionFn(self._value);
      }
    });
  }
  for (let i = 0, len = self._deferreds.length; i < len; i++) {
    handle(self, self._deferreds[i]);
  }
}

function Handler (onFulfilled, onRejected, Observer) {
  this.onFulfilled = typeof onFulfilled === "function" ? onFulfilled : null;
  this.onRejected = typeof onRejected === "function" ? onRejected : null;
  this.Observer = Observer;
}

function doResolve (fn, self) {
  try {
    fn(
      function (value) {
        resolve(self, value);
      },
      function (reason) {
        reject(self, reason);
      }
    );
  } catch (ex) {
    reject(self, ex);
  }
}

Observer.prototype["catch"] = function (onRejected) {
  return this.then(null, onRejected);
};

Observer.prototype.then = function (onFulfilled, onRejected) {
  let prom = new this.constructor(noop);

  handle(this, new Handler(onFulfilled, onRejected, prom));
  return prom;
};

Observer.all = function (arr) {
  let args = Array.prototype.slice.call(arr);

  return new Observer(function (resolve, reject) {
    if (args.length === 0) return resolve([]);
    let remaining = args.length;

    function res (i, val) {
      try {
        if (val && (typeof val === "object" || typeof val === "function")) {
          let {then} = val;
          if (typeof then === "function") {
            then.call(
              val,
              function (val) {
                res(i, val);
              },
              reject
            );
            return;
          }
        }
        args[i] = val;
        if (--remaining === 0) {
          resolve(args);
        }
      } catch (ex) {
        reject(ex);
      }
    }

    for (let i = 0; i < args.length; i++) {
      res(i, args[i]);
    }
  });
};

Observer.resolve = function (value) {
  if (value && typeof value === "object" && value.constructor === Observer) {
    return value;
  }

  return new Observer(function (resolve) {
    resolve(value);
  });
};

Observer.reject = function (value) {
  return new Observer(function (resolve, reject) {
    reject(value);
  });
};

Observer.race = function (values) {
  return new Observer(function (resolve, reject) {
    for (let i = 0, len = values.length; i < len; i++) {
      values[i].then(resolve, reject);
    }
  });
};

Observer._immediateFn = fn => fn()

/**
 * 存在未被catch的错误的时候，进行warn
 * @param err
 * @private
 */
Observer._unhandledRejectionFn = function _unhandledRejectionFn (err) {
  if (typeof console !== "undefined" && console) {
    console.warn("Possible Unhandled Observer Rejection:", err); // eslint-disable-line no-console
  }
};

export default Observer;

import util from '@/util/util';
import { checkEnv } from '@/util/browser-env';
import { serverDocument, serverWindow } from '@/util/env';

const document = serverDocument;
const window = serverWindow;

export default {
  namespaced: true,
  state: {
    headers: {
      device: (checkEnv().ios) ? 'ios' : 'and',
      openid: util.cookie.get('openId') || '',
      acct_type: util.cookie.get('loginType') || '',
      appid: util.cookie.get('appId') || '',
      access_token: util.cookie.get('accessToken') || '',
      commReq: util.cookie.get('commReq') || '',
      cookie: document.cookie,
      userAgent: window.navigator.userAgent,
    },
    /**
     * 判断是否页面在后台
     * webbundle会先把组件渲染，进行重用的时候不销毁组件
     * 通过这里判断页面状态，进行生命周期的处理
     */
    isInBackground: false,
  },
  getters: {
    gamePayLoginParams(state) {
      const loginType = state.headers.acct_type;
      const isLoginTypeWX = loginType === '1';
      const appid = isLoginTypeWX  ? 'wxAppid2' : 'qq_appid';
      return {
        openid: state.headers.openid,
        openkey: state.headers.access_token,
        sessionid: isLoginTypeWX ? 'hy_gameid' : 'openid',
        sessiontype: isLoginTypeWX  ? 'wc_actoken' : 'kp_accesstoken',
        [appid]: state.headers.appid,
      };
    },
  },
  mutations: {
    setAppHeaders(state) {
      state.headers = {
        device: (checkEnv().ios) ? 'ios' : 'and',
        openid: util.cookie.get('openId') || '',
        acct_type: util.cookie.get('loginType') || '',
        appid: util.cookie.get('appId') || '',
        access_token: util.cookie.get('accessToken') || '',
        commReq: util.cookie.get('commReq') || '',
        cookie: document.cookie,
        userAgent: navigator.userAgent,
      };
    },
    setIsInBackground(state, isInBackground) {
      state.isInBackground = isInBackground;
    },
    resetState(state) {
      state.headers = {
        device: (checkEnv().ios) ? 'ios' : 'and',
        openid: util.cookie.get('openId') || '',
        acct_type: util.cookie.get('loginType') || '',
        appid: util.cookie.get('appId') || '',
        access_token: util.cookie.get('accessToken') || '',
        commReq: util.cookie.get('commReq') || '',
        cookie: document.cookie,
        userAgent: navigator.userAgent,
      };
    },
  },
};

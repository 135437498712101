/**
 * 注册 jsBridge
 */
import { humps, setTitle } from '@/util/util';
import { checkEnv } from '@/util/browser-env';
import launchApp from '@/util/launchApp';
import jsbridge from './package/src/index';
import { logger } from '@/util/logger';

function getJsBridge() {
  // if (window.jsbridge) {
  //   return Promise.resolve(window.jsbridge);
  // }
  // return new Promise((resolve) => {
  //   document.addEventListener('jsbridgesdkinstalled', () => {
  //     resolve(window.jsbridge);
  //   });
  // });
  return Promise.resolve(jsbridge);
}

const checkEnvObj = checkEnv();

/**
 * 触发分享弹窗
 * @param params
 * @param params.title 分享标题
 * @param params.summary  分享内容
 * @param params.targetUrl 分享链接
 * @param params.thumbUrl 缩略图
 * @return {Promise}
 */
export function showShareDialog(params) {
  return getJsBridge().then(jsBridge => jsBridge.share.showShareDialog(params));
}

export function showShareDialogV2(params) {
  // 对 ios中postInfo结构做兼容
  if (checkEnvObj.ios && params.postInfo) {
    const { postInfo } = params;
    /*
      传入的 postInfo
      postId: this.postId,
      postType: this.postType,
      postCreatorId: this.postInfo.creatorId,
      groupId: this.postInfo.groupId,
      action: (this.postAction && this.postAction.ifFollow) ? 'follow' : 'unfollow',
      userId: (this.userInfo && this.userInfo.uid) || 0,
    */
    const aPostInfo = {
      ...params.postInfo,
      id: postInfo.postId,
      author: {
        uid: postInfo.userId,
      },
      group: {
        id: postInfo.groupId,
      },
      type: postInfo.postType,
    };
    // eslint-disable-next-line no-param-reassign
    params.postInfo = aPostInfo;
  }
  return getJsBridge().then(jsBridge => jsBridge.share.showShareDialogV2(params));
}

/**
 * 打开对应的终端页面
 * @param {object} params
 * @param {String} [params.name] 请求的页面的name
 * @param {string} params.options  请求的页面的option
 * @return {Promise}
 */
export function openView(params) {
  return getJsBridge().then(jsBridge => jsBridge.ui.openView(params));
}
/**
 * 提交评论
 * @param {Object} params
 * @param {Number} params.type 评论类型 - 1:问题回答 2:一级评论 3:二级评论
 * @param {Number} params.parentId 父节点ID 一级评论时为帖子id，二级评论时为一级评论id
 * @param {Number} params.commentatorId 被评论者id
 * @param {Number} params.replyId 被回复的二级评论id
 * @param {Number} params.attribute 评论属性 1:普通评论 2:作者评论 3:官方评论
 * @param {String} params.nickname 被评论者头像
 * @param {String} params.content 被评论的内容 - 用于输入框展示
 * @param {Number} params.rootId 根节点 id
 * @param {Number} params.groupId 圈子id
 */
export function postComment(params) {
  // console.log('postComment', params);
  return getJsBridge()
    .then(jsBridge => jsBridge.user.postComment({
      data: {
        type: params.type,
        parent_id: params.parentId || '0',
        commentator_id: params.commentatorId || '0',
        reply_id: params.replyId || '0',
        attribute: params.attribute,
        status: 1, // 1:正常，2:待审核，3:审核通过，4:审核驳回...
        content: params.content || '',
        nickname: params.nickname || '',
        root_id: params.rootId || '0',
        group_id: params.groupId,
        commentPrefix: params.commentPrefix || '',
      },
    }))
    .then((res) => {
      if (res.result === 0) {
        if (res.data.data) {
          return Promise.resolve(humps.camelizeKeys(res.data.data));
        }
        return Promise.reject(res);
      }
      return Promise.reject(res);
    });
}

/**
 * 获取用户信息
 */
export function getUserInfo() {
  return getJsBridge().then(jsBridge => jsBridge.user.getUserInfo().then((res) => {
    // console.log(`用户信息：${JSON.stringify(res, null, 4)}`);
    if (res.result === 0) {
      const info = humps.camelizeKeys(res.data);
      return {
        ...info,
        iconUrl: info.iconUrl || info.faceUrl,
        phone: info.phone || info.phoneNumber,
      };
    }
    // 未登录
    return Promise.reject(res);
  }));
}

/**
 * 设置右上按钮
 */
export function setRightButton(params) {
  return getJsBridge().then(jsBridge => jsBridge.ui.setRightButton(params));
  // return jsbridge.ui.setRightButton(params);
}

/**
 * 处理登录结果
 * */
function handleLoginResult(res) {
  if (res.result === 0) {
    const info = humps.camelizeKeys(res.data);
    return {
      ...info,
      iconUrl: info.iconUrl || info.faceUrl,
      phone: info.phone || info.phoneNumber,
    };
  }
  return Promise.reject(res);
}

/**
 * 登录
 */
export function login(params) {
  return getJsBridge().then(jsBridge => jsBridge.user.login(params).then(res => handleLoginResult(res)));
}

/**
 * 登录QQ
 */
export function loginQQ(params) {
  return getJsBridge().then(jsBridge => jsBridge.user.loginQQ(params).then(res => handleLoginResult(res)));
}

/**
 * 注销账户
 * */
export function deleteAccount(params) {
  return getJsBridge().then(jsBridge => jsBridge.user.deleteAccount(params));
}

/**
 * 提交回答
 */
export function postAnswer(params) {
  return getJsBridge().then(jsBridge => jsBridge.user.postAnswer(params).then((res) => {
    // console.log('jsBridge postAnswer', JSON.stringify(res));
    // 返回的数据结构应为
    // {result: 0, data: { ret: 0, post: {} }}
    if (res.result === 0) {
      return Promise.resolve(humps.camelizeKeys(res.data.post));
    }
    return Promise.reject(res);
  }));
}

/**
 * 跳转链接
 */
export function openUrl(params) {
  return getJsBridge().then(jsBridge => jsBridge.ui.openUrl(params));
}

/**
 * 关闭当前页面
 */
export function popBack() {
  return getJsBridge().then(jsBridge => jsBridge.ui.popBack());
}

/**
 * 关闭当前页面
 */
export function closeWebViews() {
  return getJsBridge().then(jsBridge => jsBridge.ui.popBack());
}

/**
 * 下载app
 */
export function downloadApp(params) {
  return getJsBridge().then(jsBridge => jsBridge.app.downloadApp(params));
}

/**
 * 发送消息到终端
 * @param {Object} params
 * @param {String} params.noticeId 通知业务id
 * @param {String} params.noticeEvent 通知事件
 * @param {String} params.noticeParams 通知参数
 */
export function send(params) {
  return jsbridge.notice.send({
    noticeId: params.noticeId,
    noticeEvent: params.noticeEvent,
    noticeParams: JSON.stringify(params.noticeParams),
  });
}

/**
 * 发送消息到终端
 * @param {Object} params
 * @param {String} params.noticeId 通知业务id
 * @param {String} params.noticeEvent 通知事件
 */
export function register(params) {
  return jsbridge.notice.register(params);
}

/**
 * 设置webview title
 * @param {String} title 标题
 */
export function setWebTitle(title) {
  if (checkEnvObj.isApp && checkEnvObj.android) {
    jsbridge.ui.setWebTitle({
      title,
    });
  }
  setTitle(title);
}

/**
 * 获取上报接口公共参数
 */
export function getReportInfo(params) {
  return jsbridge.data.getReportInfo(params);
}

/**
 * 显示积分任务完成弹窗
 *
 * @function showTaskToast
 * @param {Object} params
 * @param {String} params.title 页面title
 * @param {Number} params.integral 积分数
 * @param {Number} params.experience 经验值
 * @param {String} params.addition 额外信息
 * {"title":"标题","integral": 100, "experience": 100, "addition": "额外信息"}
 */
export function showTaskToast(params) {
  return jsbridge.ui.showTaskToast(params);
}

/**
 * 图片预览能力
 *
 * @function imagePreview
 * @param {Object} params
 * @param {Number} params.index 初始化打开的图片下标
 * @param {Array} params.images 图片集
 * [{"url":"https://xxx.png","height":"512","width":"768"},]//height 和width不是必须
 */
export function imagePreview(params) {
  return jsbridge.ui.imagePreview(params);
}

/**
 * app内通用存储接口
 *
 * @function curdData
 * @param {Object} params
 * @param {String} params.key 键名
 * @param {String} [params.value] 键值 注意 必须是字符串
 * @param {Number} params.action 1 查询 2 删除 3 存储
 * @param {Number} params.user_related 0 用户相关 1 用户不相关
 * @param {Number} params.level 存储方式，0,原来的SP存储。1，Disk存文件，优先的memory中找。2，memory存储
 * @example
 * curdData({"key":"testKey","value": "testValue", "action": 3, "user_related": 0"})
 * @return {Object} Promise
 */
export function curdData(params) {
  // TODO 安卓上如果是json字符串会自动转为json对象，考虑是否做统一兼容处理

  return jsbridge.data.curdData(params).then((res) => {
    if (res.data && typeof res.data === 'object' && checkEnvObj.android) {
      res.data = JSON.stringify(res.data);
    }
    return Promise.resolve(res);
  });
}

/**
 * 通过webbundle打开链接
 * @param {String} url 跳转链接
 * @param {Object} data 传递数据
 */
export function openWithWebbundle(url, data) {
  if (checkEnvObj.ios && window.jsbridge && window.jsbridge.tgbiz) {
    return window.jsbridge.tgbiz('pushPostDetail', [url, data]);
  } if (checkEnvObj.android && window.jsbridge.openPreloadView) {
    const params = JSON.stringify({ url, preloadData: JSON.stringify(data) });
    logger.info('安卓 webbundle打开', params);
    return window.jsbridge.openPreloadView(params);
  }
  return openUrl({
    url,
    target: 1,
  });
}

/**
 * 跳转链接
 * @param {String} url 跳转链接
 */
export function pushView(url) {
  if (checkEnvObj.isApp) {
    if (/^tgc/.test(url)) {
      return openView({ options: url });
    }
    return openUrl({
      url,
      target: 1,
    });
  }
  if (/^tgc/.test(url)) {
    return launchApp({ scheme: url });
  }
  window.location.href = url;
  return undefined;
}

/**
 * 发送全局通信事件
 * @param {String} noticeEvent 事件名
 * @param {String} noticeParams 参数
 */
export function sendGlobalNotification(noticeEvent, noticeParams) {
  return jsbridge.notice.send({
    noticeId: 'GlobalNotification',
    noticeEvent,
    noticeParams: JSON.stringify(noticeParams),
  });
}

/**
 * 监听全局通信事件
 * @param {String} noticeEvent 事件名
 * @param {String} noticeParams 参数
 */
export function receiveGlobalNotification(noticeEvent, cb) {
  return jsbridge.notice.register({
    noticeId: 'GlobalNotification',
    noticeEvent,
    callback(params) {
      if (checkEnvObj.ios) {
        cb(params.data && params.data.params);
      } else {
        cb(params);
      }
    },
  });
}

/**
 * 调用终端接口进行数据上报
 * 1.3.1新增
 * @param {Object} params
 */
export function reportData(params) {
  try {
    window.jsbridge.reportData(JSON.stringify(params));
  } catch (error) {
    logger.error('调用终端上报失败', error);
  }
}

export default jsbridge;

import jsbridge from '../../libs/jsbridge'
import { setRightButton } from '../ui/setRightButton';

/**
 * @namespace share
 */

/**
 * 
 * 
 * @typedef {Object} showShareDialogCallbackParams
 * @description 点击了分享面板里面的按钮进行回调的参数
 * @memberof share
 * @property {Number} result 状态，0=成功，其他为失败。注意
 * @property {Object} data
 * @property {String} data.type 分享面板的按钮。WXSession=微信列表,WXTimeline=朋友圈,QQFriend=qq列表,QQZone=空间
 */

/**
 * 触发分享弹窗
 *  注意showShareDialog会直接触发分享弹窗，如果要设置右上角的分享按钮，可以用setShareInfo
 * 
 * @function showShareDialog
 * @memberof share
 * 
 * @param {Object} params
 * @param {String} params.title 分享内容
 * @param {String} params.summary  分享内容摘要
 * @param {String} params.targetUrl 分享链接
 * @param {String} params.thumbUrl 缩略图
 * @param {Boolean} [params.shareVisible] （社区帖子详情页专用）是否展示分享面板。0=不展示，1=展示。默认0。
 * @param {Array<Object>} [prams.action] （社区帖子详情页专用）面板操作栏展示按钮。
 * @param {Object} [params.postInfo] （社区帖子详情页专用）社区帖子专用
 * @param {String} params.postInfo.postId 帖子或问题或答案 id
 * @param {Number} params.postInfo.postType 帖子类型 1普通帖子、2问题帖子、3答案
 * @param {String} params.postInfo.groupId 帖子所属圈子Id
 * @param {String} params.postInfo.postCreatorId 发帖人id
 * @param {String} params.postInfo.userId 当前用户id
 * @param {String} params.postInfo.action 收藏状态，follow 或 unfollow
 * @returns {Promise<showShareDialogCallbackParams>}
 * 
 * @example
 * ```
 * jsbridge.share.showShareDialog({
      shareVisible: 1, // 0 分享不可见， 1 可见
      title: '腾讯官方游戏社区',
      summary: '来自闪现一下社区，来闪现，从不落单~',
      targetUrl: window.location.href,
      thumbUrl: 'https://gameplus-1258344700.cos.ap-shanghai.myqcloud.com/business/Share/iOSAPPicon100.png',
      action: 'follow',
      postInfo: { // 帖子专用，可不填
        postId: this.postId,
        postType: this.postType,
        postCreatorId: this.postInfo.creatorId,
        groupId: this.postInfo.groupId,
        action: (this.postAction && this.postAction.ifFollow) ? 'follow' : 'unfollow',
        userId: (this.userInfo && this.userInfo.uid) || 0,
      },
 * })
 * .then((res) => {
 *    if (res.result === 0) {
 *       alert('分享成功 ' + res.data.type)
 *    }
 * })
 * ```
 */
export function showShareDialog (params = {}) {
  return jsbridge({
    url: 'share/showShareDialog',
    params
  }, {
    callbackType: 'observer',
    customCallback: true,
  })
}


/**
 * 设置右上角分享按钮
 * 参数同showShareDialog，但是这个是直接设置
 * @function setShareInfo
 * @memberof share
 * 
 * @param {Object} params
 * @param {String} params.title 分享内容
 * @param {String} params.summary  分享内容摘要
 * @param {String} params.targetUrl 分享链接
 * @param {String} params.thumbUrl 缩略图
 * @param {Boolean} [params.shareVisible] （社区帖子详情页专用）是否展示分享面板。0=不展示，1=展示。默认0。
 * @param {Array<Object>} [prams.action] （社区帖子详情页专用）面板操作栏展示按钮。
 * @param {Object} [params.postInfo] （社区帖子详情页专用）社区帖子专用
 * @param {String} params.postInfo.postId 帖子或问题或答案 id
 * @param {Number} params.postInfo.postType 帖子类型 1普通帖子、2问题帖子、3答案
 * @param {String} params.postInfo.groupId 帖子所属圈子Id
 * @param {String} params.postInfo.postCreatorId 发帖人id
 * @param {String} params.postInfo.userId 当前用户id
 * @param {String} params.postInfo.action 收藏状态，follow 或 unfollow
 * @returns {Promise<showShareDialogCallbackParams>}
 * 
 * @example
 * ```
 * jsbridge.share.setShareInfo({
      shareVisible: 1, // 0 分享不可见， 1 可见
      title: '腾讯官方游戏社区',
      summary: '来自闪现一下社区，来闪现，从不落单~',
      targetUrl: window.location.href,
      thumbUrl: 'https://gameplus-1258344700.cos.ap-shanghai.myqcloud.com/business/Share/iOSAPPicon100.png',
 * })
 * .then((res) => {
 *    if (res.result === 0) {
 *       alert('分享成功 ' + res.data.type)
 *    }
 * })
 * ```
 */
export function setShareInfo (params = {}) {
  return setRightButton().then(function () {
    return jsbridge({
      url: 'share/showShareDialog',
      params
    }, {
      callbackType: 'observer',
      customCallback: true,
    })
  })
}


/**
 * 拉起客户端分享面板（新，支持分享图片）
 * v1.7 版本增加
*  @function showShareDialogV2
 * @memberof share
 *
 * @param {Object} params
 * @param {int} [params.shareType=5] 1-文本 2-图像 3-音乐 4-视频 5-网页 6-小程序 7-结构化消息。暂时只支持2、5。
 * @param {Array.<Object>} params.action 可选 第二行图标
 {
            type: 'custom', custom-自定义图标  还可使用预埋的客户端能力如 copyLink-复制
            终端预埋能力follow accuse copyLink delete block imageLocal tgFriend(闪现私信)
            title: '哈哈哈', 自定义图标名称
            icon: decodeURIComponent('https://static.gameplus.qq.com/img/1587977266-9645'), 自定义图标
            selectedIcon:  decodeURIComponent('https://static.gameplus.qq.com/img/1587977266-9645'), 自定义图标选中态
            id: 1, 回调时吐出的id
            native: 0, 是否直接使用终端能力
          },
 * @param {String} params.iconUrl 可选 shareType为2时分享的网络图片链接
 * @param {String} params.title   可选 shareType为5时分享的标题
 * @param {String} params.summary 可选 shareType为5时分享的主要信息
 * @param {String} params.targetUrl 可选 shareType为5时分享的目标网址
 * @param {String} params.thumbUrl 可选 shareType为5时分享的小图标
 *
 * @returns {Promise.<resp>}
 * 
 * @example
 * ```
 * // 分享图片
 * jsbridge.share.showShareDialogV2({
 *   shareType: 2,
 *   iconUrl: 'https://static.gameplus.qq.com/business/Share/iOSAPPicon100.png',
 * });
 * 
 * // 分享链接
 * jsbridge.share.showShareDialogV2({
 *   shareType: 5,
 *   targetUrl: 'https://www.qq.com/',
 *   title: '测试分享标题',
 *   summary: '这是一个分享',
 *   thumbUrl: 'https://static.gameplus.qq.com/business/Share/iOSAPPicon100.png',
 * }).then((res) => {
 *   console.log('已分享到' + res.type)
 * })
 * ```
 *
 */
export function showShareDialogV2 (params) {

  if (params.shareType === 2) {
    // 安卓实际用的是thumbUrl，这里做个兼容
    params.thumbUrl = params.iconUrl || params.thumbUrl;
  }

  return jsbridge({
    url: "share/showShareDialogV2",
    params
  });
}



/**
 * 直接分享，拉起分享渠道
 * v1.7新增
 * 
*  @function shareMessage
 * @memberof share
 *
 * @param {Object} params
 * @param {String} params.shareAction 必填 分享渠道
 *   tgFriend-闪现私信 WXSession-微信好友 WXTimeline-微信朋友圈 QQFriend-QQ好友 QQZone-QQ空间
 * @param {int} params.shareType=5 可选，默认为5 1-文本 2-图像 3-音乐 4-视频 5-网页 6-小程序 7-结构化消息
 * @param {String} params.iconUrl 可选 shareType为2时分享的网络图片链接
 * @param {String} params.title   可选 shareType为5时分享的标题
 * @param {String} params.summary 可选 shareType为5时分享的主要信息
 * @param {String} params.targetUrl 可选 shareType为5时分享的目标网址
 * @param {String} params.thumbUrl 可选 shareType为5时分享的小图标
 * @param {String} params.appIcon 可选 shareType为5时 游戏小图标
 * @param {String} params.appName 可选 shareType为5时 游戏名称
 * @param {int} params.uid 可选 shareType为7时， 如果传了uid，则调过选择好友阶段，结构化消息直接发送给指定用户
 * @param {Object} params.structureMessage shareType为7时，结构化消息内容
 * @param {int} params.structureMessage.type 结构化消息样式类型 1: 两个按钮的邀请消息
 * @param {String} params.structureMessage.bgImg 构化消息 背景图
 * @param {String} params.structureMessage.avatar 构化消息 头像url
 * @param {String} params.structureMessage.nickname 构化消息 用户昵称
 * @param {String} params.structureMessage.sex 构化消息 用户性别 1 男性 2 女性
 * @param {String} params.structureMessage.desc 构化消息 角色描述信息
 * @param {String} params.structureMessage.summary 构化消息 内容
 * @param {String} params.structureMessage.appIcon 构化消息 游戏小图标
 * @param {String} params.structureMessage.appName 构化消息 游戏名称
 * @param {Array} params.structureMessage.action 构化消息 按钮列表
 * @param {String} params.structureMessage.action[i].bgImg 构化消息 按钮背景图
 * @param {String} params.structureMessage.action[i].text 构化消息 按钮文案
 * @param {String} params.structureMessage.action[i].color 构化消息 按钮颜色
 * @param {String} params.structureMessage.action[i].url 构化消息 按钮跳转url
 * @returns {Promise.<resp>} 分享回调
 */
export function shareMessage (params) {
  return jsbridge({
    url: "share/shareMessage",
    params
  });
}
/**
 * isWeex
 * @author donaldcen
 * @createTime 2017/9/19
 */
export default function () {
  try {
    return (/^(android|ios)$/i).test(weex.config.env.platform)
  } catch (e) {
    return false
  }
}
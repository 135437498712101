/**
 * createJSAPI
 * @author donaldcen
 * @createTime 2017/9/24
 */
import formatSchema from "./formatSchema";

export default function (data){
  let schemaURL = "";
  // 如果传入的时schema协议直接请求
  if (typeof data === "string") {
    schemaURL = data;
  } else {
    //处理参数
    let params = encodeURIComponent(JSON.stringify(data.params || {})); // 处理空

    // 终端参数为p
    schemaURL = formatSchema(
      Object.assign({}, data, {
        url: data.url,
        params: {p: params}
      })
    );
  }
  return schemaURL;
}

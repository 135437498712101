/**
 * getChannelName
 * @author marvinqi
 * @createTime 2017/9/20
 */
import getUA from './getUA'

export default function () {
  let m = getUA().match(/ChannelName\/([\w\-\.]+)/i)
  return (m ? m[1] : null) || ''
}
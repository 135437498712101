export default function () {
  let userAgent = ''
  if (typeof weex === 'object' && weex.config) {
    userAgent = weex.config.userAgent || (weex.config.env && weex.config.env.userAgent) || ''
  } else if (typeof window === 'object' && window.navigator) {
    userAgent = window.navigator.userAgent || ''
  } else if (typeof context !== 'undefined' && context.window) {
    userAgent = context.window.request.headers['user-agent'] || ''
  }
  return String(userAgent).toLowerCase()
}
/**
 * getProtocol 获取协议头
 * @author donaldcen
 * @createTime 2017/10/17
 */

const HTTPS = 'https:'
const HTTP = 'http:'
const DEFAULT_PROTOCOL = HTTP
export default function () {
  if (typeof weex === 'object' && weex.config && weex.config.bundleUrl) {
    return weex.config.bundleUrl.indexOf('https:') < 0 ? HTTP : HTTPS
  } else if (typeof window === 'object' && window.location && window.location.protocol) {
    return window.location.protocol
  }
  return DEFAULT_PROTOCOL
}
import jsbridge from '../../libs/jsbridge';

/**
 * 通过终端获取红点树的信息
 *
 * @function queryRedDotTree
 * @memberof data
 *
 * @param {Object} params
 * @param {String} nodeId 红点节点Id
 * @param {Number} depth 红点树层级，默认: 0(depth为0时，默认返回整棵树，为1时返回自己)
 * @example
 * queryRedDotTree({"nodeId":"ID_GROUP_ID@3", "depth": 1})
 * @return {Object} Promise
 */
export function queryRedDotTree (params) {
  return jsbridge({
    url: 'redDot/queryRedDotTree',
    params: params
  });
}

/**
 * 通过终端点消红点树
 *
 * @function onRedDotClick
 * @memberof data
 *
 * @param {Object} params
 * @param {String} nodeId 红点节点Id
 * @example
 * onRedDotClick({"nodeId":"ID_GROUP_ID@3"})
 * @return {Object} Promise
 */
export function onRedDotClick (params) {
  return jsbridge({
    url: 'redDot/onRedDotClick',
    params: params
  });
}
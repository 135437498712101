/**
 * fnStore
 * @author donaldcen
 * @createTime 2017/9/24
 */
let SNMAP = {}
let SNLen = 1

/**
 * 创建回调id
 * @option {Function} FN 回调
 * @returns {string}
 */
export function createSN (FN) {
  let SN = '__PGG_CALLBACK_' + SNLen++
  SNMAP[SN] = FN || (() => {
  })
  return SN
}

/**
 * 触发回调
 * @param {String} SN 回调id
 * @param params
 */
export function fireFN (SN, params) {
  if (typeof SNMAP[SN] === 'function') {
    SNMAP[SN].apply(this, params)
  }
}

export function setFN (SN, fun) {
  SNMAP[SN] = fun
}

export function delFN (SN) {
  // SNMAP[SN] = null
}

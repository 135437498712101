/**
 * SSR代理请求的时候cookie转发
 */
import { serverDocument } from './env';

function requestMiddleWare(reqConfig) {
  // eslint-disable-next-line no-param-reassign
  reqConfig.headers.cookie = serverDocument.cookie; // 添加csrf
}

export default {
  request: requestMiddleWare,
};

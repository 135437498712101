/**
 * getOrientation
 * @author marvinqi
 * @createTime 2018/1/17
 */
/**
 *@desc  1竖 2横
 *@returns {string}
 */
import getUA from './getUA'

export default function () {
  return Number((new RegExp('orientation\\/(\\w*)').exec(getUA()) || ['', ''])[1]) || 1
}

/**
 * Created by homkerliu on 2017/12/11.
 */

import isWeex from './isWeex'
import getUA from './getUA'

function isIphonex (str){
  !(typeof str === 'string') && (str = String(str))

  str = str.toLowerCase()
  return str.indexOf('iphone10,3') > -1 || str.indexOf('iphone10,6') > -1
}
export default function () {
  try {
    if (isWeex()) { //weex使用weex.env.deviceModel的值来判断
      let deviceModel = String(weex.config.env.deviceModel || '')

      return isIphonex(deviceModel)
    } else { // web 使用ua来判断
      let ua = String(getUA() || '')

      return isIphonex(ua)
    }
  } catch (err) {
    return false
  }

}
import axios from 'axios';
import cookie from 'js-cookie';
import { Base64 } from 'js-base64';
import jsbridge from '@/jsbridge';
import compareVersion from '@/util/compareVersion';
import { checkEnv } from '../browser-env';
import { logger } from '@/util/logger';

const checkEnvObj = checkEnv();

/**
 * 检测请求白名单，针对部分请求启用签名
 * @param {Object} reqConfig 调用参数
 */
function checkSignWhiteList(reqConfig) {
  const path = reqConfig.url.split('?')[0];
  const whiteList = ['/community.PostSrv/PostAction',
    '/community.CommentSrv/DeleteComment',
    '/community.UserSrv/Follow',
    '/community.UserSrv/CancelFollow'];
  if (whiteList.includes(path)) {
    return true;
  }
  return false;
}

class HttpRequest {
  constructor(options) {
    this.config = {
      method: 'POST',
      timeout: 20000,
      withCredentials: true,
      proxy: process.env.VUE_APP_PUBLIC_ENV === 'local' ? false : undefined,
      xsrfCookieName: '',
      xsrfHeaderName: '',
      ...options,
    };
    this.instance = axios.create(this.config);
  }

  request(config) {
    if (config.mock) {
      return Promise.resolve(config.mock);
    }
    const options = {
      decamelizeRequest: false, // 请求参数变成下划线
      camelizeResponse: true, // 返回数据变成驼峰
      errorHandler: 'auto', // auto:自动处理错误提示，false: 不处理错误
      excludeRet: [], // 过滤不自动处理的错误
      ...config.options,
    };
    const reqConfig = {
      method: 'post',
      ...config,
      options,
    };
    if (reqConfig.method.toLowerCase() === 'get' && !reqConfig.params) {
      reqConfig.params = reqConfig.data;
    }

    // App内的post请求
    if (reqConfig.method.toLowerCase() === 'post' && checkEnvObj.isApp && compareVersion('1.2') > -1 && checkSignWhiteList(reqConfig)) {
      const urlArr = reqConfig.url.split('?');
      const reqPath = urlArr[0];
      const reqOriginParams = urlArr[1] || '';
      const me = this;
      // console.log('签名传参：', {
      //   path: reqPath,
      //   body: Base64.encode(JSON.stringify(reqConfig.data)),
      //   commReq: cookie.get('commReq'),
      // });

      try {
        return jsbridge.data.getReqQueryString({
          path: reqPath,
          body: Base64.encode(JSON.stringify(reqConfig.data)), // 这里方便终端做签名的数据处理，统一转base64
          commReq: cookie.get('commReq'),
        }).then((res) => {
          // console.log(`${reqPath} ｜ 签名调用耗时：${+new Date() - reqStartTime} | 回参：`, res.data);
          reqConfig.url += `${reqOriginParams === '' ? '?' : '&'}${res.data}`;
          return me.instance.request(reqConfig);
        });
      } catch (e) {
        logger.error('签名计算错误', e.message);
        return this.instance.request(reqConfig);
      }
    }
    return this.instance.request(reqConfig);
  }

  use(type, func) {
    if (type === 'request') {
      this.instance.interceptors.request.use((reqConfig) => {
        const result = func.call(this, reqConfig);
        return result || reqConfig;
      });
    } else if (type === 'response') {
      this.instance.interceptors.response.use((response) => {
        const result = func.call(this, response);
        return result || response;
      });
    } else if (type === 'error') {
      this.instance.interceptors.response.use(null, (error) => {
        const result = func.call(this, error);
        return result || error;
      });
    }
  }
}
export default HttpRequest;

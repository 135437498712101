/**
 * getAppVersionCode
 * @author marvinqi
 * @author sunnylin
 * @createTime 2017/9/20
 */
// import _platform from './platform'
import IsIos from './isIOS'
import getVersionName from './getAppVersion'

export default function () {
  const versionName = getVersionName()
  const nums = versionName.split('.')

  // IOS的version_code需要根据version_name 计算出来
  // 安卓的version_code取version_name的最后一位
  if(IsIos()){
    let code = nums[3] | (nums[2] << 8) | (nums[1] << 16) | (nums[0] << 24)

    return code
  }else{
    return Number(nums.pop())
  }
}
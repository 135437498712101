import util from '@/util/util';
import userApi from '@/api/user';
import { getUserInfo, login, loginQQ } from '@/jsbridge';
import { updateUserInfo as updateReportUserInfo, getCommonData } from '@/api/report';
import { logger } from '@/util/logger';
import { updateAegis } from '@/util/aegis';

export default {
  namespaced: true,
  state: {
    hasCheckUserInfo: false,
    userInfo: null,
    headersMap: {},
  },
  mutations: {
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
      updateReportUserInfo(userInfo);
      getCommonData();
      updateAegis(); // 登录变化后要更新用户信息
    },
    resetState(state) {
      state.userInfo = null;
    },
  },
  actions: {
    getUserInfo({ state, commit, rootState }) {
      if (state.userInfo && Number(state.userInfo.uid) > 0) {
        return Promise.resolve();
      }
      if (rootState.isApp) {
        if (process.env.NODE_ENV === 'development' && window.location.search.indexOf('testUser=1') > -1) {
          util.cookie.set('appId', 'wxd35a5b0c66e8b683', { domain: 'qq.com' });
          util.cookie.set('accessToken', '33_jc3MyR0px70cFt7NYZyqVhrEXr9fW4S2xNQUUeSEfaI7obU1t3e6C4B0_oE9boIsoAFKHwtbkzxTqTlaBe1-PabtwcwUtFLU79U48smbp8Q', {
            domain: 'qq.com',
          });
          util.cookie.set('openId', 'oDHTRvrVmvHc7P3jlhc8YwAvC3x0', {
            domain: 'qq.com',
          });
          util.cookie.set('uid', '100369339', {
            domain: 'qq.com',
          });
          // util.cookie.set('loginType', '2', {
          //   domain: 'qq.com',
          // });
          return userApi.getUserLoginInfo({
            uid: 100369339,
          })
            .then((res) => {
              if (res.userInfo && res.ret === 0) {
                commit('setUserInfo', res.userInfo);
              }
            }, () => {
              commit('setUserInfo', null);
            });
        }
        if (process.env.NODE_ENV === 'development' && window.location.search.indexOf('testUser=0') > -1) {
          commit('setUserInfo', null);
          return Promise.resolve();
        }
        return getUserInfo()
          .then((data) => {
            logger.info('获取用户信息', data);
            if (Number(data.uid) > 0) {
              commit('setUserInfo', {
                ...data,
                description: '',
              });
              // 重新从终端获取header信息
              commit('app/setAppHeaders', null, { root: true });
            } else {
              commit('setUserInfo', null);
            }
          }, () => {
            // 未登录
            commit('setUserInfo', null);
          });
      }
      commit('setUserInfo', null);
      return Promise.resolve();
    },
    /**
     * 登录
     * @param Object param
     * @param String type 登录类型，默认可选（仅支持default和QQ类型）
     * */
    login({ commit }, { type = 'default' } = {}) {
      if (process.env.NODE_ENV === 'development' && window.location.search.indexOf('testUser=0') > -1) {
        util.cookie.set('appId', 'wxd35a5b0c66e8b683', {
          domain: 'qq.com',
        });
        util.cookie.set('accessToken', '33_jc3MyR0px70cFt7NYZyqVhrEXr9fW4S2xNQUUeSEfaI7obU1t3e6C4B0_oE9boIsoAFKHwtbkzxTqTlaBe1-PabtwcwUtFLU79U48smbp8Q', {
          domain: 'qq.com',
        });
        util.cookie.set('openId', 'oDHTRvrVmvHc7P3jlhc8YwAvC3x0', {
          domain: 'qq.com',
        });
        util.cookie.set('uid', '100369339', {
          domain: 'qq.com',
        });
        // util.cookie.set('loginType', '2', {
        //   domain: 'qq.com',
        // });
        return userApi.getUserLoginInfo({
          uid: 100369339,
        })
          .then((res) => {
            if (res.userInfo && res.ret === 0) {
              commit('setUserInfo', res.userInfo);
            }
          }, () => {
            commit('setUserInfo', null);
          });
      }
      return (type === 'QQ' ? loginQQ : login)()
        .then((data) => {
          if (Number(data.uid) > 0) {
            commit('setUserInfo', {
              ...data,
              description: '',
            });
            commit('app/setAppHeaders', null, { root: true });
          } else {
            logger.info('登录失败');
            return Promise.resolve();
          }
          logger.info(`登录成功: ${JSON.stringify(data, null, 4)}`);
          return Promise.resolve();
        })
        .catch(() => {
          logger.info('登录失败');
          return Promise.resolve();
        });
    },
  },
  getters: {
    userInfo(state) {
      return state.userInfo;
    },
  },

};

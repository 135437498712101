
import jsbridge from "../../libs/jsbridge";

/**
 * 获取用户信息
 * 
 * @function postAnswer
 * @memberof user
 *
 * @param {any} params
 * @returns {Promise<any>}
 * 
 * @example
 * postAnswer({
 *   postId: '6741779479007187',
 *   groupId: '13',
 *   title: '测试哈哈哈'
 * })
 * // 返回数据
 * {
 *   "result": 0,
 *   "data": {
 *     // 透传服务端返回的Post实体
 *   },
 *   "msg": "" // 如果有错误信息
 * }
 */
export function postAnswer(params) {
  return jsbridge({url: "user/postAnswer", params: params});
}

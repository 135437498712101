/**
 * 用户模块相关API
 * */
import { gameplusApi } from '@/util/http';

export default {
  /**
   * 获取反馈类型
   * */
  getFeedbackType() {
    return gameplusApi.request({
      method: 'post',
      url: '/community.GCBaseSrv/FeedbackType',
    }).then((res) => {
      if (res.ret === 0) return res.typeList;
      throw new Error(`Get api community.GCBaseSrv/FeedbackType fail, ret:${res.ret}`);
    });
  },
};

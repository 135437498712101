import jsbridge from "../../libs/jsbridge";
import {isObject, isString} from "../../libs/typeChecker";
import {
    checkCommand,
    checkPass,
    hasKeys, paramsIsObject,
    validator
} from "../../utils/verifyHelper";

/**
 * 打开对应的终端页面
 * 
 * @function openView
 * @memberof ui
 * 
 * @param {object} params
 * @param {String} [params.name] 页面的名字，没啥用不传
 * @param {string} params.options  终端页面的 schema
 * @returns {Promise<any>}
 * 
 * @example
 * openView({
 *    options: `tgc://groupdetail?groupId=${this.groupInfo.id}`,
 * }).then((res) => {
 *    console.log(res);
 * });
 */
export function openView(params) {
    let checkResult = checkPass(
        checkCommand(
            paramsIsObject('openView'),
            hasKeys("options"),
            validator("opeView params.options必须为string", isString, "options")
        )(params)
    );
    if (checkResult) {
        return Promise.reject(checkResult);
    }
    return jsbridge({
        url: "ui/openView",
        params
    });
}
//jsbridge://ui/openView?p={"options":"qgameapi://video/room?aid=80290445&_bpname=live&_webLaunchTime=1498555512585"}

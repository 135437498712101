/**
 * getUrlParams
 * @author marvinqi
 * @createTime 2017/9/20
 */
import url2Object from '../../../utils/url2Object'

export default function (key) {
  let urlObj = url2Object(location.search.replace('?', '')) || {}
  return key ? urlObj[key] : urlObj
}
/**
 * compareVersion
 * @author marvinqi
 * @createTime 2017/9/20
 */
import _platform from './platform'

export default function (a, b) {
  let i, l, r, len
  //安卓的版本号为2.1.1.118_118
  a = String((a || '').replace('_', '.')).split('.')
  b = b || _platform('com.tencent.qgame/')//如果b不传的话就是当前的版本号
  b = String((b || '').replace('_', '.')).split('.')

  for (i = 0, len = Math.max(a.length, b.length); i < len; i++) {
    l = (isFinite(a[i]) && Number(a[i])) || 0
    r = (isFinite(b[i]) && Number(b[i])) || 0
    if (l < r) {
      return -1
    } else if (l > r) {
      return 1
    }
  }
  return 0
}
import querystring from 'querystring';
import { checkEnv } from './browser-env';
import { serverWindow } from '@/util/env';

const window = serverWindow;

const checkEnvObj = checkEnv();

const API_HOST_DEV = 'devapi.gameplus.qq.com';
export const API_HOST_TEST = 'ams-pre.xinyue.qq.com';
const API_HOST_PRESSURE = 'ycapi.gameplus.qq.com';
const API_HOST_PROD = 'api.gameplus.qq.com';
const API_HOST_REVIEW = 'reviewapi.gameplus.qq.com';


const directUrlList = [
  /qq\.com\/community\/(post-detail)\/?\?(.+)/,
  /qq\.com\/community\/(post-video)\/?\?(.+)/,
  /qq\.com\/community\/(answer-detail)\/?\?(.+)/,
  /qq\.com\/community\/(question-detail)\/?\?(.+)/,
];

/**
 * 获取直出链接
 * @deprecated 废弃，使用uni-open-url里的方法
 * @param {String} url 路径
 */
export function toDirectUrl(url = window.location.href) {
  // eslint-disable-next-line no-useless-escape
  for (let i = 0; i < directUrlList.length; i++) {
    if (directUrlList[i].test(url)) {
      return url.replace(directUrlList[i], 'qq.com/direct/pages/community/$1.html?$2');
    }
  }
  return url;
}


export default function () {
  let baseUrl = API_HOST_PROD;
  if (process.env.VUE_APP_PUBLIC_ENV === 'local') {
    baseUrl = API_HOST_TEST;
    // 根据网站域名判断
    const host = window.location.hostname;
    if (host === 'mtest.gameplus.qq.com') {
      baseUrl = API_HOST_TEST;
    } else if (host === 'm.gameplus.qq.com') {
      baseUrl = API_HOST_PROD;
    }
  } else if (process.env.VUE_APP_PUBLIC_ENV === 'pre') {
    baseUrl = API_HOST_TEST;
  } else {
    baseUrl = API_HOST_PROD;
  }
  // APP 环境下根据ua判断
  if (checkEnvObj.isApp) {
    if (checkEnvObj.isDebug) {
      baseUrl = API_HOST_DEV;
    } else if (checkEnvObj.isTest) {
      baseUrl = API_HOST_TEST;
    } else if (checkEnvObj.isPressure) {
      baseUrl = API_HOST_PRESSURE;
    } else if (checkEnvObj.isProd) {
      baseUrl = API_HOST_PROD;
      if (checkEnvObj.isSwitchOn) {
        baseUrl = API_HOST_REVIEW;
      }
    }
  } else {
    const host = window.location.hostname;
    if (host === 'mtest.gameplus.qq.com') {
      baseUrl = API_HOST_TEST;
    } else if (host === 'm.gameplus.qq.com') {
      baseUrl = API_HOST_PROD;
    }
  }

  return `${window.location.protocol}//${baseUrl}`;
}

/**
 * 获取分享到外部的链接
 * @deprecated 废弃，使用uni-open-url里的方法
 * @param {String} url 路径
 */
export function getShareUrl(url) {
  if (url) {
    return toDirectUrl(url);
  }
  const {
    pathname, search, protocol, host,
  } = window.location;
  const searchObj = querystring.parse(search[0] === '?' ? search.slice(1) : search) || {};
  const DELETE_LIST = ['anchor', 'pageOpenType', 'pageFrom', 'openScene'];
  DELETE_LIST.forEach((item) => {
    delete searchObj[item];
  });
  const fullUrl = `${protocol}//${host}${pathname}?${querystring.stringify(searchObj)}`;
  return toDirectUrl(fullUrl);
}

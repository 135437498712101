/* eslint-disable no-param-reassign */
import { reportData } from '../../../utils/index';

/**
 * 点击数据上报指令
 * @param {*} Vue
 */
function clickReport(Vue) {
  return {
    bind: (el, binding) => {
      if (!binding) {
        return;
      }

      const onClick = () => {
        reportData(Vue, binding.value, 'click-report尚未配置reportData数据上报函数');
      };
      el.addEventListener('click', onClick, false);
      el.customOnClick = onClick;
    },
    unbind: (el) => {
      const onClick = el.customOnClick;
      el.removeEventListener('click', onClick, false);
      el.customOnClick = null;
    },
  };
}

export { clickReport };

/* eslint-disable no-underscore-dangle */

export default (function () {
  const promise = window.Promise;
  const STOP = {};
  const DONE = {};
  const STOP_PROMISE = promise.resolve(STOP);

  if (promise.prototype._use_polyfill) return;

  promise.prototype._use_polyfill = true;

  promise.prototype._then = promise.prototype.then; // 保存原本的then方法

  promise.prototype.finally = function (callback) {
    const P = this.constructor;
    return this.then(
      value => P.resolve(callback()).then(() => value),
      reason => P.resolve(callback()).then(() => {
        throw reason;
      }),
    );
  };

  promise.prototype.then = function (onResolved, onRejected) {
    return this._then((result) => {
      if (result === STOP) { // 停掉后面的Promise链回调
        return result;
      }
      return typeof onResolved === 'function' ? onResolved(result) : result;
    }, onRejected);
  };

  promise.prototype.done = function (cb) {
    return this.then((result) => {
      if (result && result.flag === DONE) {
        return cb(result.value);
      }
      return result;
    });
  };

  promise.stop = function () {
    return STOP_PROMISE;
  };
}());

import { emitEvent } from '@/util/vueComponetEvent';

export const SHOW_ACTION_EVENT = 'SHOW_ACTION_EVENT';

/**
 * 展示选择框
 * @param  {object} info
 * @param {string} info.title 标题
 * @param {string|Array<string>} info.desc 描述
 * @param {Array<object>} info.item 选项列表
 * @param {string} info.item[i].name 选项名称
 * @param {string} info.item[i].icon 选项icon,可选
 * @param {function} info.callback 回调
 * @return {object} res
 * @return {int} res.ret 0:成功 1:取消
 * @return {int} res.index 点击的选项index
 */
export function showActionSheet(info) {
  emitEvent(SHOW_ACTION_EVENT, info);
}


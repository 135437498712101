import jsbridge from '../../libs/jsbridge'

/**
 * @namespace notice
 */

/**
 * 监听终端事件
 * 
 * @function register
 * @memberof notice
 * 
 * @param params
 * @param {String} params.noticeId	通知业务id
 * @param {String} params.noticeEvent 通知事件名称
 * @returns {Promise}
 */
export function register (params = {}) {
  return jsbridge({
    url: 'notice/register',
    params
  }, {
    callbackType: 'observer',
    customCallback: true,
  })
}



/**
 * 发送事件到终端
 * 
 * @function send
 * @memberof notice
 * 
 * @param {Object} params
 * @param {String} params.noticeId 通知业务id
 * @param {String} params.noticeEvent 通知事件
 * @param {String} params.noticeParams 通知参数
 * @returns {Promise}
 */
export function send (params = {}) {
  return jsbridge({
    url: 'notice/send',
    params
  }, {
    callbackType: 'observer',
    customCallback: true,
  })
}

/* eslint-disable no-param-reassign */
import { reportData } from '../../../../utils/index';

/**
 * H5页面，获取当前组件是否在可视化曝光的区域内
 * - 曝光需要露出组件的1 / 2
 * @param {*} element 组件的DOM元素
 */
function getIsInView(element) {
  if (!element) {
    return false;
  }

  const {
    width, height, top, left,
  } = element.getBoundingClientRect();
  const halfWidth = width / 2;
  const halfHeight = height / 2;
  const clientWidth = document.documentElement.clientWidth || document.body.clientWidth;
  const clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
  // 曝光需要露出组件的1 / 2
  let isInVerticalView = (top >= 0) && (clientHeight - top >= halfHeight);
  isInVerticalView = isInVerticalView || ((top < 0) && (top + height >= halfHeight));
  let isInHorizontalView = (left >= 0) && (clientWidth - left >= halfWidth);
  isInHorizontalView = isInHorizontalView || ((left < 0) && (left + width > width / 2));

  return isInVerticalView && isInHorizontalView;
}

/**
 * H5页面，曝光数据上报指令
 * @param {*} Vue
 */
function exposureReportInH5(Vue) {
  return {
    inserted: (el, binding) => {
      if (!binding) {
        return;
      }
      if (getIsInView(el)) {
        reportData(Vue, binding.value);
        return;
      }

      const target = window;
      const onScroll = () => {
        if (getIsInView(el)) {
          reportData(Vue, binding.value);
          target.removeEventListener('scroll', onScroll, true);
        }
      };
      target.addEventListener('scroll', onScroll, true);
      el.customOnScroll = onScroll;
      el.customScrollTarget = target;
    },
    unbind: (el) => {
      if (!el.customScrollTarget) {
        return;
      }

      el.customScrollTarget.removeEventListener('scroll', el.customOnScroll, true);
      el.customOnScroll = null;
      el.customScrollTarget = null;
    },
  };
}

export { exposureReportInH5 };

'use strict';
/**
 * @fileoverview 数据相关的操作
 * @namespace device
 */
import jsbridge from '../../libs/jsbridge';

/**
 * 获取设备信息
 * @function getDeviceInfo
 * @memberof device
 * 
 * @warning 完成状态
 * 
 * @example
 * getDeviceInfo().then((res) => {
 *   console.log(res);
 * });
 */
export function getDeviceInfo () {
  return jsbridge({
    url: 'device/getDeviceInfo'
  }, {
    callbackType: 'observer',
    customCallback: true
  })
}

/**
 * 获取网络信息
 * @function getNetWorkInfo
 * @memberof device
 * 
 * @example
 * getNetWorkInfo().then((res) => {
 *   console.log(res);
 * });
 */
export function getNetworkStatus () {
  return jsbridge({
    url: "device/getNetworkStatus"
  });
}

/**
 * 判断App是否打开通知权限
 * @function getNotificationStatus
 * @memberof device
 * 
 * @example
 * getNotificationStatus().then((res) => {
 *   console.log(res);
 * });
 * // { result: 0, data: { status: 0 } }
 * // status:0=未打开,1=已打开
 * 
 * @returns {Promise}
 */
export function getNotificationStatus () {
  return jsbridge({
    url: "device/getNotificationStatus"
  });
}

/**
 * 打开系统通知权限设置
 * @function goToAppSystemSetting
 * @memberof device
 * 
 * @example
 * goToAppSystemSetting();
 */
export function goToAppSystemSetting () {
  return jsbridge({
    url: "device/goToAppSystemSetting"
  });
}
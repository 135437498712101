<template>
  <div
    id="app"
    :class="{
      'platform-android': isAndroid,
      'platform-ios': isIOS,
      'is-app': isApp,
      'is-ios-full': isIPhoneFullScreen,
      'is-ios-11-1-2': isIOS_11_1_2,
    }"
  >
    <ActionSheet />
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" />
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { isIPhoneFullScreen } from '@/util/browser-env';
import ActionSheet from '@/component/action-sheet/index.vue';

export default {
  name: 'App',
  components: {
    ActionSheet,
  },
  data() {
    return {
      isIPhoneFullScreen: isIPhoneFullScreen(),
    };
  },
  computed: {
    ...mapState({
      isApp: state => state.isApp,
      isAndroid: state => state.isAndroid,
      isIOS: state => state.isIOS,
      isIOS_11_1_2: state => state.isIOS_11_1_2,
    }),
  },

};

</script>

<style lang="scss">
@import '~@/assets/scss/common/base';

#firstScreenHtml {
  width: 100%;
  height: 100%;
}
#app {
  width: 100%;
  height: 100%;
  &.platform-android.is-app {
    overflow-y: scroll;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 4px;
      height: 8px;
      border-radius: 3px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background: #ccc;
      border-radius: 3px;
    }
  }
}
</style>

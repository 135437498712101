import Vue from 'vue';
import { isServerRender } from '@/util/env';
import ToastComp from './index.vue';

const ToastConstructor = Vue.extend(ToastComp);

const toastPool = [];

// const getAnInstance = () => {
//   if (toastPool.length > 0) {
//     const instance = toastPool[0];
//     toastPool.splice(0, 1);
//     return instance;
//   }
//   return new ToastConstructor({
//     el: document.createElement('div'),
//   });
// };

const getAnInstance = () => {
  if (!toastPool[0]) {
    toastPool[0] = new ToastConstructor({
      el: document.createElement('div'),
    });
  }
  return toastPool[0];
};

const returnAnInstance = (instance) => {
  if (instance) {
    toastPool.push(instance);
  }
};

const removeDom = (event) => {
  if (event.target.parentNode) {
    event.target.parentNode.removeChild(event.target);
  }
};

/* eslint-disable-next-line */
ToastConstructor.prototype.close = function () {
  this.visible = false;
  this.$el.addEventListener('transitionend', removeDom);
  this.closed = true;
  returnAnInstance(this);
};

const Toast = (options = {}) => {
  if (isServerRender) { // 服务端渲染不展示toast
    return null;
  }
  const duration = options.duration || 3000;

  const instance = getAnInstance();
  instance.closed = false;
  clearTimeout(instance.timer);
  instance.message = typeof options === 'string' ? options : options.message;
  instance.position = options.position || 'middle';
  instance.className = options.className || '';
  instance.iconClass = options.iconClass || '';

  document.body.appendChild(instance.$el);
  Vue.nextTick(() => {
    instance.visible = true;
    instance.$el.removeEventListener('transitionend', removeDom);
    instance.timer = setTimeout(() => {
      if (instance.closed) return;
      instance.close();
    }, duration);
  });
  return instance;
};

export default Toast;

import store from '@/store/index';
import launchApp from '@/util/launchApp';

export default function (fn, ...args) {
  if (!store.state.isApp) {
    launchApp();
  } else if (!store.state.user.userInfo || Object.keys(store.state.user.userInfo).length === 0) {
    // this.$toast('请登录');
    store.dispatch('user/login');
  } else {
    fn.apply(this, args);
  }
}

import { checkEnv } from '@/util/browser-env';
import launchApp from '@/util/launchApp';

const env = checkEnv();

export default function (fn, ...args) {
  if (!env.isApp) {
    launchApp();
  } else {
    fn.apply(this, args);
  }
}

import jsbridge from "../../libs/jsbridge";
import {isIOS, isAndroid, isBrowser} from "../../libs/os";
import {
    checkCommand,
    checkPass,
    hasKeys, paramsIsObject,
    validator
} from "../../utils/verifyHelper";
import {isObject, isString, isUrl, isValidScheme} from "../../libs/typeChecker";

/**
 * 打开一个新的webview
 *
 * @function openUrl
 * @memberof ui
 *
 * @param {Object} params
 * @param {String} params.url 跳转路径，必须绝对地址，有协议头
 * @param {Number} params.target 跳转方式，0:在当前webview打开；1：在新的webview打开；2: 系统浏览器打开
 * @param {Number} [params.style=0] webview的样式，0：顶部标题栏模式，1:顶部无标题栏形式
 * @param {Number} [params.animation=0] 打开时的动画，0：从右往左，1:直接打开，2:从下往上
 * @returns {Promise}
 *
 * @example
 * openUrl({
 *   url: 'www.qq.com',
 *   target: 0
 * })
 */
export function openUrl(params) {
    let result = checkPass(
        checkCommand(
            paramsIsObject('openUrl'),
            hasKeys("url"),
            validator("url必须为完整的url", isValidScheme, "url")
        )(params)
    );
    if (result) {
        return Promise.reject(result);
    }
    //传点击时间给终端，用于测速统计
    params.onClickTime = Number(new Date());
    params.target = params.target || 2 //默认是新开

    //处理打开方式,以下处理方式迁移自旧的native模块
    if (isIOS()) {
        if (params.target === 1 || params.target === 2) {
            params.styleCode =
                {
                    1: 4,
                    2: 2,
                    3: 5
                }[params.style] || 1;
        }
    } else if (isAndroid()) {
        if (params.target === 1) {
            if (!params.style) {
                params.style = 0;
            }
        }
    } else if (isBrowser()) {
        if (params.target === 2) {
            window.open(params.url, "_blank");
        } else {
            window.open(params.url, "_self");
        }
    }


    return jsbridge({url: "ui/openUrl", params: params});
}



import compareVerson, { compareVersionCode } from '@/util/compareVersion';
import { env } from '@/util/browser-env';

/**
 * 将图片链接替换为tgc链接，使用终端能力
 */
export default function tgcimg(url) {
  if (env.isApp && compareVerson('1.2') > -1 && /^http/.test(url)) {
    if (env.ios) {
      // ios 11 以下不支持
      if (compareVersionCode('11', env.iosVersion) < 0) {
        return url;
      }
    }
    return `tgcimg:${url}`;
  }
  return url;
}

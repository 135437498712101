import jsbridge from "../../libs/jsbridge";
import {
    checkCommand,
    checkPass,
    hasKeys, paramsIsObject,
    validator
} from "../../utils/verifyHelper";
import {isUrl} from "../../libs/typeChecker";

/**
 * 打开一个新的webview
 *
 * @function openUrl
 * @memberof ui
 *
 * @param {Object} params
 * @param {String} params.url 跳转路径，必须绝对地址，有协议头
 * @returns {Promise}
 *
 * @example
 * openPopWebview({
 *   url: 'https://www.qq.com'
 * })
 */
export function openPopWebview(params) {
    let result = checkPass(
        checkCommand(
            paramsIsObject('openPopWebview'),
            hasKeys("url"),
            validator("url必须为完整的url", isUrl, "url")
        )(params)
    );
    if (result) {
        return Promise.reject(result);
    }
    //传点击时间给终端，用于测速统计
    params.onClickTime = Number(new Date());

    return jsbridge({url: "ui/openPopWebview", params: params});
}



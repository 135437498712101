
/**
 * @desc 把字符传的2_1转换呈float类型的浮点数
 * @param str
 * @returns {Number|number}
 * @private
 */

export  default function (str) {
  return parseFloat((str || '').replace(/\_/g, '.')) || 0
}

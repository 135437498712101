import querystring from 'querystring';
import { filterXSS } from 'xss';

class LaunchInfo {
  constructor(options) {
    this.options = {
      openType: 5,
      postId: '',
      gameCode: '',
      scheme: '',
      channel: 'ShanxianOfficial',
      ...options,
    };

    this.getRealScheme();
  }

  getRealScheme() {
    let url = filterXSS(this.options.scheme) || this.getFullUrl();

    if (url) {
      url = url.startsWith('tgc://') ? url : `tgc://browser?url=${encodeURIComponent(url)}`;
      const flag = url.indexOf('?') >= 0 ? '&' : '?';
      url = `${url}${flag}srcType=${this.options.openType}&srcId=${this.options.openType === 5 ? this.options.postId : this.options.gameCode}`;
    }

    this.options.scheme = url;
  }

  getFullUrl() {
    const {
      pathname,
      search,
      protocol,
      host,
    } = window.location;
    const searchObj = querystring.parse(search[0] === '?' ? search.slice(1) : search);
    const ch = searchObj.sx_ch || searchObj.sx_tag || searchObj.shanxianinchannel;
    delete searchObj.pageOpenType;
    let pageFrom = '';
    let prevId = '';
    let urlStart = `${protocol}//${host}${pathname}`;
    if (pathname.indexOf('community/question-detail') >= 0) {
      pageFrom = '2004';
      prevId = '1504';
    } else if (pathname.indexOf('community/answer-detail') >= 0) {
      pageFrom = '2005';
      prevId = '1505';
    } else if (pathname.indexOf('community/post-video') >= 0) {
      pageFrom = '2003';
      prevId = '1503';
      urlStart = 'tgc://video_room';
      searchObj.feedId = searchObj.postId;
      this.options.postId = searchObj.postId;
      delete searchObj.postId;
    } else if (pathname.indexOf('community/post-detail') >= 0) {
      pageFrom = '2002';
      prevId = '1502';
    } else if (pathname.indexOf('activity-center') >= 0) {
      prevId = '1107';
      this.options.channel = 'sxhdhdzx';
    } else if (pathname.indexOf('group/tool/career-list') >= 0) {
      prevId = '1308';
    }

    if (searchObj.open_type) {
      this.options.openType = [5, 6].includes(parseInt(searchObj.open_type, 10))
        ? parseInt(searchObj.open_type, 10) : 5;
      delete searchObj.open_type;
    }
    if (searchObj.postId) {
      this.options.postId = searchObj.postId;
    }
    if (searchObj.game_code) {
      this.options.gameCode = searchObj.game_code;
      delete searchObj.game_code;
    }
    // 拉起后，渠道号保留
    if (!this.options.channel && ch) {
      this.options.channel = ch;
    }

    searchObj.pageFrom = pageFrom;
    searchObj.prev_id = prevId;


    return `${urlStart}?${querystring.stringify(searchObj)}`;
  }
}

export default function ({ scheme = '', schema = '', channel = 'ShanxianOfficial' } = {}) {
  const info = new LaunchInfo({ scheme: scheme || schema, channel });
  import('@tencent/game-community-js-sdk').then((sdk) => {
    sdk.app.launch({
      actId: 'shanxianH5LaunchApp',
      actName: '内部页面拉起APP',
      scheme: info.options.scheme,
      autoInstall: true,
      downloadUrlParams: { // 参数会带到下载页面
        shanxianinchannel: info.options.channel,
      },
    });
  });
}

/**
 * @file 模块到处文件
 */

import * as data from "./api/data";
import * as ui from "./api/ui";
import * as share from './api/share';
import * as user from './api/user';
import * as notice from './api/notice';
import * as core from './api/core';
import * as app from './api/app';
import * as sxapp from './api/sxapp';
import * as device from './api/device';
import * as redDot from './api/redDot';

const jsbridge = {
  app,
  sxapp,
  data,
  ui,
  share,
  user,
  notice,
  core,
  device,
  redDot,
};

export default jsbridge;

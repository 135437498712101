import camelize from '../../utils/camelize';

export function transferUserInfo (originUserInfo) {
  let tempUserInfo;
  if (!originUserInfo) return {};
  if (typeof originUserInfo === 'string') {
    try {
      tempUserInfo = JSON.parse(originUserInfo);
    } catch (error) {
      console.error('transferUserInfo error', error);
      return {};
    }
  } else {
    tempUserInfo = originUserInfo;
  }
  tempUserInfo = camelize.decamelizeKeys(tempUserInfo);
  return {
    ...tempUserInfo,
    uid: Number(tempUserInfo.uid) || 0,
    privilege: Number(tempUserInfo.privilege) || 0,
    forbid_time: Number(tempUserInfo.forbid_time) || 0,
    scheme_url: tempUserInfo.scheme_url || tempUserInfo.schemeURL,
  };
}

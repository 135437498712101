/* eslint-disable import/prefer-default-export */
import { logger } from '../logger';

const native = window.native ? window.native : {};
window.native = native;
if (!window.native.execEventCallback) {
  window.native.execEventCallback = (eventName, ...argus) => {
    const evtKey = `evt-${eventName}`;
    logger.debug('execEventCallback', evtKey);
    const handlers = window[evtKey];
    if (handlers) {
      handlers.forEach((handler) => {
        handler(...argus);
      });
    }
  };
}

export function addEventListener(eventName, handler) {
  const evtKey = `evt-${eventName}`;
  (window[evtKey] = window[evtKey] || []).push(handler);
  logger.debug('addEventListener', window[evtKey]);
}

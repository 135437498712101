/**
 * object2url
 * @author marvinqi
 * @createTime 2017/9/19
 */

/**
 * Object参数转换为String
 * @param {Object} data 参数
 * @returns {string}
 * @example
 * object2url({a:1,b:2}) => "a=1&b=2"
 */
export default function (data = {}) {
  return Object.keys(data).map(key=>`${key}=${data[key]}`).join('&')
}
/**
 * 统一链接跳转逻辑，兼容不同环境的链接
 */
import qs from 'qs';
import { pushView } from '@/jsbridge';
import { checkEnv } from '@/util/browser-env';
import { logger } from '@/util/logger';

const checkEnvObj = checkEnv();


const staticUrlList = [
  /qq\.com\/community\/(post-detail)\/?\?(.+)/,
  /qq\.com\/community\/(post-video)\/?\?(.+)/,
  /qq\.com\/community\/(answer-detail)\/?\?(.+)/,
  /qq\.com\/community\/(question-detail)\/?\?(.+)/,
];


const directUrlList = [
  /qq\.com\/direct\/pages\/community\/(post-detail)\.html\/?\?(.+)/,
  /qq\.com\/direct\/pages\/community\/(post-video)\.html\/?\?(.+)/,
  /qq\.com\/direct\/pages\/community\/(answer-detail)\.html\/?\?(.+)/,
  /qq\.com\/direct\/pages\/community\/(question-detail)\.html\/?\?(.+)/,
];

/**
 * 获取直出链接
 * @param {String} url 路径
 */
export function toDirectUrl(url = window.location.href) {
  for (let i = 0; i < staticUrlList.length; i++) {
    if (staticUrlList[i].test(url)) {
      return url.replace(staticUrlList[i], 'qq.com/direct/pages/community/$1.html?$2');
    }
  }
  return url;
}

/**
 * 将直出链接改成静态链接
 */
export function toStaticUrl(url = window.location.href) {
  for (let i = 0; i < directUrlList.length; i++) {
    if (directUrlList[i].test(url)) {
      return url.replace(directUrlList[i], 'qq.com/community/$1?$2');
    }
  }
  return url;
}


function uniOpenUrl(url) {
  const queryStr = url.slice(url.indexOf('?'));
  const queryObj = qs.parse(queryStr, { ignoreQueryPrefix: true });
  let resultUrl = url;

  // 转换视频帖子链接。App内需要使用tgc://video_room?feedId=xxx的链接
  if (checkEnvObj.isApp && /\/community\/post-video/.test(url)) {
    queryObj.feedId = queryObj.postId;
    resultUrl = `tgc://video_room?${qs.stringify(queryObj)}`;
  } else if (!checkEnvObj.isApp && /^tgc:\/\/video_room/.test(url)) {
    queryObj.postId = queryObj.feedId;
    const {
      protocol, host,
    } = window.location;
    resultUrl = `${protocol}//${host}/community/post-video?${qs.stringify(queryObj)}`;
  }
  resultUrl = toDirectUrl(resultUrl);
  logger.info('uniOpenUrl', url, resultUrl);
  pushView(resultUrl); // 方法内调用不同跳转
}

/**
 * 获取分享到外部的链接
 * @param {String} url 路径
 */
export function getShareUrl(url) {
  if (url) {
    return toDirectUrl(url);
  }
  const {
    pathname, search, protocol, host,
  } = window.location;
  const searchObj = qs.parse(search[0] === '?' ? search.slice(1) : search) || {};
  const DELETE_LIST = ['anchor', 'pageOpenType', 'pageFrom', 'openScene'];
  DELETE_LIST.forEach((item) => {
    delete searchObj[item];
  });
  const fullUrl = `${protocol}//${host}${pathname}?${qs.stringify(searchObj)}`;
  return toDirectUrl(fullUrl);
}


export default uniOpenUrl;

import jsbridge from "../../libs/jsbridge";
import { transferUserInfo } from './util';


/**
 * @typedef Response getUserInfo返回内容
 * @type {Object}
 * @property {Number} result 0 代表成功
 * @property {UserInfo} data 服务器返回的userInfo
 */

/**
 * @typedef UserInfo 用户信息
 * @type {Object}
 * @example
 * ```
 {
    "uid": 1000035,
    "open_id": "",
    "icon_url": "https://static.gameplus.qq.com/img/1577430307-5442",
    "nick_name": "liao",
    "level": 15,
    "privilege": 0,
    "account_type": 3,
    "vip_type": 1,
    "identification": "开发者",
    "description": "开发者",
    "gender": 1,
    "follow_status": 0,
    "recommend_type": 2,
    "scheme_url": "tgc://native?moduleName=personal&uid=1000035",
    "status": 1,
    "forbid_reason": "",
    "forbid_time": 0,
    "phone": "",
    "online_status": 1,
    "exp": 0,
    "renown_info": {
      "point": 1816,
      "rank": 2,
      "sub_rank": 1,
      "level": 4,
      "icon_url": "https://static.gameplus.qq.com/business/shengwang/s4_45.png",
      "rank_name": "黄金III玩家",
      "user_name": "liao",
      "scheme_url": "tgc://native?moduleName=renowndetail&uid=1000035"
    },
    "spark_level": 0,
    "exam_status": 0,
    "login_type": 2,
    "platform": 2,
    "user_badge": {
      "acquireAt": 0,
      "online_status": {
        "animationUrl": "https://static.gameplus.qq.com/business/medal/chuji.json",
        "backgroundAnimation": "https://static.gameplus.qq.com/business/medal/chuji.json",
        "badgeId": 0,
        "bigIcon": "https://static.gameplus.qq.com/img/app-upload/cecfb06a7889ab30e296ab4986237b7c-icon@3x.png",
        "bigIconLock": "",
        "iconUrl": "https://static.gameplus.qq.com/img/app-upload/c7434f0dacb7efb90efbdcf26ee07ce9-icon@3x.png",
        "intro": "",
        "jumpBtnText": "",
        "jumpUrl": "",
        "level": 0,
        "mediumIcon": "https://static.gameplus.qq.com/img/app-upload/cecfb06a7889ab30e296ab4986237b7c-icon@3x.png",
        "name": "",
        "nameAndLevel": "http://down.qq.com/qqtalk/wegamex/lottie/LV.1.png",
        "nameImg": "",
        "requiredScore": 0,
        "showName": "见习答主勋章"
      },
      "deltaScore": 0,
      "description": "",
      "levelupScoreNeed": 0,
      "maxLevelInHistory": 0,
      "isShow": 1,
      "number": 23333233,
      "progressCurrent": 2000,
      "progressTarget": 3000,
      "renownIconUrl": "https://ss1.bdstatic.com/70cFvXSh_Q1YnxGkpoWK1HF6hhy/it/u=1141259048,554497535&fm=26&gp=0.jpg",
      "score": 0,
      "status": 2,
      "uid": 0,
      "updatedAt": 0,
      "wear": false
    },
    "user_info_ext": {
      "ad_code": 0
    },
    "extend": {}
  }
 * ```
 */



/**
 * 获取用户信息
 * 
 * @function getUserInfo
 * @memberof user
 * 
 * @param {any} params
 * @returns {Response}
 * 
 * @example
 * getUserInfo().then((res) => {
 *  console.log(res);
 * });
 * 
 * // output
 * {
 *   result: 0,
 *   data: {
 *     nickName: 'xxx',
 *     uid: '123123'
 *   }
 * }
 */
export function getUserInfo (params) {
  return jsbridge({url: "user/getUserInfo", params: params}).then((res) => {
    if (res.result === 0) {
      const userInfo = transferUserInfo(res.data);
      return {
        ...res,
        data: userInfo
      };
    }
    return res;
  });
}

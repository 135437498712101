import jsbridge from '../../libs/jsbridge'

/**
 * @namespace app
 */

/**
 * 下载应用
 *
 * @function downloadApp
 * @memberof app
 *
 * @param params
 * @param {string} params.actionCode	操作，0=下载，1=暂停
 * @param {string} params.urlStr 应用url
 * @param {string} params.packageName 包名，如com.tencent.zgqyz
 * @param {string} params.appName 应用名
 * @param {string} params.iconUrl 应用图标
 * @param {string} params.appChannel 应用渠道
 * @param {string} params.versionCode 应用版本
 * @param {string} params.packageSize 安装包大小
 * @param {number} params.flag 安装操作标识， bit0 是否自动安装，bit1是否强制wifi, bit2是否检查wifi，bit3是否在任务栏提示,bit4是否回调下载信息,bit5是否校验安装包签名信息,bit6是否静默下载
 * @param {Object} params.mExtData 额外信息，{gameCode, apkMd5}
 * @returns {Promise}
 *
 * @example
```
downloadApp({
    "actionCode": 0, // 0=下载，1=暂停
    "urlStr": "https://dlied4.myapp.com/myapp/6337/cos.release-75962/10046713_com.tencent.zgqyz_a983581_1.0.24_7dhg90.apk", // gameInfo.packageInfo.downloadUrl
    "packageName": "com.tencent.zgqyz", // gameInfo.packageInfo.packageName,
    "appName": "鸿图之下", // gameInfo.name
    "iconUrl": "https://static.gameplus.qq.com/img/1591757715-5070", // gameInfo.iconUrl
    "appChannel": "10046713", // gameInfo.packageInfo.channelId
    "versionCode": "1.0.24", // gameInfo.packageInfo.version
    "packageSize": "789445897", // gameInfo.packageInfo.packageSize
    "flags": 1 + 8 + 16 + 32, // bit0 是否自动安装，bit1是否强制wifi, bit2是否检查wifi，bit3是否在任务栏提示,bit4是否回调下载信息,bit5是否校验安装包签名信息,bit6是否静默下载
    "mExtData": {
        "gameCode": "srpg", // gameInfo.gameCode
        "apkMd5": "{\"557788961-557789060\":\"fc15aeb1d1fe764a33b97ddf359bb0cc\",\"789445797-789445896\":\"fa8efabd4f54dc129cfea997270c8691\"}" // gameInfo.packageInfo.apkMd5
    }
})
```
 */
export function downloadApp (params = {}) {
  return jsbridge({
    url: 'app/downloadApp',
    params
  }, {
    callbackType: 'observer',
    customCallback: true,
  })
}

/**
 * 监听下载进度
 *
 * @function registerDownloadCallBackListener
 * @memberof app
 *
 * @returns {Promise}
 */
export function registerDownloadCallBackListener (params = {}) {
  return jsbridge({
    url: 'app/registerDownloadCallBackListener',
    params
  }, {
    callbackType: 'observer',
    customCallback: true,
  })
}

/**
 * 检查是否安装
 * @deprecated 暂时有问题, 请用 isAppInstalled
 * @param {Object} params
 * @param {String} params.identifier
 */
export function checkAppInstalled (params = {}) {
  return jsbridge({
    url: 'app/checkAppInstalled', // isAppInstalled checkAppInstalled
    params
  }, {
    callbackType: 'observer',
    customCallback: true,
  })
}


/**
 * 检查是否安装
 *
 * @function isAppInstalled
 * @memberof app
 *
 * @param {Object} params
 * @param {String} params.identifier 应用包名
 */
export function isAppInstalled (params = {}) {
  return jsbridge({
    url: 'app/isAppInstalled',
    params
  }, {
    callbackType: 'observer',
    customCallback: true,
  })
}

/**
 * 打开app
 *
 * @function launchAppZTSDK
 * @memberof app
 *
 * @param {Object} params
 * @param {string} downloadUrl 【ios专用】 下载地址
 * @param {string} scheme 【ios专用】应用shceme
 * @param {string} identifier 【Android专用】应用包名
 * @param {string} gameAppId
 * @param {string} packageName 【传对应平台字段】
 * @returns {Promise}
 *
 * @example
 ```javascript
 if (isIOS) {
  jsbridge.app.launchAppZTSDK({
    downloadUrl: gameInfo.packageInfo.downloadUrl,
    scheme: gameInfo.packageInfo.schemeUrl,
    gameAppId: xxx,
    packageName: xxx,
  }).then((res) => {
    console.log('launchApp res', res);
  });
} else {
  jsbridge.app.launchAppZTSDK({
    identifier: gameInfo.packageInfo.packageName,
    gameAppId: xxx,
    packageName: xxx,
  }).then((res) => {
    console.log('launchApp res', res);
  });
}
 ```
 */
export function launchAppZTSDK(params = {}) {
  return jsbridge({
    url: 'app/launchAppZTSDK',
    params
  }, {
    callbackType: 'observer',
    customCallback: true,
  })
}


/**
 * 打开app
 *
 * @function launchApp
 * @memberof app
 *
 * @param {Object} params
 * @param {string} downloadUrl 【ios专用】 下载地址
 * @param {string} scheme 【ios专用】应用shceme
 * @param {string} identifier 【Android专用】应用包名
 * @returns {Promise}
 *
 * @example
```javascript
if (isIOS) {
  jsbridge.app.launchApp({
    downloadUrl: gameInfo.packageInfo.downloadUrl,
    scheme: gameInfo.packageInfo.schemeUrl,
  }).then((res) => {
    console.log('launchApp res', res);
  });
} else {
  jsbridge.app.launchApp({
    identifier: gameInfo.packageInfo.packageName,
  }).then((res) => {
    console.log('launchApp res', res);
  });
}
```
 */
export function launchApp (params = {}) {
  return jsbridge({
    url: 'app/launchApp',
    params
  }, {
    callbackType: 'observer',
    customCallback: true,
  })
}


/**
 * app.downloadApk回调函数
 *
 * @callback downloadApkCallback
 * @param {string} type - 事件类型。progress=下载中,pause=暂停,finish=已完成,install=安装
 */


/**
 * 下载并安装应用
 * 基于 downloadApp 和 registerDownloadCallBackListener封装
 *
 * @function downloadApk
 * @memberof app
 *
 * @param {Object} params
 * @param {string} params.actionCode	操作，0=下载，1=暂停
 * @param {string} params.url 应用url
 * @param {string} params.packageName 包名，如com.tencent.zgqyz
 * @param {string} params.appName 应用名
 * @param {string} params.iconUrl 应用图标
 * @param {number} [params.flags=1+4+8+16+32] 标志位，默认自动下载安装。 bit0 是否自动安装，bit1是否强制wifi, bit2是否检查wifi，bit3是否在任务栏提示,bit4是否回调下载信息,bit5是否校验安装包签名信息,bit6是否静默下载
 * @param {downloadApkCallback} params.callback 下载回调
 * @returns {Promise}
 *
 * @example
```
jsbridge.app.downloadApk({
  url: 'https://dlied4.myapp.com/myapp/6337/cos.release-75962/10046713_com.tencent.zgqyz_a983581_1.0.24_7dhg90.apk',
  actionCode: this.downloadStatus2 === 'progress' ? 1 : 0,
  appName: '鸿图之下',
  packageName: 'com.tencent.zgqyz',
  iconUrl: "https://static.gameplus.qq.com/img/1591757715-5070",
  callback: (params) => {
    console.log('download APK callback', params);
    this.downloadStatus2 = params.type;
    if (params.type === 'progress') {
      this.downloadPercent2 = params.progress + '%';
    }
  },
})
```
 */
export function downloadApk(params) {
  const callback = typeof params.callback === 'function' ? params.callback : function () { };
  downloadApp({
    actionCode: params.actionCode,
    urlStr: params.url,
    packageName: params.packageName,
    appName: params.appName,
    iconUrl: params.iconUrl,
    flags: params.flags ?? params.flgas ?? 1 + 4 + 8 + 16,
    callback(e) {
      if (e.result !== 0) {
        callback({
          type: 'error',
          e,
        });
        throw Error(`下载出错 code: ${e.result}`);
      }
    },
  });
  registerDownloadCallBackListener({
    callback(res) {
      if (res.packageName === params.packageName) {
        if (res.state === 0) { // 0 下载中
          callback({
            type: 'progress',
            progress: res.progress,
          });
        } else if (res.state === 1) {
          callback({
            type: 'pause',
          });
        } else if (res.state === 2) {
          callback({
            type: 'waiting',
          });
        } else if (res.state === 3) {
          callback({
            type: 'finish',
          });
        } else if (res.state === 4 || res.state === 8) {
          callback({
            type: 'install',
          });
        } else {
          callback({
            type: 'unknown',
            e: res
          });
        }
      }
    },
  })
}

import jsbridge from '../../libs/jsbridge'

/**
 * @namespace sxapp
 */

/**
 * TGPA预下载游戏资源
 *
 * @function startPreDownload
 * @memberof sxapp
 *
 * @param {string} pkg 游戏包名
 */
export function startPreDownload (pkg) {
  return jsbridge({
    url: 'sxapp/startPreDownload',
    params: {
      pkg: pkg,
    }
  }, {
    callbackType: 'observer',
    customCallback: true,
  })
}


/**
 * TGPA游戏资源状态查询
 *
 * @function getPreDownloadProcess
 * @memberof sxapp
 *
 * @param {string} pkg 游戏包名
 * 
 * @returns {Promise}
 
 * @example
```
\/**
 * 当totalCount=1或者totalCount=downLoadGount时，进度使用recevivedLen/totalLen
 * 当totalCount>1并 且totalCountsdownLoadCount时，表示有多个资源包，有downLoadcount时个下载完了，
 * 进度使用downLoadGount/totalCount(此时totallen是已经开始下载的资源包的总大小，不是所有资源包的总大小，所以不能用于计算进度）
 *\/
getPreDownloadProcess('com.tencent.abcd').then((res) => {
  if (res.result !== 0) {
    // query fail
    return;
  }
  console.log(res.data);
  res.data.ret;           // 状态码：0正常，可读取进度;1，未启动，先调用启动下载接口；-1，当前游戏无需预下载
  res.data.receivedLen;   // 已下载长度，单位是bytestotallen：总长度
  res.data.path;          // 文件存储路径
  res.data.totalCount;    // 预下载资源包总数
  res.data.downLoadCount; // 已下载的资源包个数
});
```
 */
export function getPreDownloadProcess (pkg) {
  return jsbridge({
    url: 'sxapp/getPreDownloadProcess',
    params: {
      pkg: pkg,
    }
  }, {
    callbackType: 'observer',
    customCallback: true,
  })
}

/**
 * getUrl
 * @author donaldcen
 * @createTime 2017/10/22
 */

export default function () {
  if (typeof weex === 'object' && weex.config && weex.config.bundleUrl) {
    return weex.config.bundleUrl
  } else if (typeof window === 'object' && window.location && window.location.href ) {
    return window.location.href
  } else {
    return ''
  }
}
import { logger } from '@/util/logger';
import { checkEnv } from '@/util/browser-env';
import { serverWindow as window } from '@/util/env';

const MORE_FLAG = 1;
const EQUAL_FLAG = 0;
const LESS_FLAG = -1;

let platform = '';
if (checkEnv().android) {
  platform = 'android';
} else if (checkEnv().ios) {
  platform = 'ios';
}
// 获取UA
// const getUserAgent = () => navigator && navigator.userAgent;

// 获取IOS版本号
const getIOSVersion = (ua) => {
  const regexp = /.*\s+com\.tencent\.gamecommunity\/(\S+)\s+.*/ig;
  if (ua && regexp.test(ua)) {
    // ua = 'Mozilla/5.0 (iPhone; CPU iPhone OS 13_3 like Mac OS X) AppleWebKit/605.1.15
    // (KHTML, like Gecko) Mobile/15E148 com.tencent.gamecommunity/1.1.0.1.1.1sss ENV/TEST SUB/0 Scale/2.00'
    return ua.replace(regexp, '$1').replace(/([^\d.])/g, '');
  }
  return '';
};

// 获取Android版本号
const getAndroidVersion = (ua) => {
  const regexp = /.*\s+VERSION_NAME\/(\S+)\s+.*/ig;
  if (ua && regexp.test(ua)) {
    // ua = 'ENV/TEST BUNDLEID/com.tencent.gamecommunity CHANNEL/DevOps VERSION_NAME/1.1.0.2 VERSION_CODE/2 SUB/0'
    return ua.replace(regexp, '$1').replace(/([^\d.])/g, '');
  }
  return '';
};

// 获取终端版本号
const getClientVersion = (ua) => {
  if (checkEnv().android) {
    return getAndroidVersion(ua);
  } if (checkEnv().ios) {
    return getIOSVersion(ua);
  }
  return '';
};

// 对比版本
export const compareVersionCode = (targetVersion, currentVersion) => {
  // 没有当前版本直接按照小于处理
  if (!currentVersion) {
    return LESS_FLAG;
  }
  const nowVersion = currentVersion;
  // targetVersion = '1.0.1.1'
  // nowVersion = '1.0.1.2.3'
  const tvr = targetVersion.split('.');
  const nvr = nowVersion.split('.');
  let status = EQUAL_FLAG;
  // 任何一个版本号解析错误都按照小于
  if (tvr.length <= 0 || nvr.length <= 0) {
    status = LESS_FLAG;
  } else {
    // 循环对比
    for (let i = 0; i < nvr.length; i++) {
      const n = parseInt(nvr[i], 10);
      let t = tvr[i];
      // 如果当前版本位数大于目标版本
      if (typeof t === 'undefined') {
        if (n > 0) {
          status = MORE_FLAG;
        } else {
          status = EQUAL_FLAG;
        }
      } else {
        t = parseInt(tvr[i], 10);
        if (n > t) {
          status = MORE_FLAG;
          break;
        } else if (n === t) {
          status = EQUAL_FLAG;
        } else {
          status = LESS_FLAG;
          break;
        }
      }
    }
    // 如果当前版本位数小于目标版本并且前面没对比出来大小
    if (status === EQUAL_FLAG && tvr.length > nvr.length) {
      let nl = nvr.length;
      while (typeof tvr[nl] !== 'undefined') {
        status = parseInt(tvr[nl], 10) > 0 ? LESS_FLAG : EQUAL_FLAG;
        // eslint-disable-next-line no-plusplus
        nl++;
      }
    }
  }
  return status;
};

/**
 * 判断目标版本是否大于当前版本(在服务端渲染有问题，慎用)
 * @param targetVersion [String|Object] 安卓IOS统一对比就传字符串 分开对比就传对象 {android: '1.1.0', ios: '1.1.0',}
 * @returns {Number} code -1 当前版本小于目标版本 0 当前版本等于目标版本 1 当前版本大于目标版本 ua userAgent currentVersion 当前版本号
 */
export default function compareVerson(targetVersion) {
  let code = LESS_FLAG;
  let ua = '';
  let currentVersion = '';
  try {
    ua = window.navigator.userAgent;
    currentVersion = getClientVersion(ua);
    if (typeof targetVersion === 'string') {
      code = compareVersionCode(targetVersion, currentVersion);
    } else if (typeof targetVersion === 'object') {
      code = compareVersionCode(targetVersion[platform], currentVersion);
    }
  } catch (e) {
    logger.error('compareVerson error', e);
  }
  return code;
  // return {
  //   code,
  //   targetVersion,
  //   currentVersion,
  //   ua,
  // };
}

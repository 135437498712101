/**
 * getDeviceId
 * @author marvinqi
 * @createTime 2017/9/20
 */

/**
 *@desc 获取设备id
 *@returns {*|string}
 */
import getUA from './getUA'

export default function () {
  return (/\bimei\/([\w\-]+)/i.exec(getUA()) || ['', '0'])[1] //IMEI号
}
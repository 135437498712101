/**
 * Created by Dreamzhu on 2017/4/5.
 * 用于实现页面的全局通知
 */

import Vue from 'vue';

const message = new Vue();

// 触发事件
export function emitEvent(eventName, ...args) {
  // eslint-disable-next-line no-useless-call
  message.$emit.apply(message, [eventName, ...args]);
}

// 监听事件
export function onEvent(eventName, callback) {
  message.$on(eventName, callback);
}

export function onceEvent(eventName, callback) {
  message.$once(eventName, callback);
}

export default {
  emit: emitEvent,
  on: onEvent,
  once: onceEvent,
};

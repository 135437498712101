/**
 * jsAPI 接入
 * @author donaldcen
 * @createTime 2017/9/18
 */

import {isQgame, isQgameLiveassistant, isTest} from '../os'
import web from './web'

/**
 * 请求终端接口
 * @param {Object|String} data 字符串则当做schema处理，对象自动转换
 * @option {string} data.schema 传入的协议头默认jsbridge
 * @param {String} data.url 终端接口路径
 * @param {Object} data.params 终端接口参数
 * @param {object} options
 * @options {string} options.dataType // 'strict':开启严格模式，终端对调必须有result
 * @options {string} options.callbackType // 'observer':开启回调不回收模式，终端可以触发多次回调
 * @returns {Promise}
 *
 * @example
 * base({
 *  url:'ui/setWebViewBehavior',
 *  params: {"navBgColor":"#FFFF0000","callback":"__MQQ_CALLBACK_2"}
 * })
 */
export default function (data, options) {
  // if(isQgame() || isQgameLiveassistant() || isTest()){
  //   return web(data, options)
  // } else{
  //   return Promise.reject('not support')
  // }
  return web(data, options)
}

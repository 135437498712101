/**
 * @desc compose函数
 * @param {function} args 合并函数
 * @returns {function} 获取输入函数
 */
export function compose (...args) {
  let funcS = Array.prototype.slice.call(args).reverse()
  /**
     * @desc 计算输出结果
     * @param {object} input
     * @returns {object} output
     */
  return function (x) {
    let result = arguments
    for (let l = funcS.length; l--;) {
      result = [funcS[l].apply(this, result)]
    }
    return result[0]
  }
}

/**
 * @desc 匹配正则表达式
 * @param {regex} regex 需要匹配的正则表达式
 * @returns {function} 获取匹配字符串
 */
export function match (regex) {
  /**
     * @desc 获取和输出匹配字符串
     * @param {string} input
     * @returns {Array} 匹配结果
     */
  return function (string) {
    return String(string).match(regex)
  }
}

/**
 * @desc 获取数组中的某一个值
 * @param {number} index
 * @returns {function} 获取数组的输入
 */
export function getOne (index) {
  /**
     * @param {Array} 输入数组
     * @returns {object} 输出数据节点
     */
  return function (arr) {
    return arr[index]
  }
}

export let getFirst = getOne(0)

export function getLastOne (arr) {
  return arr[arr.length - 1]
}

/**
 * @desc 把输入内容转换成string类型
 * @returns {string}
 * @param string
 */
export function toString (string) {
  return Object.prototype.toString.call(string)
}

/**
 * @desc 把字符串变成纯小写
 * @param string
 * @returns {string} 输出的纯小写内容
 */
export function toLowerCase (string) {
  return String(string).toLowerCase()
}


/**
 * @desc 去除字符串空格
 * @param string
 * @returns {string}
 */
export function trim (string) {
  return String(string).trim()
}

/**
 * @desc 获取对象类型
 * @param {object} 输入对象
 * @returns {string} 获取对象类型
 */
export let getType = compose(toString, match(/\[object (.*?)\]/), getOne(1), trim, toLowerCase)


<template>
  <DefaultLayout>
    <div class="p-preload">
      <span class="loading">
        {{ prompt }}
      </span>
    <!-- <button class="btn"
      @click="mockAppTest"
    >
      模拟终端ready
    </button>
    <button class="btn"
      @click="mockAppPush"
    >
      模拟终端pushView
    </button>
    <button class="btn"
      @click="addNoUseTimes"
    >
      模拟失败情况
    </button> -->
    </div>
  </DefaultLayout>
</template>

<script>
import { logger } from '@/util/logger';
import { checkEnv } from '@/util/browser-env';
import jsbridge from '@/jsbridge';
import DefaultLayout from '@/layouts/default/index.vue';
// import { addEventListener } from '@/util/webbundle/jsapi';
// 错误之后关闭加速方案的错误次数
const overTimes = 2;

const checkEnvObj = checkEnv();

export default {
  name: 'Preload',
  components: {
    DefaultLayout,
  },
  data() {
    return {
      prompt: '',
    };
  },
  mounted() {
    logger.info('preload page mounted');
    // if (checkEnvObj.android) return; // 验证安卓ANR问题
    const self = this;

    self.$nextTick(() => {
      // 加载完成之后，设置页面的宽度，防止iOS出现宽度变化的情况
      self.resetWindowWidth();

      /**
       * @description 注册方法供iOS终端调用，判断页面是否可用
       */
      window.isPreloadExist = function isPreloadExist() {
        return true;
      };

      jsbridge.ui.setOnVisibilityChange({
        callback: (res) => {
          // console.log('preload setOnVisibilityChange', res);
          logger.info('preload setOnVisibilityChange', res);
          if (res.result === 0) {
            if (res.data.visibilityState) {
              setTimeout(() => {
                // 如果超时5S之后还在preload页，说明执行出错，跳转失败，需要给出提示
                if (this.$route.name === 'preload') {
                  self.timeoutHandle();
                }
              }, 5000);
            }
          }
        },
      });
    });
  },
  methods: {
    // mockAppTest () {
    //   window.native.execEventCallback('vashybrid_bundle_message', {
    //     type: 'preloadView',
    //     data: null,
    //     url: 'https://mtest.gameplus.qq.com/community/post-detail?postId=400078003&_gcwv=2&pageFrom=1101',
    //   });
    // },
    // mockAppPush () {
    //   const mockData = {
    //     post_info: {
    //       id: '400078407',
    //       parent_id: '400075074',
    //       creator_id: '1000947',
    //       account_type: 5,
    //       group_id: '24',
    //       status: 6,
    //       type: 3,
    //       sub_type: 1,
    //       flag: '64',
    //       title: '目前收入最高的LOL电竞选手是谁？',
    //       content: '别人问imp"如果这游戏不火了你会失业吗”。\n',
    //       pics: { info: [] },
    //       summary: '别人问imp”。',
    //       video_info: {
    //         cover_url: '', duration: 0, detail: [], vid: '', flag: 0, source: 0,
    //       },
    //       components: null,
    //       scheduled_time: '0',
    //       recent_reply_time: '0',
    //       tags: '400004329',
    //       create_time: '1583342244',
    //       modified_time: '1583386741',
    //       channel: 2,
    //       review_stage: 5,
    //       recommend_reason: '优质回答',
    //       recommend_start_time: '1583337600',
    //       recommend_end_time: '3160137600',
    //       last_review_id: '400078874',
    //       tag: '',
    //       like_cnt: 1,
    //       comment_cnt: 0,
    //       read_cnt: 12,
    //       follow_cnt: 0,
    //       share_cnt: 0,
    //       negative_cnt: 0,
    //       exposure_cnt: 0,
    //       happy_cnt: 0,
    //       answer_cnt: 0,
    //       accuse_cnt: 0,
    //       invited_answer_cnt: 0,
    //       score: '155016',
    //       recent_accuse_time: '0',
    //       top_start_time: '0',
    //       top_end_time: '0',
    //       extent: {
    //         user_actions: [],
    //         post_detail_url: 'https://m.gameplus.qq.com/community/answer-detail?questionId=400075074\u0026answerId=400078407\u0026_wv=2',
    //         total_num_in_group: 0,
    //         user_action_with_post: {
    //           if_like: 0, if_follow: 0, if_answer: 0, if_dislike: 0, if_happy: 0,
    // like_time: '0', dislike_time: '0', happy_time: '0', answer_id: '0',
    //         },
    //         creator_activity: '',
    //         review_process: null,
    //       },
    //     },
    //     creator: {
    //       uid: '1000947',
    //       open_id: 'E74E7457975BA1121FBE271E0C8F17D7',
    //       icon_url: 'https://gameplus-test-1258344700.cos.ap-shanghai.myqcloud.com/head/0d3dbf920a2f7c03971d0692b43600eb_117283412.jpg',
    //       nick_name: '往事清零爱恨随意',
    //       level: 2,
    //       privilege: '0',
    //       account_type: 5,
    //       vip_type: 2,
    //       identification: '',
    //       description: '好人成佛要经历九九八十一难，而坏人只需放下屠刀。',
    //       gender: 1,
    //       follow_status: 0,
    //       recommend_type: 0,
    //       scheme_url: 'tgc://native?moduleName=personal\u0026uid=1000947',
    //       status: 1,
    //       forbid_reason: '',
    //       forbid_time: '0',
    //       phone: '',
    //       exp: 90,
    //       create_time: '1577361005',
    //       renown_info: {
    //         point: 0, rank: 1, sub_rank: 1, level: 1, icon_url: 'https://static.gameplus.qq.com/business/shengwang/rank1.png', rank_name: '白银III玩家', user_name: '', scheme_url: '',
    //       },
    //       online_status: 0,
    //     },
    //     group_info: {
    //       id: '24',
    //       name: '英雄联盟',
    //       icon_url: 'https://gameplus-test-1258344700.cos.ap-shanghai.myqcloud.com/img/1583148669-3365',
    //       description: '',
    //       cover_image_url: 'https://gameplus-test-1258344700.cos.ap-shanghai.myqcloud.com/img/1583225044-7384',
    //       update_num: '0',
    //       follow_num: '164',
    //       post_num: '0',
    //       official_num: '0',
    //     },
    //   };
    //   window.native.execEventCallback('vashybrid_bundle_message', {
    //     type: 'pushView',
    //     data: mockData,
    //     url: 'https://mtest.gameplus.qq.com/community/post-detail?postId=400078407&_gcwv=2&pageFrom=1101',
    //   });
    // },
    /**
     * @description 如果路由跳转出错，则给出错误提示
     */
    timeoutHandle() {
      this.prompt = '加载资源失败，请稍后重试';
      logger.info('webbundle 加速失败');
      // 记录失败次数，如果超过2次，则先关闭加速方案。正常应用之后可以开启
      this.addNoUseTimes();
      this.$router.replace('/error/common');
    },
    /**
     * @description 重设页面的宽度，解决preload页面未展示时推出来的那瞬间可能有宽度不准确的情况
     */
    resetWindowWidth() {
      const winWidth = window.screen.width;

      try {
        // body有宽度，不能直接设为屏幕宽度，否则会有横向滚动条
        if (checkEnvObj.ios && checkEnvObj.iPad) {
          // iPad
          document.body.style.width = `${winWidth}px`;
        }
        // document.documentElement.style.width = winWidth + 'px';
      } catch (e) {
        // nothing
      }
    },
    /**
     * @description 增加加速方案失败的次数
     */
    addNoUseTimes() {
      const key = 'webbundle_speedy_error_times';
      let times = localStorage.getItem(key);

      times = window.parseInt(times);
      times = Number.isNaN(times) ? 0 : times;

      if (times >= 0) {
        times += 1;

        // 超过一次，先禁用加速方案
        if (times >= overTimes) {
          logger.info('关闭 webbundle 方案');
          if (checkEnvObj.ios) {
            jsbridge.core.call(
              'ui/setWebBundleOption',
              { useWebBundle: 0 },
            );
          } else {
            try {
              window.webbundle.setWebBundleOption(0);
            } catch (e) {
              // nothing
              logger.info('安卓关闭 webbundle 方案 失败', e);
            }
          }
          // 超过两次的，关闭方案，然后清0
          localStorage.setItem(key, 0);
        } else {
          localStorage.setItem(key, times);
        }
      }
    },
  },
};
</script>

<style lang="scss">
.p-preload {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .btn {
    margin-top: 10px;
    background: rgb(233, 246, 215);
    border-radius: 5px;
    border: 1px solid #666;
    padding: 5px;
  }
}
</style>

/**
 * getAppVersion
 * @author donaldcen
 * @createTime 2017/9/20
 * @desc version_name,安卓和IOS一样
 */

import platform from './platform'
export default function () {
  return String(platform('com.tencent.qgame/')) || '0.0.0.0'
}

import jsbridge from "../../libs/jsbridge";

/**
 * 返回
 * 
 * @function popBack
 * @memberof ui
 * 
 * @param {any} params 
 */
export function popBack(params) {
  return jsbridge({url: "ui/popBack", params: params});
}



/**
 * appGreaterThen 当前版本大于等于传入版本号返回true否则 false
 * @author donaldcen
 * @createTime 2017/10/25
 */

import compareAppVersion from './compareAppVersion'

export default function (ver) {
  return compareAppVersion(ver) >= 0
}
/* eslint-disable no-unused-vars */
import './polyfill/promise';
import Vue from 'vue';
import '@/filter/index';
import reportData from '@/api/report';
import { logger } from '@/util/logger';
import customPlugin from '@/plugin/index';
import { ReportDataPlugin, PLATFORM } from '@/plugin/report_data_plugin';
// import jsbridge from '@/jsbridge';
import { getScript } from '@/util/util';
// import { registerWebbundleEvents, preloadMixin } from '@/page/common/preload/preload-util';
import { registerWebbundleEvents as registerWebbundleEvents2 } from '@/util/webbundle/preload-util';
import App from './App.vue';
import router from './router';
import store from './store';

Vue.use(customPlugin);

Vue.reportData = reportData;
Vue.prototype.$logger = logger;
Vue.use(ReportDataPlugin, { platform: PLATFORM.h5 });

// const FastClick = require('fastclick');
//
// FastClick.attach(document.body);

document.addEventListener('WeixinJSBridgeReady', () => {
  if (!window.WeixinJSBridge) return false;
  // 设置网页字体为默认大小
  window.WeixinJSBridge.invoke('setFontSizeCallback', { fontSize: 0 });
  // 重写设置网页字体大小的事件
  window.WeixinJSBridge.on('menu:setfont', () => {
    window.WeixinJSBridge.invoke('setFontSizeCallback', { fontSize: 0 });
  });
  return undefined;
});

if (process.env.NODE_ENV === 'development'/* || process.env.VUE_APP_PUBLIC_ENV === 'pre' */) {
  const VConsole = require('vconsole');
  const vConsole = new VConsole();
  // eslint-disable-next-line no-console
  console.log(`vconsole version: ${vConsole.version}`);
}


if (process.env.VUE_APP_PUBLIC_ENV === 'pre') { // 测试环境
  if (/.*(\?|&)test_h5_branch=([^=?&]+).*/i.test(window.location.search) && !/com\.tencent\.gamecommunity/i.test(window.navigator.userAgent)) {
    const cookie = require('js-cookie');
    const testH5Branch = decodeURIComponent(RegExp.$2.trim());
    cookie.set('test_h5_branch', testH5Branch, { path: '/' });
  }
}

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');

registerWebbundleEvents2({ router, store });

// 终端部分逻辑判断依赖mqq
if (store.state.isApp) {
  getScript('//pub.idqqimg.com/qqmobile/qqapi.js?_bid=152').then(() => {
    // window.mqq.execGlobalCallback = function fn (...arg) {
    //   console.log('execGlobalCallback', arg);
    // };
    // console.log('execGlobalCallback ====>');
    // console.log(window.mqq.execGlobalCallback);
  });
}


// const loadedModules = {};
// let preData;
// logger.debug('Vue App start');
// logger.info('进入详情页');
// logger.info('user-agent', navigator.userAgent);

// // 监听preload的事件
// addEventListener('vashybrid_bundle_message', (event) => {
//   logger.info('收到事件', 'vashybrid_bundle_message', event);
//   console.log('vashybrid_bundle_message', event);
//   const data = (event) || {};
//   // 如果是preloadView，则加载对应组件的bundle
//   if (data.type === 'preloadView') {
//     // 只有preload的才走preloadView逻辑
//     logger.debug('data.type===preloadView');
//     if (router.currentRoute.name === 'preload') {
//       // 这里需要注意，这里的routerName是你项目中preload页面的路由名
//       const promiseList = [];
//       // 路由都把需要加载的都加载
//       const routes = getPreloadRoutes();
//       logger.debug('获得预加载路由 routes', routes);
//       // 加载多个可能需要加载的vue
//       routes.forEach((route) => {
//         // 如果模块没有加载过，则加载
//         if (!loadedModules[route]) {
//           router.getMatchedComponents(route).forEach((fn) => {
//             if (typeof fn === 'function') {
//               // 将bundle的执行结果推入
//               promiseList.push(fn());
//               // 将已加载的路由模块标记
//               loadedModules[route] = true;
//             }
//           });
//         }
//       });

//       // 确保所有bundle都加载ok了才通知终端
//       // 这里调用的JSAPI依赖于所处环境
//       Promise.all(promiseList).then(() => {
//         // 通知终端，preload页面已经准备好，可以使用
//         logger.debug('所有路由预加载完成', 'all is done');
//         if (store.state.isIOS) {
//           // window.location.href = 'jsbridge://comic/preloadViewSuccess';
//           jsbridge.core.call('comic/preloadViewSuccess');
//           logger.info('ios通知 preloadViewSuccess');
//         } else {
//           try {
//             // android通知终端preload页已ok可以使用
//             logger.debug('安卓通知 preloadViewSuccess');
//             window.webbundle.preloadViewSuccess();
//           } catch (e) {
//             // do nothing
//             logger.error('预加载加载路由失败', e);
//           }
//         }
//       });
//     }
//   } else if (data.type === 'pushView') {
//     setTimeout(() => {
//       if (router.currentRoute.name === 'preload') {
//         // 如果5s之后还在preload页，则说明跳转失败了，此处为容错逻辑
//         window.location.replace(data.url);
//         logger.info('preload页面跳转失败。自动跳转');
//       }
//     }, 5000);

//     // 否则是pushView，则保存数据，并跳转到指定tab

//     if (store.state.isIOS) {
//       if (typeof data.data === 'string') {
//         try {
//           preData = JSON.parse(data.data);
//         } catch (e) {
//           logger.error('ios获取preData失败', e);
//         }
//       } else if (typeof data.data === 'object') {
//         preData = data.data;
//       }
//       logger.info('ios获取preData', preData);
//     } else if (store.state.isAndroid) {
//       logger.debug('window.webbundle', window.webbundle);
//       if (typeof window.webbundle === 'object' && window.webbundle.getData) {
//         try {
//           preData = JSON.parse(window.webbundle.getData());
//           logger.info('安卓获取preData', preData);
//         } catch (e) {
//           logger.error('安卓获取preData失败', e);
//           preData = null;
//         }
//       }
//     }

//     let url = getUrlPath(data.url);
//     logger.info('获取跳转url', url);
//     // 通过H5加速方式打开的，url链接带上参数，pageOpenType为1，无标识非H5加速，后续如果有其他类型，type可以再增加
//     // 页面发现如果是H5加速方式打开的，则将虚拟dom直接转成字符串然后渲染，而不是直接虚拟dom去patch
//     if (url.indexOf('?') === -1) {
//       url += '?pageOpenType=1';
//     } else {
//       url += '&pageOpenType=1';
//     }

//     // 跳转到需要跳转的目标页面
//     if (preData) {
//       router.onReady(() => {
//         router.beforeResolve((to, from, next) => {
//           const matched = router.getMatchedComponents(to);
//           // eslint-disable-next-line max-nested-callbacks
//           Promise.all(matched.map((c) => {
//             if (c.asyncData) {
//               logger.debug('c.asyncData', preData);
//               return c.asyncData({ store, route: to }, preData);
//             }
//             return false;
//           })).then(next).catch(next);
//         });
//       });
//     }
//     // 因为手Q结合版的iOS新版会根据历史记录做回退，因此这里改为使用replace，不留下历史记录
//     router.replace(url);
//     logger.debug('路由跳转', url);
//   }
// });

/**
 * isPatch
 * @author marvinqi
 * @createTime 2017/9/20
 */
import getUA from './getUA'

export default function (number) {
  number = number || 1
  let rex = new RegExp('patch\\/' + number)
  return rex.test(getUA())
}
/**
 * 自动跳转到元素
 */
// import Vue from 'vue';

export default function (el, binding) {
  const { value, oldValue } = binding;
  if (value && !oldValue) {
    setTimeout(() => {
      // 特性检测，ios 不支持 smooth
      const isSmoothScrollSupported = 'scrollBehavior' in document.documentElement.style;
      if (isSmoothScrollSupported) {
        el.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
      } else {
        el.scrollIntoView(true);
      }
    }, 500); // TODO 500ms 后滚动比较顺畅，后续优化
  }
}

import { PLATFORM } from '../../../constants/index';
import { exposureReportInH5 } from './platform/h5';
import { exposureReportInHippy } from './platform/hippy';

/**
 * 曝光数据上报指令
 * @param {*} Vue
 * @param {*} option 相关选项，默认使用Hippy曝光数据上报指令
 */
function exposureReport(Vue, options) {
  const { platform = PLATFORM.hippy } = options || {};
  const strategyObj = {
    [PLATFORM.h5]: exposureReportInH5,
    [PLATFORM.hippy]: exposureReportInHippy,
  };
  const strategy = strategyObj[platform] || strategyObj[PLATFORM.hippy];

  return strategy(Vue);
}

export { exposureReport };
